import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { ApprovalType, AppState, CompanyRole, RootState } from "@/types";
import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";
import core from "@/core";
import { Subscribe, SubscribeType } from "@/core/core-web-socket";
import { CalendarModel } from "@/models/calendar/calendar.model";
import CalendarService from "@/services/calendar/calendar.service";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";
import { NotificationModel } from "@/models/user/notification.model";
import { NavMenu, NavMenuId, NavMenuType, NavSubMenu } from "@/models/core/nav-menu.model";
import NavMenuItems from "@/data/nav-menu-items";
import { cloneDeep } from "lodash";
import { hasCompanyRole } from "@/store/modules/auth";
import CalendarUtils from "@/utils/calendar-utils";

const state: AppState = {
  version: process.env.VUE_APP_VERSION || "0",
  name: Constant.appName,
  size: { width: 0, height: 0, bodyWidth: 0 },
  routerBeforeTask: new RouterBeforeTask(),
  modal: {
    alert: {
      visible: false,
      title: "알림",
      body: "내용",
      showCancelButton: false,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
      promise: {
        resolve: null,
        reject: null,
      },
      allowBackCloseEvent: true,
    },
  },
  path: "",
  selectedCalendarId: "all",
  printClass: null,
  isApp: false,
  isMobile: false,
  isMobileSize: false,
  showNav: true,
  bottomNav: "",
  platform: "",
  webSocketConnected: false,
  data: {
    ready: false,
    calendarList: [] as CalendarModel[],
    notificationList: [] as NotificationModel[],
  },
  navMenuList: [] as NavMenu[],
};

export const getters: GetterTree<AppState, RootState> = {
  getCalendarList: (theState: AppState) => {
    return (): Promise<CalendarModel[] | null> => {
      return new Promise((resolve, reject) => {
        const dataFunction = () => {
          if (theState.data.ready) {
            resolve(theState.data.calendarList);
          } else {
            setTimeout(dataFunction, 100);
          }
        };
        dataFunction();
      });
    };
  },
};

export const actions: ActionTree<AppState, RootState> = {
  addPrint({ commit }, deviceType: string) {
    if (state.printClass == null) {
      document.documentElement.classList.add("print");
      document.documentElement.classList.add(deviceType);
      state.printClass = deviceType;
    }
  },
  clearPrint({ commit }) {
    if (state.printClass) {
      document.documentElement.classList.remove("print");
      document.documentElement.classList.remove("mobile");
      document.documentElement.classList.remove("pc");
      state.printClass = null;
    }
  },
  webSocketConnected({ commit }, data) {
    commit("webSocketConnected", data);
  },
  loadNavMenu({ commit }) {
    commit("loadNavMenu");
  },
};

const mutations: MutationTree<AppState> = {
  clearSelectedCalendar(theState) {
    theState.selectedCalendarId = "all";
    // console.log("clear selected calendar");
  },
  async webSocketConnected(theState, data) {
    const connected = data.connected;
    const subscribeList: Subscribe[] | null = data.subscribeList;

    const preWebSocketConnected = theState.webSocketConnected;
    theState.webSocketConnected = connected;

    console.log("connected : ", connected, "preConnected : ", preWebSocketConnected);

    if (preWebSocketConnected != connected) {
      // console.log("change web-socket connected : ", connected);

      if (connected) {
        // console.log("subscribeList : ", subscribeList);

        if (subscribeList != null) {
          subscribeList.forEach((subscribe) => {
            if (subscribe.type != null) {
              core.webSocket.subscribe(subscribe.type, subscribe.item, subscribe.callback);
            }
          });
        }
        await mutations.loadCalendar(theState);
        // theState.data.ready = true;
        // await mutations.loadNavMenu(theState);
      } else {
        theState.data.calendarList.length = 0;
      }
    }
  },
  async loadCalendar(theState) {
    try {
      // console.log("loadCalendar");
      theState.data.calendarList.length = 0;

      const calendarList = (await CalendarService.getList()) as CalendarModel[];
      calendarList.forEach((calendar) => {
        theState.data.calendarList.push(calendar);
      });
      theState.data.calendarList.sort((a, b) => {
        if (a.no < b.no) return -1;
        else if (a.no > b.no) return 1;
        return 0;
      });
      // console.log("update calendar list. ", theState.data.calendarList.length);
    } catch (e) {
      console.log(e);
    }

    // 캘린더 정보 변경 알림 구독 처리
    const user = (await store.getters["auth/user"]()) as UserModel;
    core.webSocket.subscribe(
      SubscribeType.CALENDAR,
      {
        userId: user.id,
        callback: async (event) => {
          // console.log("changed calendar : ", event);
          const calendarList = theState.data.calendarList;
          const crud = event.crud;
          const item = event.item as CalendarModel;

          for (let i = 0; i < calendarList.length; i++) {
            const calendar = calendarList[i];
            if (calendar.id === item.id) {
              calendarList.splice(i, 1);
              break;
            }
          }

          if (crud === "C" || crud === "U") {
            calendarList.push(item);
            calendarList.sort((a, b) => {
              if (a.no < b.no) return -1;
              else if (a.no > b.no) return 1;
              return 0;
            });
            try {
              await mutations.loadNavMenu(theState);
            } catch (e) {
              console.log(e);
            }
          }
          // console.log("changed calendar list : ", calendarList);
        },
      },
      {
        connected: async () => {
          // console.log("connected refresh calendar");
          // const calendarList = (await CalendarService.getList()) as CalendarModel[];
          // theState.data.calendarList.length = 0;
          // calendarList.forEach((calendar) => {
          //   theState.data.calendarList.push(calendar);
          // });
          // theState.data.calendarList.sort((a, b) => {
          //   if (a.no < b.no) return -1;
          //   else if (a.no > b.no) return 1;
          //   return 0;
          // });
        },
        disconnected: () => {},
      }
    );
    theState.data.ready = true;

    try {
      await mutations.loadNavMenu(theState);
    } catch (e) {
      console.log(e);
    }
  },
  async loadNavMenu(theState) {
    const user = await store.getters["auth/user"]();
    if (user == null) {
      console.log("not found user");
    } else {
      console.log("loadNavMenu");
      // console.log("user : ", JSON.stringify(user));

      const calendarList: CalendarModel[] = theState.data.calendarList;
      // console.log("calendarList : ", JSON.stringify(calendarList));
      const menuList = [] as NavMenu[];
      for (const orgMenu of NavMenuItems) {
        const menu = cloneDeep(orgMenu);
        if (menu.type === NavMenuType.DIVIDER) {
          if (menuList.length > 0) {
            // 이전 메뉴가 divider 일 경우 무시
            const preMenu = menuList[menuList.length - 1];
            if (preMenu.type === NavMenuType.DIVIDER) {
              continue;
            }
          }
          menuList.push(menu);
        } else {
          if (menu.id != null) {
            if (menu.id === NavMenuId.logout) {
              menu.click = () => {
                store.dispatch("auth/logoutPage");
              };
            } else if (menu.id === NavMenuId.notification) {
              if (menu.badge) {
                if (theState.data.notificationList.length > 0) {
                  menu.badge.content = String(theState.data.notificationList.length);
                } else {
                  menu.badge.content = "";
                }
              }
            } else if (menu.id === NavMenuId.calendar) {
              if (user.company != null && user.company.lastApproval.type === ApprovalType.ALLOW) {
                // 일정 메뉴 서브 메뉴 추가
                const subMenuList = [] as NavSubMenu[];
                menu.subMenuList = subMenuList;
                const html =
                  '<span class="v-chip v-chip--label v-chip--outlined theme--light v-size--x-small success success--text"><span class="v-chip__content">{title}</span></span>';

                calendarList.forEach((calendar) => {
                  // console.log("calendar : ", calendar);
                  subMenuList.push({
                    path: `/${calendar.id}`,
                    title: `${html.replace("{title}", CalendarUtils.typeToString(calendar.type))} ${
                      calendar.name
                    }`,
                    companyRoles: [
                      CompanyRole.OWNER,
                      CompanyRole.ADMIN,
                      CompanyRole.MANAGER,
                      CompanyRole.USER,
                    ],
                    divider: false,
                  });
                });
              }
            } else {
              console.log("invalid menuId : ", menu.id);
            }
          }

          if (menu.subMenuList) {
            const subMenuList = [] as NavSubMenu[];
            menu.subMenuList.forEach((subMenu) => {
              if (subMenu.companyRoles == null || hasCompanyRole(user, ...subMenu.companyRoles)) {
                subMenu.path = menu.path + subMenu.path;
                subMenuList.push(subMenu);
              }
            });
            if (subMenuList.length > 0) {
              if (subMenuList.length === 1) {
                const subMenu = subMenuList[0];
                menu.path = subMenu.path;
                delete menu.subMenuList;
                menuList.push(menu);
              } else {
                menu.subMenuList = subMenuList;
                menuList.push(menu);
              }
            }
          } else if (menu.companyRoles == null || hasCompanyRole(user, ...menu.companyRoles)) {
            menuList.push(menu);
          }
          // console.log("menu : ", menu);
          // if (menu.companyRoles != null) {
          //   console.log("hasCompanyRole : ", hasCompanyRole(user, ...menu.companyRoles));
          // }
        }
      }

      // console.log("theState", theState);
      // console.log("menuList : ", menuList);
      theState.navMenuList = menuList;
    }
  },
};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
