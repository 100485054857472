
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import CalendarUtils from "@/utils/calendar-utils";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import { CalendarModel } from "@/models/calendar/calendar.model";

export default mixins(MixinsPage).extend({
  name: "CalendarDayComponent",
  components: {},
  props: {
    date: {
      type: String,
      default: () => {
        return "";
      },
    },
    eventList: {
      type: Array,
      default: () => {
        return [] as CalendarEventModel[];
      },
    },
    addButtonVisible: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data: () => ({
    visible: {
      price: false,
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const query = this.$route.query;
      let calendar = null as CalendarModel | null;
      if (this.isNotBlank(query.calendarId)) {
        const calendarId = Number(query.calendarId);
        const calendarList = (await this.$store.getters[
          "app/getCalendarList"
        ]()) as CalendarModel[];
        calendarList.some((_calendar) => {
          if (_calendar.id === calendarId) {
            calendar = _calendar;
            return true;
          }
        });
      }

      if (calendar != null) {
        if (calendar.type === "company" && this.$store.getters["auth/isAdminRoleHigher"]) {
          this.visible.price = true;
        } else {
          this.visible.price = false;
        }
      } else {
        this.visible.price = this.$store.getters["auth/isAdminRoleHigher"];
      }
    });
  },
  watch: {},
  methods: {
    calendarEventEstimateDetailToText(item: CalendarEventModel) {
      return CalendarEventService.detailToText(item);
    },
    showViewPage(item) {
      //console.log("view page : ", item);
      const query = {} as any;
      // if (this.isNotBlank(this.$route.query.calendarId)) {
      //   query.calendarId = this.$route.query.calendarId;
      // }
      query.calendarId = item.calendarId;
      this.$router.push({ path: "/calendar/event/" + item.id, query: query });
    },
    showAddPage() {
      const query = {
        startDate: this.date,
        endDate: this.date,
      } as any;

      if (this.isNotBlank(this.$route.query.calendarId)) {
        query.calendarId = this.$route.query.calendarId;
      }

      this.$router.push({
        path: "/calendar/event/add",
        query: query,
      });
    },
    getColorStyle(item: any) {
      const hex = CalendarUtils.colorToHex(item.color);
      let style = "";
      if (hex != null) {
        style = `color: ${hex}`;
      }
      //console.log("style : ", style);
      return style;
    },
    getTime(item: any) {
      if (item.allDay) {
        let text = "";
        const startMoment = core.date.instance(item.startAt);
        if (this.date != startMoment.format("YYYY-MM-DD")) {
          text += startMoment.format("D일") + " 종일";
        }

        const endMoment = core.date.instance(item.endAt);
        if (this.date != endMoment.format("YYYY-MM-DD")) {
          if (text.length > 0) text += "<br>";
          else {
            text += "종일<br>";
          }
          text += endMoment.format("~ D일") + " 종일";
        } else {
          if (text.length > 0) text += "<br>~ 종일";
        }

        if (text.length == 0) text += "종일";
        return text;
      }

      //console.log("date : ", this.date);

      const startMoment = core.date.instance(item.startAt);
      //console.log("view start at : ", item.viewStartAt);
      // const startMoment = core.date.instance(item.startAt);
      let strStartAt = "";
      if (this.date != startMoment.format("YYYY-MM-DD")) {
        strStartAt = startMoment.format("D일 a hh:mm");
      } else {
        strStartAt = startMoment.format("a hh:mm");
      }

      const endMoment = core.date.instance(item.endAt);
      //console.log("view end at : ", item.viewEndAt);
      // const endMoment = core.date.instance(item.endAt);
      let strEndAt = "";
      if (this.date != endMoment.format("YYYY-MM-DD")) {
        strEndAt = endMoment.format("~ D일 a hh:mm");
      } else {
        strEndAt = endMoment.format("~ a hh:mm");
      }
      //console.log("strEndAt : ", strEndAt);

      return `${strStartAt}<br>${strEndAt}`;
    },
  },
});
