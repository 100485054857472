import AbstractTableService from "@/services/core/abstract-table.service";
import { TableRequest } from "@/types";
import { EstimateModel } from "@/models/estimate/estimate.model";
import core from "@/core";

class EstimateService extends AbstractTableService {
  constructor() {
    super("/api/v1/estimate", "estimate");
  }

  get(id: number): Promise<unknown> {
    return super.get(id, null);
  }

  getList(): Promise<unknown> {
    return super.getList();
  }

  create(params): Promise<unknown> {
    return super.create(params);
  }

  update(id: number, params): Promise<unknown> {
    return super.update(id, params);
  }

  delete(id: number): Promise<unknown> {
    return super.delete(id, null);
  }

  getTable(params: TableRequest): Promise<unknown> {
    return super.getTable(params);
  }

  updateContract(id: number, contract: string): Promise<unknown> {
    const url = this.getUrl();
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ contract: contract });
      core.http
        .putJson(`${url}/${id}/contract`, strParams)
        .then((data) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateMemo(id: number, memo: string): Promise<unknown> {
    const url = this.getUrl();
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ memo: memo });
      core.http
        .putJson(`${url}/${id}/memo`, strParams)
        .then((data) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getCalendarEvent(id: number) {
    const url = this.getUrl();
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${url}/${id}/calendar/event`)
        .then((data) => {
          const item = data.eventList;
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getShareCalendarEvent(id: number) {
    const url = this.getUrl();
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${url}/${id}/calendar/event/share`)
        .then((data) => {
          const item = data.shareCalendarEventList;
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  export(id: number) {
    const url = this.getUrl();
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}/${id}/export`)
        .then((data) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  titleToText(item: EstimateModel) {
    let title = "";
    if (core.utils.validate.isNotBlank(item.account)) {
      title = item.account;
    } else if (item.address != null && item.address.length > 0) {
      title = item.address;
    }

    if (core.utils.validate.isNotBlank(item.dong)) {
      title += ` ${item.dong}동`;
    }
    if (core.utils.validate.isNotBlank(item.ho)) {
      title += ` ${item.ho}호`;
    }
    return title;
  }

  priceToHtml(item: EstimateModel) {
    let text = "";
    if (item.totalPrice > 0) {
      if (item.paymentYn === "Y") {
        text += `견적가 ${core.utils.format.moneyKor(String(item.totalPrice))}, 결제 완료`;
      } else {
        text += `견적가 ${core.utils.format.moneyKor(
          String(item.totalPrice)
        )}, 결제 ${core.utils.format.moneyKor(
          String(item.payment)
        )}, 잔금 ${core.utils.format.moneyKor(String(item.balance))}`;
      }
    }
    return text;
  }

  contractToHtml(item: EstimateModel) {
    let html = "";
    const value = item.contract;
    if (value === "WAIT") html = "<span class='red--text'>계약대기</span>";
    else if (value === "COMPLETE") html = "<span class='green--text'>계약완료</span>";
    else if (value === "CANCEL") html = "<span class='black--text'>계약취소</span>";
    else if (value === "EVENT") html = "<span class='orange--text'>이벤트</span>";
    else if (value === "TEMPORARY") html = "<span class='orange--text'>임시계약</span>";
    else html = "알 수 없음";

    return html;
  }

  scheduleToHtml(item: EstimateModel) {
    if (item.contract === "COMPLETE") {
      if (item.scheduleYn === "Y") {
        if (item.workStatus === "C") {
          return "<span class='green--text'>시공완료</span>";
        } else {
          return "<span class='green--text'>일정확정</span>";
        }
      } else {
        return "<span class='orange--text'>일정미정</span>";
      }
    }
    return "";
  }
}

export default new EstimateService();
