import core from "@/core";
import AbstractCoreService from "@/services/core/abstract-core.service";

class CalendarService extends AbstractCoreService {
  constructor() {
    super("/api/v1/calendar", "calendar");
  }

  get(id: number): Promise<unknown> {
    return super.get(id, null);
  }

  getList(): Promise<unknown> {
    return super.getList();
  }

  create(params): Promise<unknown> {
    return super.create(params);
  }

  update(id: number, params): Promise<unknown> {
    return super.update(id, params);
  }

  delete(id: number): Promise<unknown> {
    return super.delete(id, null);
  }

  updateOrder(params: any) {
    const url = this.url;
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .postJson(`${url}/order`, strJson)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new CalendarService();
