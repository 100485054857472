import AbstractTableService from "@/services/core/abstract-table.service";
import core from "@/core";

class PublicShareCalendarEventService {
  protected readonly url: string = "/api/v1/sce";
  protected readonly itemKey: string = "shareCalendarEvent";

  get(uuid: string): Promise<unknown> {
    const url = this.url;
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}/${uuid}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new PublicShareCalendarEventService();
