import { UserCompanyModel } from "@/models/company/user-company.model";
import { IdModel, IdUpdateModel } from "@/models/core/type.model";
import { UserSocialModel } from "@/models/company/user-social.model";
import { YnType } from "@/types";
import { ImageFileModel } from "@/models/file/image-file.model";

export enum Role {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  USER = "USER",
}

export interface SimpleUserModel extends IdModel {
  username: string;
  email: string;
  name: string;
  phone: string;

  profile: ImageFileModel | null;
}

export interface UserModel extends IdUpdateModel {
  username: string;
  email: string | null;
  name: string;
  phone: string;
  roles: string[];
  company: UserCompanyModel | null;
  profile: ImageFileModel | null;
  socialList: UserSocialModel[];
  passwordTempYn: YnType;
  passwordUpdateAt: string | null;
}
