
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import { UserModel } from "@/models/user/user.model";

export default mixins(MixinsCommon).extend({
  name: "UserListItemComponent",

  props: {
    item: {
      type: Object,
      default: () => {
        return null as UserModel | null;
      },
    },
  },
  methods: {
    approvalToText() {
      let content = "알 수 없음";
      if (this.item.company.lastApproval.type === "ALLOW") {
        content = "승인";
      } else if (this.item.company.lastApproval.type === "REQUEST") {
        content = "승인 요청중";
      } else if (this.item.company.lastApproval.type === "DENY") {
        content = "승인 거절";
      }
      //console.log("content : ", content);
      return content;
    },

    approvalToClass() {
      let content = "";
      if (this.item.company.lastApproval.type === "ALLOW") {
        content = "green--text";
      } else if (this.item.company.lastApproval.type === "REQUEST") {
        content = "orange--text";
      } else if (this.item.company.lastApproval.type === "DENY") {
        content = "red--text";
      }
      //console.log("content : ", content);
      return content;
    },
    companyRoleToText(role) {
      if (role === "OWNER") {
        return "소유주";
      } else if (role === "ADMIN") {
        return "관리자";
      } else if (role === "MANAGER") {
        return "매니저";
      } else if (role === "USER") {
        return "일반사용자";
      } else {
        return "알 수 없음";
      }
    },
  },
});
