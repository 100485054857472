
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CalendarEventAddEditComponent from "@/components/calendar/EventAddEditComponent.vue";
import UpdateEvent from "@/models";

export default mixins(MixinsPage).extend({
  name: "CalendarEventAdd",
  components: {
    CalendarEventAddEditComponent,
  },
  data: () => ({
    query: null as any,
    component: {
      updateEvent: null as UpdateEvent | null,
    },
  }),
  created() {
    this.query = this.$route.query;
    // console.log("mounted");
  },
  activated() {
    this.component.updateEvent = this.getPageUpdateEvent();
  },
});
