
import CompanyViewComponent from "@/components/company/ViewComponent.vue";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import { UserCompanyModel } from "@/models/company/user-company.model";
import { AppBarMenuItem, CompanyRole } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "CompanyView",
  components: {
    CompanyViewComponent,
  },
  data: () => ({
    app: store.state.app,
  }),
  mounted() {
    this.$nextTick(async () => {
      const user = await this.$store.getters["auth/user"]();
      if (user != null) {
        const company = user.company as UserCompanyModel;
        const editVisible =
          company.role === CompanyRole.OWNER || company.role === CompanyRole.ADMIN;
        this.appBarChangeMenuVisible("edit", editVisible);
        // if (this.iconSubMenu.edit != null) {
        //   this.iconSubMenu.edit.visible =
        //     company.role === CompanyRole.OWNER || company.role === CompanyRole.ADMIN;
        // }
      } else {
        await this.notFound();
      }
    });
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "edit") {
        // console.log("event");
        this.$router.push({ path: "/company/me/edit" });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
  }
});
