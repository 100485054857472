
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimateService from "@/services/estimate/estimate.service";
import { EstimateModel } from "@/models/estimate/estimate.model";
import EstimatePrintComponent from "@/components/estimate/PrintComponent.vue";
import store from "@/store";
import core from "@/core";

export default mixins(MixinsPage).extend({
  name: "EstimatePrint",
  components: { EstimatePrintComponent },
  data: () => ({
    ready: false,
    first: true,
    estimate: null as EstimateModel | null,
    app: store.state.app,
  }),

  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params as any;
      const estimateId = params.id;
      if (estimateId <= 0) {
        await this.notFound();
        return;
      } else {
        try {
          this.estimate = await this.getEstimate(estimateId);
        } catch (e) {
          console.log(e);
          await this.notFound();
          return;
        }
      }
      document.documentElement.classList.add("print");
      document.documentElement.classList.add(this.app.isMobile ? "mobile" : "pc");
      this.ready = true;
    });
  },
  beforeRouteLeave(to, from, next) {
    document.documentElement.classList.remove("print");
    document.documentElement.classList.remove(this.app.isMobile ? "mobile" : "pc");
    next();
  },
  updated() {
    if (this.first) {
      this.first = false;
      setTimeout(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("android") > -1 && userAgent.indexOf("scc") > -1) {
          core.mobile.call({ cmd: "print" });
        } else if (userAgent.indexOf("msie") > -1 || userAgent.indexOf("trident") > -1) {
          document.execCommand("print", true);
        } else {
          window.print();
        }
      }, 500);
    }
  },
  methods: {
    async getEstimate(estimateId): Promise<EstimateModel> {
      return (await EstimateService.get(estimateId)) as EstimateModel;
    },
  },
});
