
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsStorage from "@/mixins/single/storage";
import MixinsTable from "@/mixins/single/table";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import core from "@/core";
import store from "@/store";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import { StorageType, UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";
import CalendarUtils from "@/utils/calendar-utils";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import { CalendarModel } from "@/models/calendar/calendar.model";
import CalendarEventListItemComponent from "@/components/calendar/EventListItemComponent.vue";
import MixinsScroll from "@/mixins/single/scroll";

export default mixins(MixinsPageForm, MixinsStorage, MixinsTable, MixinsScroll).extend({
  name: "CalendarSearch",
  components: { CalendarEventListItemComponent },
  data: () => ({
    history: "search",
    storageItems: {
      type: StorageType.CALENDAR_SEARCH,
    },
    table: {
      firstLoading: true,
      loading: false,
      body: document.documentElement,
      defaultColumns: [],
    },
    historyItems: [] as any,
    historyFilterItems: [] as any,
    submitSearchKeyword: null as string | null,
    searchKeyword: null as string | null,
    searchKeywordFocus: false,
    searchKeywordFocusTime: new Date().getTime(),
    searchType: "keyword", // keyword, detail
    preViewType: "",
    viewType: "history", // history, detail, result
    keywordPromise: {
      wait: null as any,
      resolve: null as any,
    },
    detailSearch: {
      addressLike: "",
      dong: "",
      ho: "",
      customerPhoneLike: "",
      customerName: "",
      scheduleYn: "",
      contractStatus: "",
      paymentYn: "",
      balance: "",
    },
    calendarMap: {},
  }),

  mounted() {
    this.$nextTick(async () => {
      await this.storageWait();
      const items = this.getStorageItems(this.history);
      if (items != null) {
        this.historyItems = items;
      } else {
        this.setStorageItem(this.history, this.historyItems);
      }

      const calendarList = (await this.$store.getters["app/getCalendarList"]()) as CalendarModel[];
      calendarList.forEach((calendar) => {
        this.calendarMap[calendar.id] = calendar;
      });

      // 테이블 초기화
      this.initTable({
        service: CalendarEventService,
        serviceFunctionKey: "getTable",
        itemHeight: 60,
      });

      this.changedSearchKeyword(this.searchKeyword);
      this.keywordPromise.wait = new Promise((resolve: any, reject) => {
        this.keywordPromise.resolve = resolve;
      });

      const vm = this as any;
      const routerBeforeTask = store.state.app.routerBeforeTask;
      const routerBeforeTaskItem = new RouterBeforeTaskItem(vm.$vnode.tag, () => {
        //console.log("viewType : ", vm.viewType, ", searchType : ", this.searchType);
        if (vm.searchType === "detail") {
          if (vm.preViewType === "result") {
            vm.viewType = "result";
          } else {
            vm.searchType = "keyword";
          }
          return false;
        }
        return true;
      });
      routerBeforeTask.pushPage(routerBeforeTaskItem);

      this.moveFocus("searchKeyword");

      this.focusIn();
    });
  },

  computed: {
    tableDataList() {
      return this.table.response.data;
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },

  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      this.updatePreVmEvent(event.result, event.item);

      if (event.result === UpdateEventType.UPDATE) {
        this.addTableItem("id", event.item);
      } else if (event.result === UpdateEventType.DELETE) {
        //console.log("event : ", event);
        this.deleteTableItem("id", event.item);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },

  watch: {
    "scroll.scrollTop"(scrollTop) {
      // console.log("scrollTop : ", scrollTop);
      this.scrollTop = scrollTop;
    },
    searchKeyword(val) {
      this.changedSearchKeyword(val);
      //console.log("changed searchKeyword");
      this.keywordPromise.resolve();
    },
    searchType(type) {
      if (type === "keyword") {
        this.viewType = "history";
      } else if (type === "detail") {
        this.viewType = "detail";
      } else {
        console.log("Unknown Type : ", type);
      }
    },
    viewType(val, prev) {
      this.preViewType = prev;

      // viewType 변경시, 스크롤바 위로 이동
      const $el = this.$refs.body as any;
      if ($el != null) {
        $el.scrollTop = 0;
      }
    },
  },
  methods: {
    eventDateToString(item) {
      return CalendarUtils.eventDateToString(item);
    },
    getHistoryDate(item: any) {
      return this.dateElapsedTimeFormat(
        core.date.instance(new Date(item.date)).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    isSearch() {
      return this.table.request.draw > 0;
    },
    isResultEmpty() {
      return this.table.request.draw > 0 && this.table.response.data.length == 0;
    },
    changedSearchKeyword(searchKeyword) {
      const isBlank = this.isBlank(searchKeyword);
      const filterItems = [] as any;

      this.historyItems.some((item: any) => {
        if (isBlank || item.keyword.indexOf(searchKeyword) > -1) {
          filterItems.push(item);
        }
        if (filterItems.length === 20) {
          return true;
        }
      });
      this.historyFilterItems = filterItems;
      if (filterItems.length > 0 && this.searchKeywordFocus) {
        // TODO : 이력정보 표시?
        //this.visibleHistory = true;
      }
    },
    focusIn() {
      this.searchKeywordFocus = true;
      this.searchKeywordFocusTime = new Date().getTime();
      this.viewType = "history";
    },
    focusOut() {
      if (new Date().getTime() - this.searchKeywordFocusTime > 100) {
        this.searchKeywordFocus = false;
      }
    },
    submit() {
      (this.$refs.searchKeyword as any).blur();
      this.searchKeywordFocus = false;

      const searchKeyword = this.searchKeyword as string;
      if (this.isNotBlank(searchKeyword)) {
        this.viewType = "result";

        this.submitSearchKeyword = searchKeyword;
        //console.log("submit searchKeyword : ", this.searchKeyword);

        // 키워드 스토리지 동일 항목 제외, 최대 100개 저장
        this.historyItems.some((item: any, index) => {
          if (item.keyword === searchKeyword) {
            this.historyItems.splice(index, 1);
            return true;
          }
        });

        const diffLength = this.historyItems.length - 99;
        if (diffLength > 0) {
          for (let i = 0; i < diffLength; i++) {
            this.historyItems.splice(this.historyItems.length - 1, 1);
          }
        }

        this.historyItems.unshift({
          keyword: searchKeyword,
          date: new Date().getTime(),
        });
        this.saveStorageItems();

        const searchColumns = this.keywordToSearchColumns(searchKeyword);
        this.clearTable();
        this.loadTable(searchColumns);
      } else {
        core.alert.show({
          title: "알림",
          body: "키워드를 입력하세요!",
        });
      }
    },

    async submitDetail() {
      if (await this.validate()) {
        const params = cloneDeep(this.detailSearch);
        for (const key of Object.keys(params)) {
          const value = params[key];
          if (this.isBlank(value)) {
            delete params[key];
          } else if (key === "phone") {
            params[key] = params[key].replaceAll("-", "");
          }
        }
        if (Object.keys(params).length === 0) {
          await core.alert.show({
            title: "알림",
            body: "입력된 조건이 1개 이상이어야 합니다.",
          });
        } else {
          //console.log("params : ", params);
          this.viewType = "result";
          this.clearTable();
          this.loadTable(params);
        }
      }
    },
    async selectHistory(item: any) {
      //console.log("selectHistory : ", item);

      const changedSearchKeyword = this.searchKeyword !== item.keyword;

      if (changedSearchKeyword) {
        this.searchKeyword = item.keyword;
        await this.keywordPromise.wait;
      }

      if (this.submitSearchKeyword !== item.keyword) {
        if (!changedSearchKeyword) {
          this.changedSearchKeyword(this.searchKeyword);
        }
        this.submit();
      } else {
        this.changedSearchKeyword(this.searchKeyword);
        if (this.viewType === "history") {
          this.viewType = "result";
        }
      }
    },

    keywordToSearchColumns(searchKeyword: string) {
      const searchColumns = {} as any;

      // // 1. 휴대전화번호 형식 분류
      // if (core.utils.validate.isNotBlank(searchKeyword)) {
      //   const phoneResult = core.utils.format.textToPhoneNumber(searchKeyword) as any;
      //   // console.log("phoneResult : ", phoneResult);
      //   if (phoneResult != null) {
      //     console.log(`replace content ${searchKeyword} -> ${phoneResult.replaceContent}`);
      //     searchKeyword = phoneResult.replaceContent;
      //
      //     if (phoneResult.list.length > 1) {
      //       searchColumns.customerPhoneList = phoneResult.list.join(",");
      //     } else {
      //       searchColumns.customerPhone = phoneResult.list[0];
      //     }
      //   }
      // }
      //
      // // 2. 동호수 형식 분류, 101동 101호, 101-101, 101.101
      // if (core.utils.validate.isNotBlank(searchKeyword)) {
      //   const dongHoResult = core.utils.format.textToDongHo(searchKeyword) as any;
      //   // console.log("dongHoResult : ", dongHoResult);
      //   if (dongHoResult != null) {
      //     console.log(`replace content ${searchKeyword} -> ${dongHoResult.replaceContent}`);
      //     searchKeyword = dongHoResult.replaceContent;
      //     if (dongHoResult.list.length > 1) {
      //       const dongList = [] as any;
      //       const hoList = [] as any;
      //       dongHoResult.list.forEach((dongHo) => {
      //         dongList.push(dongHo.dong);
      //         hoList.push(dongHo.ho);
      //       });
      //       searchColumns.dongList = dongList.list.join(",");
      //       searchColumns.hoList = hoList.list.join(",");
      //     } else {
      //       searchColumns.dong = dongHoResult.list[0].dong;
      //       searchColumns.hoStartLike = dongHoResult.list[0].ho;
      //     }
      //   }
      // }

      // 3. 주소 검색
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        searchColumns.title = searchKeyword;
        //   const placeIdList = [] as any;
        //   const placeNameList = searchKeyword.split(" ");
        //   placeNameList.forEach((placeName) => {
        //     this.placeList.forEach((place) => {
        //       if (place.name.indexOf(placeName) > -1) {
        //         placeIdList.push(place.id);
        //         searchKeyword = searchKeyword.replaceAll(placeName, "").trim();
        //       }
        //     });
        //   });
        //
        //   if (placeIdList.length > 0) {
        //     if (placeIdList.length > 1) {
        //       searchColumns.placeIdList = placeIdList.join(",");
        //     } else {
        //       searchColumns.placeId = placeIdList[0];
        //     }
        //   }
      }

      return searchColumns;
    },
    getColorStyle(item: any) {
      const hex = CalendarUtils.colorToHex(item.color);
      let style = "";
      if (hex != null) {
        style = `color: ${hex}`;
      }
      //console.log("style : ", style);
      return style;
    },
    calendarEventEstimateDetailToText(item: CalendarEventModel) {
      return CalendarEventService.detailToText(item);
    },
    showViewPage(item) {
      //console.log("view page : ", item);
      const query = {} as any;
      // if (this.isNotBlank(this.$route.query.calendarId)) {
      //   query.calendarId = this.$route.query.calendarId;
      // }
      query.calendarId = item.calendarId;
      this.$router.push({ path: "/calendar/event/" + item.id, query: query });
    },
  },
});
