
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import CalendarEventDaySelectModal from "@/modals/calendar/CalendarEventDaySelectModal.vue";

export default mixins(MixinsPage).extend({
  name: "CalendarEventSelect",
  components: { CalendarEventDaySelectModal },
  data: () => ({
    ready: false,
    calendar: {
      model: core.date.instance().format("YYYY-MM-DD"),
      title: "",
      height: 0,
      items: [] as any,
      totalPrice: 0,
    },
    reloadFlag: false,
    searchColumns: {} as any,

    modal: {
      day: {
        visible: false,
        params: {
          searchColumns: {},
        },
        // 업데이트 이벤트 처리용 변수
        updateEvent: (null as any) as UpdateEvent | null,
      },
    },
    returnType: "",
    updatedType: "",
  }),
  mounted() {
    this.$nextTick(async () => {
      this.resize();
      // month
      const query = this.$route.query;
      if (this.isNotBlank(query.month)) {
        const moment = core.date.instance(query.month as string);
        if (moment.isValid()) {
          this.calendar.model = moment.format("YYYY-MM-DD");
        }
      }
      this.returnType = String(query.returnType);

      if (this.isNotBlank(query.calendarId)) {
        this.searchColumns.calendarId = String(query.calendarId);
      }

      this.ready = true;
      this.reloadFlag = true;
    });
  },
  updated() {
    const updatedType = this.updatedType;
    this.updatedType = "";
    if (updatedType === "schedule") {
      this.resize();
    }
  },
  watch: {
    "app.size"() {
      this.resize();
    },
    reloadFlag(reloadFlag) {
      this.reloadFlag = false;
      if (reloadFlag && this.ready) {
        this.drawCalendarEvent(this.searchColumns);
      }
    },
    "modal.day.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.day);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = {
            returnType: this.returnType,
            date: event.item,
          };
          this.goBack(UpdateEventType.UPDATE, item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.drawCalendarEvent(this.searchColumns);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    resize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 130;
      } else {
        if (this.app.size.width < 965) {
          offset = 150;
        } else {
          offset = 160;
        }
      }
      this.calendar.height = this.app.size.height - offset;

      {
        const elList = document.querySelectorAll(".v-event-more-custom");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      {
        const elList = document.querySelectorAll(".v-event-more-custom-issue");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      {
        const elList = document.querySelectorAll(".v-event-more-custom-text");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      const elList = document.querySelectorAll(".v-calendar-weekly__week .v-calendar-weekly__day");
      if (elList != null) {
        const eventElList = document.querySelectorAll(
          ".v-calendar-weekly__week .v-calendar-weekly__day .v-event"
        );

        if (eventElList.length > 0) {
          //console.log("eventElSize : ", eventElList.length);
          eventElList.forEach((el: any) => {
            if (el.style.display == "none") {
              el.style.display = "block";
            }
          });
        }

        elList.forEach((el) => {
          if (el.clientHeight < el.scrollHeight) {
            const eventList = el.querySelectorAll("[data-date]");
            let height = 21; // label 제외
            let addMoreCount = 0;
            let date = "";
            eventList.forEach((eventEl: any) => {
              if (eventEl.dataset.date != null) {
                date = eventEl.dataset.date;
              }
              height += eventEl.clientHeight;
              if (eventEl.classList.contains("v-event")) {
                height += 1;
              }
              if (height > el.clientHeight) {
                eventEl.style.display = "none";
                addMoreCount++;
              }
            });
            // if (this.issueDateMap[date]) {
            //   let moreEl = el.querySelector(".v-event-more-custom-issue");
            //   if (moreEl == null) {
            //     moreEl = document.createElement("div");
            //     moreEl.classList.add("v-event-more-custom-issue");
            //     el.appendChild(moreEl);
            //   }
            // }
            if (addMoreCount > 0) {
              let moreEl = el.querySelector(".v-event-more-custom");
              if (moreEl == null) {
                moreEl = document.createElement("div");
                moreEl.classList.add("v-event-more-custom");
                el.appendChild(moreEl);
              }
              let moreTextEl = el.querySelector(".v-event-more-custom-text");
              if (moreTextEl == null) {
                moreTextEl = document.createElement("div");
                moreTextEl.classList.add("v-event-more-custom-text");
                el.appendChild(moreTextEl);
              }
              moreTextEl.innerHTML = String(addMoreCount);
            }
          }
        });
      }
    },
    viewDay({ date }: any) {
      // this.showScheduleDayModal(date);
      const searchColumns: any = {};
      for (const key of Object.keys(this.searchColumns)) {
        if (key !== "startDate" && key !== "endDate") {
          const value = this.searchColumns[key];
          if (value != null) {
            searchColumns[key] = value;
          } else if (value === null) {
            searchColumns[key] = "null";
          }
        }
      }
      searchColumns.date = date;
      //console.log("searchColumns : ", searchColumns);
      this.showDayModal(searchColumns);
    },
    getEventColor(event: any) {
      return event.color;
    },
    setToday() {
      this.calendar.model = "";
    },
    getCalendarInstance() {
      return this.$refs.calendar as Vue & {
        prev: () => void;
        next: () => void;
        checkChange: () => void;
        getFormatter: (format: any) => any;
      };
    },
    prev() {
      this.getCalendarInstance().prev();
      this.replaceState();
    },
    next() {
      this.getCalendarInstance().next();
      this.replaceState();
    },
    replaceState() {
      const params = core.utils.getUrlParams();
      params.month = core.date.instance(this.calendar.model).format("YYYY-MM");
      const url = this.$route.path + "?" + core.http.objToUrlParams(params);
      history.replaceState("", "", url);
    },
    showEvent({ day, nativeEvent, event }: any) {
      const startMoment = core.date.instance(event.start);
      const endMoment = core.date.instance(event.end);
      const diff = endMoment.diff(startMoment, "days");
      if (diff === 0) {
        const searchColumns: any = {};
        for (const key of Object.keys(this.searchColumns)) {
          if (key !== "startDate" && key !== "endDate") {
            const value = this.searchColumns[key];
            if (value != null) {
              searchColumns[key] = value;
            } else if (value === null) {
              searchColumns[key] = "null";
            }
          }
        }
        searchColumns.date = startMoment.format("YYYY-MM-DD");
        //console.log("searchColumns : ", searchColumns);
        this.showDayModal(searchColumns);
      }
    },
    async updateRange({ start, end }: any) {
      //console.log("updateRange : ", start.date);

      const events = [] as any;
      const startMoment = core.date.instance(`${start.date}T00:00:00`);
      const endMoment = core.date.instance(`${start.date}T23:59:59`);

      // 타이틀 변경
      this.calendar.title = startMoment.format("YYYY년 MM월");

      //console.log(`start ${startMoment.format("YYYY-MM-DD HH:mm:ss")}`);
      //console.log(`end ${endMoment.format("YYYY-MM-DD HH:mm:ss")}`);

      this.searchColumns.startDate = startMoment.format("YYYY-MM-DD");
      this.searchColumns.endDate = startMoment.add(1, "months").format("YYYY-MM-DD");

      this.$nextTick(() => {
        this.reloadFlag = true;
      });
    },
    swipe(direction: string) {
      //console.log("swipe : ", direction);
      if (direction === "Left") {
        this.next();
      } else if (direction === "Right") {
        this.prev();
      } else {
        return;
      }
    },
    moneyFormat(value: string) {
      return core.utils.format.moneyKor(value);
    },
    async drawCalendarEvent(searchColumns: any) {
      core.loader.show();
      try {
        const items = (this.calendar.items = [] as any);
        const params = cloneDeep(searchColumns);
        //console.log("params : ", params);
        const eventList = (await CalendarEventService.getList(params)) as CalendarEventModel[];
        //console.log("eventList : ", eventList);
        eventList.forEach((event) => {
          const startDateMoment = core.date.instance(event.startAt, true);

          const startDate = startDateMoment.toDate();
          let endDate = null as Date | null;
          {
            const endMoment = core.date.instance(event.endAt);
            if (event.allDay) {
              endDate = core.date.instance(endMoment, true).toDate();
            } else {
              const endDateMoment = core.date.instance(endMoment, true);
              const diffDays = endDateMoment.diff(startDate, "days");
              if (diffDays === 1 && endMoment.format("HH:mm:ss") === "00:00:00") {
                endDate = core.date.instance(endMoment, true).add(-1, "days").toDate();
              } else {
                endDate = core.date.instance(endMoment, true).toDate();
              }
            }
          }
          const item = {
            id: event.id,
            name: `${event.title}`,
            start: startDate,
            end: endDate,
            color: event.color,
            timed: false,
          };

          items.push(item);
          // if (schedule.estimate != null && schedule.estimate.paymentYn === "Y") {
          //   paymentScheduleList.push(item);
          // } else if (schedule.complete) {
          //   completeScheduleList.push(item);
          // } else {
          //   items.push(item);
          // }
        });

        this.updatedType = "schedule";
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    showDayModal(searchColumns: any) {
      const modal = this.modal.day;

      modal.params.searchColumns = searchColumns;
      modal.visible = true;
    },
  },
});
