
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import { UserModel } from "@/models/user/user.model";
import CalendarSelectModal from "@/modals/calendar/SelectModal.vue";
import { ApprovalType } from "@/types";
import CompanyService from "@/services/company/company.service";
import { CompanyModel } from "@/models/company/company.model";
import MyCompanyService from "@/services/company/my-company.service";
import { UserCompanyModel } from "@/models/company/user-company.model";
import Constant from "@/store/constant";

export default mixins(MixinsPageForm).extend({
  name: "CompanyInvitation",
  components: { CalendarSelectModal },
  data: () => ({
    ready: false,
    code: "",
    company: null as CompanyModel | null,
    processing: false,
    visible: {
      ios: false,
    },
  }),

  mounted() {
    this.$nextTick(async () => {
      try {
        if (!core.mobile.isApp()) {
          const platform = core.utils.platform();
          if (platform === "ios") {
            this.visible.ios = true;
          }
        }

        const params = this.$route.params;
        this.code = params.code;
        if (this.isBlank(this.code)) {
          await this.notFound();
          return;
        }

        await this.getCompanyInvitation();
        if (this.company == null) {
          return;
        }
        this.ready = true;

        const query = this.$route.query;
        if (query.s) {
          // 자동 선택
          await this.submit();
        }
      } catch (e) {
        console.log(e);
        const query = this.$route.query;
        if (query.s) {
          // 자동 선택
          this.goBack();
        } else {
          await this.notFound();
        }
      }
    });
  },
  methods: {
    moveIosStore() {
      location.href = Constant.iosStoreUrl;
    },
    async getCompanyInvitation() {
      try {
        const company = (await CompanyService.getInvitation(this.code)) as CompanyModel;
        // console.log("company : ", company);
        this.company = company;
      } catch (e: any) {
        if (e.message) {
          await core.alert.show({
            title: "알림",
            body: e.message,
          });
          this.goBack();
        } else {
          throw e;
        }
      }
    },
    async submit() {
      console.log("submit");

      // 앱에서 열도록 처리
      if (!core.mobile.isApp()) {
        const platform = core.utils.platform();
        if (platform === "android") {
          const code = this.code;
          setTimeout(function () {
            const path = "/ci/" + code + "?s=true";
            location.href =
              "intent://action?path=" +
              path +
              "#Intent;scheme=sigong;package=kr.co.scc20.sigong;end";
          }, 200);
          return;
        } else if (platform === "ios") {
          const code = this.code;
          setTimeout(function () {
            const path = "/ci/" + code + "?s=true";
            try {
              location.href = "sigong://action?path=" + path;
            } catch (e) {
              console.log("error : ", e);
            }
          }, 200);
          return;
        }
      }

      // 견적서 받기 처리
      this.processing = true;
      try {
        const user = (await this.$store.getters["auth/user"]()) as UserModel;
        if (user == null) {
          await core.alert.show({
            title: "알림",
            body: "로그인후 다시 시도해주세요",
          });

          const query = {
            companyInvitationCode: this.code,
          };
          this.$router.push({
            path: "/login",
            query: query,
          });
        } else {
          if (user.company) {
            if (user.company.lastApproval.type !== ApprovalType.ALLOW) {
              await core.alert.show({
                title: "알림",
                body: "지금 가입된 업체 탈퇴 후 참가하실 수 있습니다",
              });
              this.$router.push("/company");
              return;
            } else {
              if (user.company.id === this.company?.id) {
                try {
                  await core.alert.show({
                    title: "알림",
                    body: "내 업체 초대는 참가하실 수 없습니다",
                  });
                  if (core.mobile.isApp()) {
                    this.$router.back();
                  }
                  return;
                } catch (e) {
                  console.log(e);
                }
              } else {
                // 업체 정보 존재할 경우
                await core.alert.show({
                  title: "알림",
                  body: "지금 가입된 업체 탈퇴 후 참가하실 수 있습니다",
                });
                this.$router.push("/company/me");
              }
            }
          } else {
            // const query = {
            //   companyInvitationCode: this.code,
            // };
            // this.$router.push({
            //   path: "/company",
            //   query: query,
            // });

            try {
              const params = {
                code: this.code,
              };
              const userCompany = (await MyCompanyService.register(params)) as UserCompanyModel;
              console.log("commit user company : ", userCompany);
              // const user = await this.$store.getters["auth/user"](true);
              await core.alert.show({
                title: "알림",
                body: `${userCompany.name} 업체 가입이 완료되었습니다`,
              });
              this.$router.push("/");
            } catch (e) {
              console.log(e);
            }
          }
        }
      } finally {
        this.processing = false;
      }
    },
  },
});
