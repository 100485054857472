
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CalendarEventAddEditComponent from "@/components/calendar/EventAddEditComponent.vue";
import CalendarSelectComponent from "@/components/calendar/SelectComponent.vue";
import CalendarAddEditComponent from "@/components/calendar/AddEditComponent.vue";

export default mixins(MixinsPage).extend({
  name: "CalendarAdd",
  components: {
    CalendarAddEditComponent,
    CalendarSelectComponent,
    CalendarEventAddEditComponent,
  },
  data: () => ({
    query: null as any,
    params: null as any,
    type: "",
  }),
  mounted() {
    this.query = this.$route.query;
    this.$nextTick(async () => {
      if (this.$store.getters["auth/isUserRole"]) {
        this.params = {
          type: "user",
        };
      }
    });
  },
  watch: {
    type(type) {
      // console.log("type : ", type);
      this.params = {
        type: type,
      };
    },
  },
});
