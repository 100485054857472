
import store from "@/store";
import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MyCompanyService from "@/services/company/my-company.service";

export default mixins(MixinsPageForm).extend({
  name: "CompanyMyWithdraw",
  components: {},
  data: () => ({
    app: store.state.app,
    processing: false,
    form: {
      checkbox: false,
    },
  }),
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    async submit() {
      core.loader.show();
      this.processing = true;
      try {
        const form = this.form;
        const result = await MyCompanyService.delete();
        core.loader.hide();

        await core.alert.show({
          title: "알림",
          body: "업체가 삭제되었습니다. 이용해주셔서 감사합니다.",
        });
        if (this.$route.path.indexOf("/company/me/withdraw") > -1) {
          this.$router.push("/");
        }
      } catch (e) {
        core.loader.hide();
        this.errorSubmit(e);
      }
      this.processing = false;
    },
  },
});
