
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CalendarDayComponent from "@/components/calendar/DayComponent.vue";
import core from "@/core";
import { cloneDeep } from "lodash";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import { UpdateEventType } from "@/types";
import { CalendarModel } from "@/models/calendar/calendar.model";

export default mixins(MixinsPage).extend({
  name: "CalendarDay",
  components: {
    CalendarDayComponent,
  },
  data: () => ({
    title: "일정",
    query: null as any,
    date: "",
    searchColumns: {} as any,
    eventList: [] as CalendarEventModel[],
    visible: {
      addButton: false,
      totalPrice: false,
    },
    totalPrice: 0,
    ready: false,
    loading: false,
  }),
  computed: {
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  mounted() {
    this.$nextTick(async () => {
      const query = (this.query = this.$route.query);
      let calendar = null as CalendarModel | null;
      if (this.isNotBlank(query.calendarId)) {
        const calendarId = Number(query.calendarId);
        const calendarList = (await this.$store.getters[
          "app/getCalendarList"
        ]()) as CalendarModel[];
        calendarList.some((_calendar) => {
          if (_calendar.id === calendarId) {
            calendar = _calendar;
            return true;
          }
        });
      }
      if (calendar != null) {
        this.searchColumns.calendarId = calendar.id;
        this.visible.totalPrice =
          calendar.type === "company" && this.$store.getters["auth/isAdminRoleHigher"];
        this.visible.addButton = !(calendar.type === "company_expected_date");
      } else {
        this.visible.totalPrice = this.$store.getters["auth/isAdminRoleHigher"];
        this.visible.addButton = true;
      }

      if (this.query.date != null) {
        this.date = this.query.date;
        const moment = core.date.instance(this.date);
        if (moment.isValid()) {
          this.title = moment.format("YYYY년 MM월 DD일(ddd)");
          //console.log("title : ", title);
        }
      } else {
        await this.notFound();
      }

      this.ready = true;
    });
  },
  watch: {
    async date(date) {
      const moment = core.date.instance(date);
      if (moment.isValid()) {
        this.title = moment.format("YYYY년 MM월 DD일(ddd)");

        const searchColumns = this.searchColumns;
        searchColumns.startDate = moment.format("YYYY-MM-DD");
        searchColumns.endDate = moment.add(1, "days").format("YYYY-MM-DD");
        if (this.ready) {
          await this.loadCalendarEvent();
        }
      }
    },
  },
  activated() {
    const event = this.getPageUpdateEvent();
    if (event) {
      if (
        event.result === UpdateEventType.RELOAD ||
        event.result === UpdateEventType.UPDATE ||
        event.result === UpdateEventType.DELETE
      ) {
        this.loadCalendarEvent();
        this.updatePreVmEvent(event.result, event.item);
      } else {
        console.log("unknown event result : ", event);
      }
    }
  },
  methods: {
    swipe(direction: string) {
      //console.log("direction : ", direction);
      if (direction === "Left") {
        this.next();
      } else if (direction === "Right") {
        this.prev();
      } else {
        return;
      }
    },
    next() {
      const moment = core.date.instance(this.date).add(1, "days");
      this.date = moment.format("YYYY-MM-DD");
      const title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");
      this.$store.dispatch("topToolbar/changeTitle", { title: title });

      const params = cloneDeep(this.query);
      params.date = this.date;
      this.replaceState(params);
    },
    prev() {
      const moment = core.date.instance(this.date).add(-1, "days");
      this.date = moment.format("YYYY-MM-DD");
      const title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");
      this.$store.dispatch("topToolbar/changeTitle", { title: title });

      const params = cloneDeep(this.query);
      params.date = this.date;
      this.replaceState(params);
    },
    async loadCalendarEvent() {
      if (this.loading) {
        console.log("already load");
        return;
      }
      this.loading = true;

      try {
        const eventList = (await CalendarEventService.getList(
          this.searchColumns
        )) as CalendarEventModel[];
        //console.log("eventList : ", eventList);
        CalendarEventService.sortEventList(eventList, this.searchColumns);
        let totalPrice = 0;
        eventList.forEach((event) => {
          totalPrice += event.price;
        });
        this.eventList = eventList;
        this.totalPrice = totalPrice;
        //console.log("11 eventList : ", eventList);
      } catch (e) {
        console.log(e);
        this.goBack();
      } finally {
        this.loading = false;
      }
    },
  },
});
