
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { ComponentProperties } from "@/mixins/component";

export default mixins(MixinsModal).extend({
  name: "EstimateRemarkModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            form: {
              remark: "",
            },
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    rows: 15,
  }),
  mounted() {
    this.$nextTick(async () => {
      const height = this.app.size.height - 56 - 200;
      this.rows = parseInt(String(height / 20));

      console.log("height : ", height);
      console.log("nextTick");
      console.log("properties : ", this.properties);
    });
  },
  methods: {
    submit() {
      console.log("submit");
      this.cancel();
    },
  },
});
