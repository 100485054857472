
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimateService from "@/services/estimate/estimate.service";
import EstimatePaymentService from "@/services/estimate/estimate-payment.service";
import { EstimateModel, PaymentModel } from "@/models/estimate/estimate.model";
import UpdateEvent from "@/models";
import EstimatePaymentAddEditModal from "@/modals/estimate/PaymentAddEditModal.vue";
import { CompanyDetailType, UpdateEventType } from "@/types";
import core from "@/core";
import { UserModel } from "@/models/user/user.model";
import { UserCompanyModel } from "@/models/company/user-company.model";
import { BankAccountModel } from "@/models/company/bank-account.model";
import Constant from "@/store/constant";

export default mixins(MixinsPage).extend({
  name: "EstimatePayment",
  components: { EstimatePaymentAddEditModal },
  data: () => ({
    ready: false,
    estimate: {} as EstimateModel,
    estimateUpdatedAt: null as Date | null,
    paymentList: [] as PaymentModel[],
    modal: {
      paymentAddEdit: {
        visible: false,
        params: {
          type: "add",
          estimate: null as EstimateModel | null,
          payment: null as any | null,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),

  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params;
      //console.log("query : ", query);
      if (this.isBlank(params.id)) {
        await this.notFound();
        return;
      }

      const estimateId = Number(params.id);
      try {
        this.estimate = await this.getEstimate(estimateId);
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }

      try {
        const paymentList = (this.paymentList = (await EstimatePaymentService.getList(
          estimateId
        )) as PaymentModel[]);
        this.sortPaymentList();
        //console.log("paymentList : ", paymentList);
      } catch (e) {
        console.log(e);
      }

      this.ready = true;
    });
  },
  activated() {},
  watch: {
    "modal.paymentAddEdit.updateEvent"(event) {
      const modal = this.modal.paymentAddEdit;
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          if (modal.params.type === "add") {
            this.addPayment(event.item);
          } else if (modal.params.type === "edit") {
            this.editPayment(modal.params.payment, event.item);
          } else {
            console.log("unknown type : ", event);
          }
        } else if (event.result === UpdateEventType.DELETE) {
          this.deletePayment(modal.params.payment);
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
  },
  methods: {
    async sendPaymentSms() {
      const user = (await this.$store.getters["auth/user"]()) as UserModel;
      if (user.company) {
        const company = user.company as UserCompanyModel;
        let content = `[${company.name}]\n결제요청\n\n`;
        if (company.details) {
          const bankAccount = company.details[CompanyDetailType.BANK_ACCOUNT];
          if (bankAccount) {
            bankAccount.data.list.some((item: BankAccountModel) => {
              //console.log("item : ", item);
              if (item.default) {
                content += `은행: ${item.bankName}\n`;
                content += `계좌번호: ${item.bankAccount}\n`;
                content += `예금주: ${item.accountHolder}\n`;
                return true;
              }
            });
          }
        }
        const strPrice = this.moneyFormat(String(this.estimate.balance));
        content += `금액: ${strPrice}\n`;
        content += "\n";
        content += "아래 링크를 통해 결제 정보를 확인하세요!\n";
        let val = window.btoa(this.estimate.customerPhone + "," + this.estimate.authCode);
        content += Constant.customerWebUrl + `/login?v=${val}&p=/estimate/payment\n`;
        content += "인증코드 " + this.estimate.authCode;
        // console.log("content : ", content);
        // const url = "sms:" + this.estimate.customerPhone + "?body=" + encodeURIComponent(content);
        location.href = core.mobile.getSmsLink(this.estimate.customerPhone, content);
      } else {
        console.log("not found user company : ", user);
      }
    },
    isReloadEstimate() {
      const date = new Date();
      if (this.estimateUpdatedAt == null) return true;
      // 견적 조회 이후, 1분 이상 지났을때 다시 조회하도록 처리
      return date.getTime() - this.estimateUpdatedAt.getTime() > 60000;
    },
    async getEstimate(estimateId): Promise<EstimateModel> {
      const estimate = (await EstimateService.get(estimateId)) as EstimateModel;
      this.estimateUpdatedAt = new Date();
      return estimate;
    },
    showPaymentModal(type, item?) {
      const modal = this.modal.paymentAddEdit;
      modal.params.type = type;
      modal.params.estimate = this.estimate;
      modal.params.payment = item;
      modal.visible = true;
    },
    async addPayment(form) {
      core.loader.show("저장중...");
      const estimate = this.estimate;
      try {
        const _payment = (await EstimatePaymentService.create(estimate.id, form)) as PaymentModel;
        //console.log("payment : ", _payment);
        const paymentList = this.paymentList;
        paymentList.push(_payment);
        this.sortPaymentList();

        if (this.isReloadEstimate()) {
          this.estimate = await this.getEstimate(this.estimate.id);
          this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
        } else {
          this.estimate.payment += _payment.price;
          this.estimate.balance -= _payment.price;
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        }
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async editPayment(payment: PaymentModel, form) {
      core.loader.show("저장중...");
      const estimate = this.estimate;
      try {
        const _payment = (await EstimatePaymentService.update(
          estimate.id,
          payment.id,
          form
        )) as PaymentModel;
        //console.log("payment : ", _payment);
        const paymentList = this.paymentList;
        for (let i = 0; i < paymentList.length; i++) {
          const __payment = paymentList[i];
          if (__payment.id === payment.id) {
            paymentList.splice(i, 1);
            break;
          }
        }
        paymentList.push(_payment);
        this.sortPaymentList();

        if (this.isReloadEstimate()) {
          this.estimate = await this.getEstimate(this.estimate.id);
          this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
        } else {
          // 이전 금액 삭제, 추가
          this.estimate.payment -= payment.price;
          this.estimate.balance += payment.price;

          // 수정된 금액 추가, 삭제
          this.estimate.payment += _payment.price;
          this.estimate.balance -= _payment.price;
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        }
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async deletePayment(payment: PaymentModel) {
      core.loader.show("삭제중...");
      const estimate = this.estimate;
      try {
        await EstimatePaymentService.delete(estimate.id, payment.id);
        const paymentList = this.paymentList;
        for (let i = 0; i < paymentList.length; i++) {
          const _payment = paymentList[i];
          if (_payment.id === payment.id) {
            paymentList.splice(i, 1);
            break;
          }
        }

        if (this.isReloadEstimate()) {
          this.estimate = await this.getEstimate(this.estimate.id);
          this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
        } else {
          // 이전 금액 삭제, 추가
          this.estimate.payment -= payment.price;
          this.estimate.balance += payment.price;
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        }
      } catch (e) {
        console.log(e);
      }

      core.loader.hide();
    },
    sortPaymentList() {
      this.paymentList.sort((a, b) => {
        const aMoment = core.date.instance(a.date);
        const bMoment = core.date.instance(b.date);
        if (aMoment.isBefore(bMoment)) return -1;
        else if (bMoment.isBefore(aMoment)) return 1;
        return 0;
      });
    },
    typeToText(item: PaymentModel) {
      return EstimatePaymentService.typeToText(item);
    },
  },
});
