
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { EstimateModel } from "@/models/estimate/estimate.model";
import HintView from "@/components/core/HintView.vue";
import EstimateService from "@/services/estimate/estimate.service";
import EstimateRemarkModal from "@/modals/estimate/RemarkModal.vue";
import core from "@/core";
import { UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";
import EstimateItemInputModal from "@/modals/estimate/ItemInputModal.vue";
import EstimateRecentItemModal from "@/modals/estimate/RecentItemModal.vue";

export default mixins(MixinsPageForm).extend({
  name: "EstimateInfoEditComponent",
  components: { EstimateRecentItemModal, EstimateItemInputModal, EstimateRemarkModal, HintView },
  props: {
    query: {
      type: Object,
      default: () => {
        return {
          view: "",
        };
      },
    },
    estimate: {
      type: Object as () => EstimateModel,
      default: () => {
        return {} as EstimateModel;
      },
    },
    place: {
      type: Object,
      default: () => {
        return {
          address1: "",
          address2: "",
          latitude: 0,
          longitude: 0,
        };
      },
    },
  },
  data: () => ({
    ready: false,

    form: {
      account: "",
      address: "",
      latitude: "",
      longitude: "",
      customerPhone: "",
      customerName: "",
      expectedDate: "",
      dong: "",
      ho: "",
    },
    datePicker: {
      visible: false,
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const view = this.query.view;
      if (view !== "info" && view !== "contract") {
        console.log("not support view type : ", view);
        return;
      }

      const estimate = this.estimate;

      const form = this.form;
      form.account = estimate.account;
      form.address = estimate.address == null ? "" : estimate.address;
      if (estimate.latitude != null) {
        form.latitude = String(estimate.latitude);
      }
      if (estimate.longitude != null) {
        form.longitude = String(estimate.longitude);
      }
      form.customerPhone = estimate.customerPhone;
      form.customerName = estimate.customerName;
      form.expectedDate = estimate.expectedDate;
      form.dong = estimate.dong ? estimate.dong : "";
      form.ho = estimate.ho ? estimate.ho : "";
      setTimeout(() => {
        this.changeValue(false);
        this.ready = true;
      }, 500);
    });
  },
  computed: {},
  watch: {
    place(place) {
      // console.log("place : ", place);
      if (place) {
        if (this.isNotBlank(place.address2)) {
          this.form.address = place.address1 + " " + place.address2;
        } else {
          this.form.address = place.address1;
        }
        this.form.latitude = place.latitude;
        this.form.longitude = place.longitude;
      }
    },
    "form.customerPhone"(val) {
      this.form.customerPhone = core.utils.format.hyphenPhone(val);
      this.changeValue();
    },
    "form.address"(val) {
      this.changeValue();

      if (this.isBlank(val)) {
        // console.log("reset location");
        this.form.latitude = "";
        this.form.longitude = "";
      }
    },
    "form.customerName"(val) {
      this.changeValue();
    },
    "form.expectedDate"(val) {
      this.changeValue();
    },
    "form.dong"(val) {
      this.changeValue();
    },
    "form.ho"(val) {
      this.changeValue();
    },
  },
  methods: {
    showSearchAddressPage() {
      // console.log("estimate : ", this.estimate);
      // console.log("place : ", this.place);
      const params = {
        level: "2",
      } as any;

      if (this.place != null) {
        if (this.place.latitude && this.place.longitude) {
          params.latitude = this.place.latitude;
          params.longitude = this.place.longitude;
        }
        if (this.place.address1) {
          params.address1 = this.place.address1;
        }
        if (this.place.address2) {
          params.address2 = this.place.address2;
        }
      } else if (this.estimate != null && this.estimate.latitude && this.estimate.longitude) {
        params.latitude = this.estimate.latitude;
        params.longitude = this.estimate.longitude;
        params.address1 = this.estimate.address;
      }

      this.$router.push({ path: "/search-address", query: params });
    },
    changeValue(changed?: boolean) {
      if (changed == null) changed = true;
      this.changeBackButton(changed);
    },
    async submit() {
      if (await this.validate()) {
        const view = this.query.view;
        let msg = "";
        if (view === "info") {
          msg = "수정된 견적서 기본정보를 저장하시겠습니까?";
        } else if (view === "contract") {
          msg = "작성된 계약서를 저장하시겠습니까?";
        }

        const confirmResult = await core.alert.show({
          title: "확인",
          body: msg,
          showCancelButton: true,
          cancelButtonText: "취소",
          confirmButtonText: "저장",
        });
        if (confirmResult !== "confirm") {
          return;
        }

        core.loader.show("저장중...");

        const estimate = this.estimate as EstimateModel;

        const params = cloneDeep(this.form) as any;
        // params.memo = estimate.memo;
        params.remark = estimate.remark;
        params.detailList = [] as any;

        estimate.detailList.forEach((detail) => {
          params.detailList.push({
            id: detail.id,
            description: detail.description,
            specification: detail.specification,
            quantity: detail.quantity,
            unitPrice: detail.unitPrice,
          });
        });

        ///console.log("params : ", params);
        try {
          if (view === "contract" && estimate.contract === "WAIT") {
            const result = await EstimateService.updateContract(estimate.id, "COMPLETE");
            //console.log("result : ", result);
          }
          const updateEstimate = (await EstimateService.update(estimate.id, params)) as any;
          //console.log("updateEstimate : ", updateEstimate);
          this.goBack(UpdateEventType.UPDATE, updateEstimate);
        } catch (e) {
          console.log(e);
        }

        core.loader.hide();
      }
    },
  },
});
