
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";
import core from "@/core";

export default mixins(MixinsPageForm).extend({
  name: "Settings",
  components: {},
  data: () => ({
    app: store.state.app,
    auth: store.state.auth,
    processing: false,
    settings: {
      notification: false,
    },
  }),
  mounted() {
    const mobileSetting = this.auth.mobileSetting;
  },
  watch: {
    "auth.mobileSetting"(mobileSetting) {
      if (mobileSetting) {
        this.processing = false;
      }
    },
  },
  methods: {
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
    // updateCustomerReceiveCall() {
    //   if (!this.processing) {
    //     this.processing = true;
    //     const mobileSetting = this.$store.state.auth.mobileSetting as MobileSetting | null;
    //     // console.log("mobileSetting : ", mobileSetting);
    //     if (mobileSetting != null && mobileSetting.customerReceiveCall) {
    //       // 고객 수신 전화 설정 변경
    //       mobileSetting.customerReceiveCall.enable = !mobileSetting.customerReceiveCall.enable;
    //       core.mobile.call({
    //         cmd: "updateCustomerReceiveCall",
    //         value: mobileSetting.customerReceiveCall,
    //       });
    //     } else {
    //       this.processing = false;
    //     }
    //   }
    // },
    approvalToText() {
      if (this.auth.user == null) return "";
      const user = this.auth.user as UserModel;
      let content = "알 수 없음";
      if (user.company != null) {
        if (user.company.lastApproval.type === "ALLOW") {
          content = "승인";
        } else if (user.company.lastApproval.type === "REQUEST") {
          content = "승인 요청중";
        } else if (user.company.lastApproval.type === "DENY") {
          content = "승인 거절";
        }
      }
      //console.log("content : ", content);
      return content;
    },

    approvalToClass() {
      if (this.auth.user == null) return "";
      const user = this.auth.user as UserModel;
      let content = "";
      if (user.company != null) {
        if (user.company.lastApproval.type === "ALLOW") {
          content = "green--text";
        } else if (user.company.lastApproval.type === "REQUEST") {
          content = "orange--text";
        } else if (user.company.lastApproval.type === "DENY") {
          content = "red--text";
        }
      }
      //console.log("content : ", content);
      return content;
    },
    companyRoleToText(role) {
      if (role === "OWNER") {
        return "소유주";
      } else if (role === "ADMIN") {
        return "관리자";
      } else if (role === "MANAGER") {
        return "매니저";
      } else if (role === "USER") {
        return "일반사용자";
      } else {
        return "알 수 없음";
      }
    },
  },
});
