
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import EstimateService from "@/services/estimate/estimate.service";
import { EstimateModel } from "@/models/estimate/estimate.model";

export default mixins(MixinsCommon).extend({
  name: "EstimateListItemComponent",

  props: {
    item: {
      type: Object as () => EstimateModel,
      default: () => {
        return null as EstimateModel | null;
      },
    },
  },
  mounted() {},
  methods: {
    titleToText(item: EstimateModel) {
      return EstimateService.titleToText(item);
    },
    contractToHtml(item: EstimateModel) {
      return EstimateService.contractToHtml(item);
    },
    priceToHtml(item: EstimateModel) {
      return EstimateService.priceToHtml(item);
    },
    scheduleToHtml(item: EstimateModel) {
      return EstimateService.scheduleToHtml(item);
    },
  },
});
