
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimateComponent from "@/components/estimate/EstimateComponent.vue";
import UpdateEvent from "@/models";
import AppBar from "@/components/core/AppBar.vue";
import { cloneDeep } from "lodash";
import { LayoutType } from "@/router";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import { AppBarMenuItem } from "@/types";
import { defaultTopToolbar } from "@/store/modules/top-toolbar";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "Estimate",
  components: { EstimateComponent, AppBar },
  data: () => ({
    type: "",
    ready: false,
    properties: {
      visible: false,
      updateEvent: null as UpdateEvent | null,
    },
    filter: {
      selected: null as any,
      selectedItem: null as any,
      items: [] as any,
    },
    prop: null as any,
  }),
  mounted() {
    this.$nextTick(() => {
      this.filter.items = [
        { id: "default", text: "견적서" },
        { id: "contract", text: "계약서" },
      ];

      const meta = this.$route.meta as any;
      const layoutList = cloneDeep(meta.layoutList) as any;
      layoutList.push(LayoutType.NAV);
      this.prop = {
        layoutList: layoutList,
        appBarMenu: meta.appBarMenu,
      };
      this.changedFilter(0);
    });
  },
  watch: {
    "filter.selected"(selected) {
      this.changedFilter(selected);
    },
  },
  activated() {
    // console.log("activated");
    const event = this.getPageUpdateEvent();
    if (event != null) {
      this.properties.updateEvent = event;
    }
    this.$store.dispatch("topToolbar/changeStyle", {
      clazz: defaultTopToolbar.clazz,
      dark: defaultTopToolbar.dark,
      elevation: 1,
      color: defaultTopToolbar.color,
    });
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "search") {
        // console.log("event");
        this.$router.push({ path: "/estimate/search" });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    changedFilter(selected) {
      if (selected != null) {
        const selectedItem = this.filter.items[selected];
        if (selectedItem) {
          console.log("selectedItem : ", selectedItem);
          this.filter.selectedItem = selectedItem;
          this.type = selectedItem.id;
          this.properties.visible = true;
        } else {
          console.log("not found item : ", selected);
        }
      }
    },
  },
});
