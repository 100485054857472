
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import { ComponentProperties } from "@/mixins/component";

export default mixins(MixinsModal).extend({
  name: "HelpUnitPriceTableModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
        } as ComponentProperties;
      },
    },
  },
});
