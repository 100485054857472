import { IdUpdateModel } from "@/models/core/type.model";

export enum CalendarType {
  USER = "user",
  COMPANY = "company",
  COMPANY_EXPECTED_DATE = "company_expected_date",
}

export interface CalendarModel extends IdUpdateModel {
  name: string;
  no: number;
  memo: string;
  type: CalendarType;
}
