
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { EstimateModel } from "@/models/estimate/estimate.model";
import HintView from "@/components/core/HintView.vue";
import EstimateService from "@/services/estimate/estimate.service";
import EstimateRemarkModal from "@/modals/estimate/RemarkModal.vue";
import core from "@/core";
import { StorageType, UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";
import EstimateItemInputModal from "@/modals/estimate/ItemInputModal.vue";
import UpdateEvent from "@/models";
import EstimateRecentItemModal from "@/modals/estimate/RecentItemModal.vue";
import MixinsStorage from "@/mixins/single/storage";
import UnitPriceTableService from "@/services/unit-price/unit-price-table.service";
import LoadUnitPriceModal from "@/modals/unit-price/LoadUnitPriceModal.vue";
import SelectUnitPriceTableModal from "@/modals/unit-price/SelectUnitPriceTableModal.vue";
import { UnitPriceTableModel } from "@/models/unit-price/unit-price-table.model";
import SelectEstimateOrUnitPriceTableModal from "@/modals/estimate/SelectEstimateOrUnitPriceTableModal.vue";
import SelectEstimateModal from "@/modals/estimate/SelectEstimateModal.vue";
import HelpUnitPriceTableModal from "@/modals/unit-price/HelpUnitPriceTableModal.vue";

export interface DetailModel {
  id: number | null;
  index: number;
  no: number | null;
  description: string; // 품명
  specification: string; // 규격
  quantity: number; // 수량
  quantityModel: string; // 수량 모델
  unitPrice: number; // 단가
  unitPriceModel: string; // 단가 모델
  amount: number; // 합계
  amountModel: string;
}

export default mixins(MixinsPageForm, MixinsStorage).extend({
  name: "EstimateAddEditComponent",
  components: {
    HelpUnitPriceTableModal,
    SelectEstimateModal,
    SelectEstimateOrUnitPriceTableModal,
    SelectUnitPriceTableModal,
    LoadUnitPriceModal,
    EstimateRecentItemModal,
    EstimateItemInputModal,
    EstimateRemarkModal,
    HintView,
  },
  props: {
    type: {
      type: String,
      default: "add",
    },
    query: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
    estimate: {
      type: Object as () => EstimateModel,
      default: () => {
        return null as EstimateModel | null;
      },
    },
    place: {
      type: Object,
      default: () => {
        return {
          address1: "",
          address2: "",
          latitude: 0,
          longitude: 0,
        };
      },
    },
  },
  data: () => ({
    storageItems: {
      type: StorageType.ESTIMATE,
    },
    ready: false,
    form: {
      account: "",
      address: "",
      latitude: "",
      longitude: "",
      customerPhone: "",
      remark: "",
    },
    formConvertField: {
      account: "estimate_account",
      address: "estimate_address",
      customerPhone: "estimate_customerPhone",
      remark: "estimate_remark",
    },
    totalPrice: 0,
    detailIndex: 0,
    detailList: [] as DetailModel[],
    simpleEstimateSwitch: false,
    addUnitPriceTableSwitch: false,
    storageRemarkItems: null as any,
    unitPriceTable: {
      switch: false,
      name: "",
    },
    visible: {
      help1: true,
      help1Visible: true,
    },
    modal: {
      itemInput: {
        visible: false,
        params: {
          item: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
      recentItem: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      selectUnitPriceTable: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      selectEstimate: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      selectEstimateOrUnitPriceTable: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      helpUnitPriceTable: {
        visible: false,
      },
      remark: {
        visible: false,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      // storage
      await this.storageWait();

      const storage = this.getStorageItems();
      if (storage) {
        if (storage.simpleEstimate == null) {
          storage.simpleEstimate = true;
        }
        this.simpleEstimateSwitch = storage.simpleEstimate;
        if (storage.visibleHelp1 == null) {
          storage.visibleHelp1 = true;
          this.saveStorageItems();
        }
        this.visible.help1 = storage.visibleHelp1;
      }

      if (this.type === "add") {
        const query = this.$route.query;
        if (this.isNotBlank(query.phone)) {
          this.form.customerPhone = String(query.phone);
        }
        // console.log("query : ", query);
        this.ready = true;
      } else if (this.type === "edit") {
        // 견적서 수정
        // console.log("estimate : ", this.estimate);
        const estimate = this.estimate;
        this.form.customerPhone = estimate.customerPhone;
        this.form.account = estimate.account;
        this.form.address = estimate.address == null ? "" : estimate.address;
        this.form.remark = estimate.remark;

        estimate.detailList.forEach((item) => {
          const detail: DetailModel = {
            id: item.id,
            no: null,
            index: this.detailIndex++,
            description: item.description,
            specification: item.specification,
            quantity: Number(item.quantity),
            quantityModel: String(item.quantity),
            unitPrice: Number(item.unitPrice),
            unitPriceModel: String(item.unitPrice),
            amount: Number(item.amount),
            amountModel: String(item.amount),
          };
          this.detailList.push(detail);
        });
        this.changedDetail();
        setTimeout(() => {
          this.changeValue(false);
          this.ready = true;
        }, 500);
      } else {
        this.ready = true;
      }

      const storageRemarkItems = await this.$store.dispatch(
        "storage/getStorageItem",
        StorageType.ESTIMATE_REMARK
      );
      if (storageRemarkItems.list == null) {
        storageRemarkItems.list = [];
        await this.$store.dispatch("storage/setStorageItem", {
          type: StorageType.ESTIMATE_REMARK,
          items: storageRemarkItems,
        });
      }
      this.storageRemarkItems = storageRemarkItems;
    });
  },
  computed: {},
  watch: {
    "visible.help1Visible"(visible) {
      console.log("visible : ", visible);
      const storage = this.getStorageItems();
      if (storage) {
        storage.visibleHelp1 = false;
        this.saveStorageItems();
        this.visible.help1 = false;
      }
    },
    place(place) {
      // console.log("place : ", place);
      if (place) {
        if (this.isNotBlank(place.address2)) {
          this.form.address = place.address1 + " " + place.address2;
        } else {
          this.form.address = place.address1;
        }
        this.form.latitude = place.latitude;
        this.form.longitude = place.longitude;
      }
    },
    simpleEstimateSwitch(val) {
      if (val != null) {
        const storage = this.getStorageItems();
        if (storage) {
          if (storage.simpleEstimate != val) {
            storage.simpleEstimate = val;
            this.saveStorageItems();
          }
        }
      }
    },
    "form.remark"(val) {
      this.changeValue();
    },
    "form.address"(val) {
      this.changeValue();
      if (this.isBlank(val)) {
        // console.log("reset location");
        this.form.latitude = "";
        this.form.longitude = "";
      }
    },
    "form.customerPhone"(val) {
      this.form.customerPhone = core.utils.format.hyphenPhone(val);
      this.changeValue();
    },
    async "modal.itemInput.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.itemInput);
      if (event != null) {
        // console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          //console.log("item : ", item);
          if (item.index != null) {
            const detailList = this.detailList;
            for (let i = 0; i < detailList.length; i++) {
              const detail = detailList[i];
              if (detail.index === item.index) {
                detail.description = item.description;
                detail.specification = item.specification;
                detail.quantity = Number(item.quantity);
                detail.quantityModel = String(item.quantity);
                detail.unitPrice = Number(item.unitPrice);
                detail.unitPriceModel = String(item.unitPrice);
                detail.amount = Number(item.amount);
                detail.amountModel = String(item.amount);
                break;
              }
            }
            this.changedDetail();
          } else {
            this.detailIndex++;
            const detail: DetailModel = {
              id: null,
              no: null,
              index: this.detailIndex,
              description: item.description,
              specification: item.specification,
              quantity: Number(item.quantity),
              quantityModel: item.quantity,
              unitPrice: Number(item.unitPrice),
              unitPriceModel: item.unitPrice,
              amount: Number(item.amount),
              amountModel: String(item.amount),
            };
            this.detailList.push(detail);
            this.changedDetail();
          }
        } else if (event.result === UpdateEventType.EVENT) {
          const item = event.item;
          // console.log("item : ", item);
          if (item.type === "showGetRecentItem") {
            this.showGetRecentItemModal();
          } else {
            console.log("Unknown type : ", event);
          }
        } else if (event.result === UpdateEventType.DELETE) {
          const item = event.item;
          let detail = null as DetailModel | null;
          this.detailList.some((_detail) => {
            if (_detail.index === item.index) {
              detail = _detail;
              return true;
            }
          });
          if (detail != null) {
            this.removeDetail(detail);
          } else {
            console.log("not found detail : ", item);
          }
        } else {
          console.log("Unknown type : ", event);
        }
      }
    },
    "modal.recentItem.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.recentItem);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          this.showItemInputModal({
            description: item.description,
            specification: item.specification,
            quantity: Number(item.quantity),
            quantityModel: item.quantity,
            unitPrice: Number(item.unitPrice),
            unitPriceModel: item.unitPrice,
            amount: item.amount,
          });
        } else {
          console.log("Unknown type : ", event);
        }
      }
    },
    "modal.selectEstimateOrUnitPriceTable.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectEstimateOrUnitPriceTable);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          if (item === "estimate") {
            this.showSelectEstimateModal();
          } else if (item === "unitPriceTable") {
            this.showSelectUnitPriceTableModal();
          } else {
            console.log("unknown result item : ", item);
          }
        } else {
          console.log("Unknown type : ", event);
        }
      }
    },
    async "modal.selectUnitPriceTable.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectUnitPriceTable);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;

          try {
            const unitPriceTable = (await UnitPriceTableService.get(
              item.id
            )) as UnitPriceTableModel;
            //console.log("unitPriceTable : ", unitPriceTable);
            if (unitPriceTable.detailList) {
              const unitPriceTableDetailList = unitPriceTable.detailList;
              for (let i = 0; i < unitPriceTableDetailList.length; i++) {
                const unitPriceTableDetail = unitPriceTableDetailList[i];
                let detail = null as DetailModel | null;
                this.detailList.some((_detail) => {
                  if (_detail.description.trim() === unitPriceTableDetail.description.trim()) {
                    detail = _detail;
                    return true;
                  }
                });
                if (detail == null) {
                  const detail: DetailModel = {
                    id: null,
                    no: null,
                    index: this.detailIndex++,
                    description: unitPriceTableDetail.description,
                    specification: unitPriceTableDetail.specification,
                    quantity: Number(unitPriceTableDetail.quantity),
                    quantityModel: String(unitPriceTableDetail.quantity),
                    unitPrice: Number(unitPriceTableDetail.unitPrice),
                    unitPriceModel: String(unitPriceTableDetail.unitPrice),
                    amount: unitPriceTableDetail.amount,
                    amountModel: String(unitPriceTableDetail.amount),
                  };
                  this.detailList.push(detail);
                } else {
                  const confirmResult = await core.alert.show({
                    title: "확인",
                    body: `"${detail.description}" 품명이 중복됩니다.<br>해당 내용을 변경하시겠습니까?`,
                    showCancelButton: true,
                    cancelButtonText: "아니오",
                    confirmButtonText: "변경",
                  });
                  if (confirmResult === "confirm") {
                    detail.specification = unitPriceTableDetail.specification;
                    detail.specification = unitPriceTableDetail.specification;
                    detail.quantity = Number(unitPriceTableDetail.quantity);
                    detail.quantityModel = String(unitPriceTableDetail.quantity);
                    detail.unitPrice = Number(unitPriceTableDetail.unitPrice);
                    detail.unitPriceModel = String(unitPriceTableDetail.unitPrice);
                    detail.amount = unitPriceTableDetail.amount;
                    detail.amountModel = String(unitPriceTableDetail.amount);
                  }
                }
              }
              this.changedDetail();
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          console.log("Unknown type : ", event);
        }
      }
    },
    async "modal.selectEstimate.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectEstimate);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          try {
            const estimate = (await EstimateService.get(item.id)) as EstimateModel;
            //console.log("estimate : ", estimate);
            if (estimate.detailList) {
              const estimateDetailList = estimate.detailList;

              for (let i = 0; i < estimateDetailList.length; i++) {
                const estimateDetail = estimateDetailList[i];
                let detail = null as DetailModel | null;
                this.detailList.some((_detail) => {
                  if (_detail.description.trim() === estimateDetail.description.trim()) {
                    detail = _detail;
                    return true;
                  }
                });

                if (detail == null) {
                  const detail: DetailModel = {
                    id: null,
                    no: null,
                    index: this.detailIndex++,
                    description: estimateDetail.description,
                    specification: estimateDetail.specification,
                    quantity: Number(estimateDetail.quantity),
                    quantityModel: String(estimateDetail.quantity),
                    unitPrice: Number(estimateDetail.unitPrice),
                    unitPriceModel: String(estimateDetail.unitPrice),
                    amount: Number(estimateDetail.amount),
                    amountModel: String(estimateDetail.amount),
                  };
                  this.detailList.push(detail);
                } else {
                  const confirmResult = await core.alert.show({
                    title: "확인",
                    body: `"${detail.description}" 품명이 중복됩니다.<br>해당 내용을 변경하시겠습니까?`,
                    showCancelButton: true,
                    cancelButtonText: "아니오",
                    confirmButtonText: "변경",
                  });
                  if (confirmResult === "confirm") {
                    detail.specification = estimateDetail.specification;
                    detail.specification = estimateDetail.specification;
                    detail.quantity = Number(estimateDetail.quantity);
                    detail.quantityModel = String(estimateDetail.quantity);
                    detail.unitPrice = Number(estimateDetail.unitPrice);
                    detail.unitPriceModel = String(estimateDetail.unitPrice);
                    detail.amount = Number(estimateDetail.amount);
                    detail.amountModel = String(estimateDetail.amount);
                  }
                }
              }
              this.changedDetail();
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          console.log("Unknown type : ", event);
        }
      }
    },
  },
  methods: {
    showRemarkHistory() {
      console.log("show remark history");
      const modal = this.modal.remark;
      modal.visible = true;
    },
    showSearchAddressPage() {
      // console.log("place : ", this.place);
      const params = {
        level: "2",
      } as any;

      if (this.place != null) {
        if (this.place.latitude && this.place.longitude) {
          params.latitude = this.place.latitude;
          params.longitude = this.place.longitude;
        }
        if (this.place.address1) {
          params.address1 = this.place.address1;
        }
        if (this.place.address2) {
          params.address2 = this.place.address2;
        }
      } else if (this.estimate != null && this.estimate.latitude && this.estimate.longitude) {
        params.latitude = this.estimate.latitude;
        params.longitude = this.estimate.longitude;
        params.address1 = this.estimate.address;
      }

      this.$router.push({ path: "/search-address", query: params });
    },
    showHelpUnitPriceTable() {
      const modal = this.modal.helpUnitPriceTable;
      modal.visible = true;
    },
    showSelectEstimateOrUnitPriceTableModal() {
      // 견적서 또는 단가표 가져오기 선택 modal
      const modal = this.modal.selectEstimateOrUnitPriceTable;
      modal.visible = true;
    },
    showSelectEstimateModal() {
      // 단가표 가져오기
      const modal = this.modal.selectEstimate;
      modal.visible = true;
    },
    showSelectUnitPriceTableModal() {
      // 단가표 가져오기
      const modal = this.modal.selectUnitPriceTable;
      modal.visible = true;
    },
    showGetRecentItemModal() {
      // 최근항목가져오기
      const modal = this.modal.recentItem;
      modal.visible = true;
    },
    showItemInputModal(item) {
      const modal = this.modal.itemInput;
      modal.params.item = item;
      modal.visible = true;
    },
    addDetail() {
      this.detailIndex++;
      const detail: DetailModel = {
        id: null,
        no: null,
        index: this.detailIndex,
        description: "",
        specification: "",
        quantity: 1,
        quantityModel: "1",
        unitPrice: 0,
        unitPriceModel: "",
        amount: 0,
        amountModel: "",
      };
      this.detailList.push(detail);
      this.changedDetail();
    },
    async removeDetail(detail) {
      //console.log("removeDetail : ", detail);
      let showAlert = false;
      if (this.type === "edit") {
        const estimateDetailList = this.estimate.detailList;
        for (let i = 0; i < estimateDetailList.length; i++) {
          const estimateDetail = estimateDetailList[i];
          if (estimateDetail.id === detail.id) {
            let body = "";
            if (estimateDetail.calendarEventId != null) {
              body = "일정에 포함된 견적 항목 입니다.<br>삭제 하시겠습니까?";
            }

            if (body.length > 0) {
              showAlert = true;
              const params = {
                title: "확인",
                body: body,
                showCancelButton: true,
              };
              const result = await core.alert.show(params);
              if (result !== "confirm") {
                return;
              }
            }

            break;
          }
        }
      }

      if (!showAlert && this.isNotBlank(detail.description)) {
        const params = {
          title: "확인",
          body: "항목을 삭제하시겠습니까?",
          showCancelButton: true,
        };
        const result = await core.alert.show(params);
        if (result !== "confirm") {
          return;
        }
      }

      const list = this.detailList;
      for (let i = 0; i < list.length; i++) {
        const _item = list[i];
        if (detail.index === _item.index) {
          list.splice(i, 1);
          break;
        }
      }
      this.changedDetail();
    },
    async changeDetail(detail) {
      if (this.isNotBlank(detail.amountModel)) {
        detail.amountModel = core.utils.format.onlyNumber(detail.amountModel);
        detail.amount = Number(detail.amountModel);
      } else {
        detail.amountModel = "";
        detail.amount = 0;
      }

      detail.unitPriceModel = parseInt(String(detail.amount / detail.quantity));
      detail.unitPrice = Number(detail.unitPriceModel);

      if (detail.amount > 0) {
        detail.amount = detail.unitPrice * detail.quantity;
        detail.amountModel = core.utils.format.onlyNumber(detail.amount);
      }

      this.changedDetail();
    },
    changedDetail() {
      let totalPrice = 0;
      this.detailList.forEach((detail) => {
        totalPrice = totalPrice + detail.unitPrice * detail.quantity;
      });
      this.totalPrice = totalPrice;
      this.changeValue();
    },
    changeValue(changed?: boolean) {
      if (changed == null) changed = true;
      this.changeBackButton(changed);
    },
    async saveRemark(estimate: EstimateModel) {
      if (this.isNotBlank(estimate.remark)) {
        const storageRemarkItems = this.storageRemarkItems;
        const remark = estimate.remark.trim();
        let exists = false;
        storageRemarkItems.list.some((item: any) => {
          if (item.text === remark) {
            exists = true;
            item.time = new Date().getTime();
            return true;
          }
        });

        if (exists) {
          // 정렬
          storageRemarkItems.list.sort((a: any, b: any) => {
            if (a.time > b.time) return -1;
            else if (a.time < b.time) return 1;
            return 0;
          });
        } else {
          storageRemarkItems.list.unshift({
            time: new Date().getTime(),
            text: remark,
          });
          const maxLength = 30;
          if (storageRemarkItems.list.length > maxLength) {
            const length = storageRemarkItems.list.length;
            for (let i = maxLength; i < length; i++) {
              storageRemarkItems.list.splice(i, 1);
            }
          }
        }

        await this.$store.dispatch("storage/setStorageItem", {
          type: StorageType.ESTIMATE_REMARK,
          items: storageRemarkItems,
        });
      }
    },
    async submit() {
      if (await this.validate()) {
        const confirmResult = await core.alert.show({
          title: "확인",
          body: `${this.type === "add" ? "작성" : "수정"}된 견적서를 저장하시겠습니까?`,
          showCancelButton: true,
          cancelButtonText: "취소",
          confirmButtonText: "저장",
        });
        if (confirmResult !== "confirm") {
          return;
        }

        core.loader.show("저장중...");

        const type = this.type;
        const params = cloneDeep(this.form) as any;
        params.memo = "";
        params.customerName = "고객";
        params.detailList = [] as any;

        if (type === "edit") {
          const estimate = this.estimate;
          params.memo = estimate.memo;
          params.expectedDate = estimate.expectedDate;
          params.dong = estimate.dong;
          params.ho = estimate.ho;
        }

        this.detailList.forEach((detail) => {
          params.detailList.push({
            id: detail.id,
            description: detail.description,
            specification: detail.specification,
            quantity: detail.quantity,
            unitPrice: detail.unitPrice,
          });
        });

        // 단가표 저장 확인
        if (this.unitPriceTable.switch) {
          const unitPriceTableParams = {
            name: this.unitPriceTable.name,
            detailList: [] as any,
          };
          params.detailList.forEach((detail) => {
            unitPriceTableParams.detailList.push({
              description: detail.description,
              specification: detail.specification,
              quantity: detail.quantity,
              unitPrice: detail.unitPrice,
            });
          });
          try {
            const unitPriceTable = await UnitPriceTableService.create(unitPriceTableParams);
            // console.log("unitPriceTable : ", unitPriceTable);
          } catch (e) {
            console.log(e);
          }
        }

        // console.log("params : ", params);
        try {
          if (type === "add") {
            const estimate = (await EstimateService.create(params)) as EstimateModel;
            this.saveRemark(estimate);
            // console.log("estimate : ", estimate);
            if (this.hasPreVmPageStack()) {
              this.goBack(UpdateEventType.UPDATE, estimate);
            } else {
              //console.log("goback page : ", estimate.id);
              this.goBack(UpdateEventType.PAGE, { path: `/estimate/${estimate.id}` });
            }
          } else if (type === "edit") {
            const estimate = (await EstimateService.update(
              this.estimate.id,
              params
            )) as EstimateModel;
            this.saveRemark(estimate);
            // console.log("estimate : ", estimate);
            this.goBack(UpdateEventType.UPDATE, estimate);
          }
        } catch (e) {
          this.errorSubmit(e, this.formConvertField);
        }

        core.loader.hide();
      }
    },
  },
});
