import AbstractCoreService from "@/services/core/abstract-core.service";
import core from "@/core";

class ConstructionStatusService extends AbstractCoreService {
  constructor() {
    super("/api/v1/construction-status", "status");
  }

  createImage(formData: FormData) {
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`${this.url}/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data: any) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getImageFileList(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`${this.url}/${id}/image-file`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ConstructionStatusService();
