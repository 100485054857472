
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { cloneDeep } from "lodash";
import CalendarService from "@/services/calendar/calendar.service";
import { CalendarModel } from "@/models/calendar/calendar.model";

export default mixins(MixinsPageForm).extend({
  name: "CalendarAddEditComponent",
  components: {},
  props: {
    type: {
      type: String,
      default: "add",
    },
    query: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
    params: {
      type: Object,
      default: () => {
        return {
          type: "",
        };
      },
    },
    calendar: {
      type: Object as () => CalendarModel,
      default: () => {
        return {
          id: 0,
          name: "",
          no: 0,
          memo: "",
          type: "",
        };
      },
    },
  },
  data: () => ({
    ready: false,
    form: {
      type: "",
      name: "",
      memo: "",
    },
    calendarTypeList: [
      {
        id: "user",
        name: "개인",
      },
    ],
  }),
  mounted() {
    this.$nextTick(async () => {
      // console.log("params : ", this.params);
      if (this.$store.getters["auth/isManagerRole"]) {
        this.calendarTypeList.push({
          id: "company",
          name: "업체",
        });
      }

      if (this.type === "edit") {
        this.calendarTypeList.push({
          id: "company_expected_date",
          name: "시공예정일",
        });
      }

      if (this.params) {
        this.form.type = this.params.type;
        console.log("type : ", this.form.type);
      }
      this.changedCalendar();

      this.ready = true;
    });
  },
  computed: {},
  watch: {
    calendar() {
      this.changedCalendar();
    },
  },
  methods: {
    changedCalendar() {
      const calendar = this.calendar;
      if (this.type === "edit" && calendar && calendar.id > 0) {
        this.form.type = calendar.type;
        this.form.name = calendar.name;
        this.form.memo = calendar.memo;
      }
    },
    async submit() {
      if (await this.validate()) {
        try {
          //console.log("submit");
          const params = cloneDeep(this.form) as any;
          //console.log("params : ", params);
          if (this.type === "add") {
            const calendar = await CalendarService.create(params);
            //console.log("calendar : ", calendar);
            this.goBack();
          } else if (this.type === "edit") {
            if (this.calendar) {
              const calendarId = Number(this.calendar.id);
              const calendar = await CalendarService.update(calendarId, params);
              //console.log("calendar : ", calendar);
              this.goBack();
            } else {
              console.log("not found calendar");
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
});
