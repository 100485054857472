
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export interface SelectModalListItem {
  icon: string | null | undefined;
  id: string;
  text: string;
}

export interface SelectModalProps {
  visible: boolean;
  updateEvent: UpdateEvent | null;
  params: {
    list: SelectModalListItem[];
  };
}

export default mixins(MixinsModal).extend({
  name: "SelectModal",
  props: {
    properties: {
      type: Object as () => SelectModalProps,
      default: () => {
        return {
          visible: false,
          updateEvent: null as UpdateEvent | null,
          params: {
            list: [],
          },
        } as SelectModalProps;
      },
    },
  },
  methods: {
    select(item) {
      // console.log("item : ", item);
      this.close(UpdateEventType.CONFIRM, item);
    },
  },
});
