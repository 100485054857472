
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import { UserModel } from "@/models/user/user.model";
import { CompanyRole } from "@/types";
import CalendarEventOwnerShareComponent from "@/components/calendar/EventOwnerShareComponent.vue";
import CalendarEventNotOwnerShareComponent from "@/components/calendar/EventNotOwnerShareComponent.vue";
import CalendarEventShareComponent from "@/components/calendar/EventShareComponent.vue";
import ChangedCalendarEventShareComponent from "@/components/calendar/ChangedEventShareComponent.vue";
import { ShareCalendarEventModel } from "@/models/calendar/share-calendar-event.model";
import ShareCalendarEventService from "@/services/calendar/share-calendar-event.service";

export default mixins(MixinsPage).extend({
  name: "CalendarEventShare",
  components: {
    CalendarEventNotOwnerShareComponent,
    CalendarEventOwnerShareComponent,
    CalendarEventShareComponent,
    ChangedCalendarEventShareComponent,
  },
  data: () => ({
    tab: 0,
    eventId: null as any,
    query: {} as any,
    event: null as CalendarEventModel | null,
    items: {
      shareCalendarEvent: null as ShareCalendarEventModel | null,
      shareReceivedCalendarEvent: null as ShareCalendarEventModel | null,
    },
    ready: false,
  }),
  created() {
    const params = this.$route.params;
    this.eventId = Number(params.id);
  },
  mounted() {
    this.$nextTick(async () => {
      const user = (await this.$store.getters["auth/user"]()) as UserModel;
      //console.log("user : ", user);
      if (
        !(
          user.company?.role === CompanyRole.OWNER ||
          user.company?.role === CompanyRole.ADMIN ||
          user.company?.role === CompanyRole.MANAGER
        )
      ) {
        await this.notFound();
        return;
      }

      try {
        this.query = this.$route.query;
        const event = (this.event = (await CalendarEventService.get(
          this.eventId,
          {}
        )) as CalendarEventModel);

        if (!event.owner) {
          if (event.shareCalendarEventId != null) {
            this.items.shareCalendarEvent = (await ShareCalendarEventService.get(
              event.shareCalendarEventId
            )) as ShareCalendarEventModel;
            if (this.items.shareCalendarEvent.changeStatus === "ESTIMATE") {
              this.tab = 1;
            }
            //console.log("shareCalendarEvent : ", this.items.shareCalendarEvent);
          }

          if (event.shareReceivedCalendarEventId != null) {
            this.items.shareReceivedCalendarEvent = (await ShareCalendarEventService.get(
              event.shareReceivedCalendarEventId
            )) as ShareCalendarEventModel;
            //console.log("shareReceivedCalendarEvent : ", this.items.shareReceivedCalendarEvent);
          }
        }
      } catch (e) {
        await this.notFound();
        return;
      }
      this.ready = true;
    });
  },
  methods: {},
});
