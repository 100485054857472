
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimateAddEditComponent from "@/components/estimate/AddEditComponent.vue";
import EstimateService from "@/services/estimate/estimate.service";
import { EstimateModel } from "@/models/estimate/estimate.model";
import EstimateInfoEditComponent from "@/components/estimate/InfoEditComponent.vue";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPage).extend({
  name: "EstimateEdit",
  components: {
    EstimateInfoEditComponent,
    EstimateAddEditComponent,
  },
  data: () => ({
    query: {} as any,
    estimate: null as EstimateModel | null,
    place: null as any,
  }),
  created() {
    this.query = this.$route.query;
  },

  mounted() {
    this.$nextTick(async () => {
      const query = this.$route.query;
      //console.log("query : ", query);
      if (this.isBlank(query.id)) {
        await this.notFound();
        return;
      }
      // view
      if (query.view !== "info" && query.view !== "contract") {
        query.view = "estimate";
      }

      if (query.view === "info") {
        await this.$store.dispatch("topToolbar/changeTitle", {
          title: "견적서 기본정보 수정",
          ignoreCheck: true,
        });
      } else if (query.view === "contract") {
        await this.$store.dispatch("topToolbar/changeTitle", {
          title: "계약서 작성",
          ignoreCheck: true,
        });
      } else {
        await this.$store.dispatch("topToolbar/changeTitle", {
          title: "견적서 수정",
          ignoreCheck: true,
        });
      }

      const estimateId = Number(query.id);
      try {
        this.estimate = (await EstimateService.get(estimateId)) as EstimateModel;
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }
    });
  },

  activated() {
    const event = this.getPageUpdateEvent();
    if (event) {
      if (event.result === UpdateEventType.UPDATE) {
        if (event.path.indexOf("/search-address") > -1) {
          const item = event.item;
          this.place = item;
          //console.log("selected place item : ", item);
        } else {
          console.log("unknown path : ", event);
        }
      } else {
        console.log("unknown result : ", event);
      }
    }
  },
});
