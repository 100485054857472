
import store from "@/store";
import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import QuestionBoardService from "@/services/board/question-board.service";
import { UpdateEventType } from "@/types";
import { UserModel } from "@/models/user/user.model";
import { QuestionModel } from "@/models/board/question.model";

export default mixins(MixinsPageForm).extend({
  name: "QuestionBoardEdit",
  components: {},
  data: () => ({
    app: store.state.app,
    processing: false,
    questionBoard: null as QuestionModel | null,
    form: {
      title: "",
      content: "",
    },
    contentRows: 10,
    user: {} as UserModel,
    formConvertField: {
      title: "question_title",
      content: "question_content",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      this.user = (await store.getters["auth/user"]()) as UserModel;

      const id = Number(this.$route.query.id);
      console.log("id : ", id);
      if (isNaN(id)) {
        await this.notFound();
        return;
      }
      try {
        const questionBoard = (this.questionBoard = (await QuestionBoardService.get(
          id
        )) as QuestionModel);
        if (questionBoard.createdBy != this.user.id) {
          await core.alert.show({ title: "알림", body: "수정할 수 없는 게시물 입니다" });
          this.goBack(UpdateEventType.RELOAD);
        }
        this.form.title = questionBoard.title;
        if (questionBoard.content) {
          this.form.content = questionBoard.content;
        }
        //console.log("questionBoard : ", questionBoard);
      } catch (e) {
        this.goBack(UpdateEventType.RELOAD);
        return;
      }
      this.windowResize();
    });
  },
  watch: {
    "app.size"(size) {
      this.windowResize();
    },
  },
  methods: {
    windowResize() {
      const size = this.app.size;
      // console.log("size", size);
      if (this.app.isMobileSize) {
        // console.log("size.height : ", size.height);
        const rows = (size.height / 35).toFixed(0);
        // console.log("rows : ", rows);
        this.contentRows = Number(rows);
      } else {
        this.contentRows = Number(20);
      }
    },
    async submit() {
      core.loader.show();
      this.processing = true;
      if (await this.validate()) {
        try {
          const form = this.form;
          //console.log("submit : ", form);
          if (this.questionBoard) {
            const questionBoard = await QuestionBoardService.update(this.questionBoard.id, form);
            //console.log("questionBoard : ", questionBoard);
          }
          this.goBack(UpdateEventType.RELOAD);
        } catch (e) {
          console.log(e);
          this.errorSubmit(e, this.formConvertField);
        }
      }
      core.loader.hide();
      this.processing = false;
    },
  },
});
