
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CalendarEventViewComponent from "@/components/calendar/EventViewComponent.vue";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import { CalendarEventModel, CalendarEventType } from "@/models/calendar/calendar-event.model";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import core from "@/core";
import ShareCalendarEventService from "@/services/calendar/share-calendar-event.service";
import { ShareCalendarEventModel } from "@/models/calendar/share-calendar-event.model";
import ConstructionStatusService from "@/services/construction-status/construction-status.service";
import { ConstructionStatusModel } from "@/models/construction-status/construction-status.model";
import { TYPE } from "vue-toastification/src/ts/constants";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "CalendarEventView",
  components: { CalendarEventViewComponent },
  data: () => ({
    id: null as any,
    event: null as CalendarEventModel | null,
    requestDate: null as any,
    shareCalendarEvent: null as ShareCalendarEventModel | null,
    statusList: [] as ConstructionStatusModel[],
    query: null as any,
    // iconSubMenu: {
    //   alarm: null as Menu | null,
    // },
    // textSubMenu: {
    //   share: null as Menu | null,
    //   edit: null as Menu | null,
    //   delete: null as Menu | null,
    //   estimate: null as Menu | null,
    //   doneCancel: null as Menu | null,
    // },
    view: {
      diffDays: 0,
    },
    existsUser: false,
    ready: false,
  }),
  mounted() {
    //console.log("mounted");
    this.$nextTick(async () => {
      this.query = this.$route.query;
      const params = this.$route.params;
      if (params.id != null) {
        this.id = Number(params.id);
        //console.log("id : ", this.id);
        try {
          await this.loadCalendarEvent();
          if (this.event?.type === CalendarEventType.DEFAULT) {
            await this.loadConstructionStatus();
          }

          // const existsUser = (this.existsUser = Boolean(
          //   await CalendarEventService.existsEventUser(this.id)
          // ));
          // {
          //   const menu = this.iconSubMenu.alarm as Menu;
          //   menu.visible = true;
          // }
          // this.changedExistsUser(existsUser);
        } catch (e) {
          console.log("error ");
          console.log(e);
          await this.notFound();
          return;
        }
        // this.ready = true;
      } else {
        await this.notFound();
      }
    });
  },
  async activated() {
    //console.log("activated");
    this.ready = false;
    const event = this.getPageUpdateEvent();
    //console.log("event : ", event);
    if (event != null) {
      if (event.result === UpdateEventType.UPDATE) {
        if (!(event.path.indexOf("/estimate") > -1)) {
          this.event = event.item;
        } else {
          console.log("unknown event : ", event);
        }
        this.updatePreVmEvent(event.result, event.item);
      } else if (event.result === UpdateEventType.DELETE) {
        this.goBack(UpdateEventType.DELETE, this.event);
        return;
      } else if (event.result === UpdateEventType.RELOAD) {
        try {
          //console.log("reload event : ", event);
          if (event.path.indexOf("construction-status") > -1) {
            await this.loadCalendarEvent();
            await this.loadConstructionStatus();
            this.updatePreVmEvent(UpdateEventType.RELOAD);
          } else {
            await this.loadCalendarEvent();
            this.updatePreVmEvent(UpdateEventType.RELOAD);
          }
        } catch (e) {
          console.log(e);
          await this.notFound();
          return;
        }
      } else {
        console.log("unknown event : ", event);
      }
    } else {
      // 다시 가져오기
      await this.loadCalendarEvent();
    }
    // this.changedEvent();
    this.ready = true;
  },
  deactivated() {
    this.ready = false;
  },
  computed: {
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
  },
  watch: {
    existsUser(existsUser) {
      this.changedExistsUser(existsUser);
    },
    event(event, preEvent) {
      if (event != null) {
        //console.log("changed event : ", event);
        this.requestDate = new Date();
        this.changedEvent();
        if (preEvent != null && preEvent.complete != event.complete) {
          // console.log("changed estimate");
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        }
      }
    },
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "share") {
        this.showSharePage();
      } else if (menu.id === "edit") {
        if (this.event != null) {
          this.$router.push({
            path: "/calendar/event/edit",
            query: { id: String(this.event.id), calendarId: String(this.event.calendarId) },
          });
        }
      } else if (menu.id === "delete") {
        this.deleteItem();
      } else if (menu.id === "unshare") {
        this.deleteItem();
      } else if (menu.id === "estimate") {
        if (this.event != null) {
          this.$router.push(`/estimate/${this.event.estimateId}`);
        }
      } else if (menu.id === "doneCancel") {
        if (this.event) {
          this.updateComplete(!this.event.complete);
        }
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    changedExistsUser(existsUser) {
      // const menu = this.iconSubMenu.alarm as Menu;
      // if (existsUser) {
      //   menu.icon = "mdi-bell";
      // } else {
      //   menu.icon = "mdi-bell-outline";
      // }
      // // console.log("existsUser : ", existsUser);
    },
    async updateComplete(complete: boolean) {
      try {
        if (complete) {
          let exists = false;
          if (this.statusList) {
            this.statusList.some((status) => {
              if (status.type === "A") {
                exists = true;
                return true;
              }
            });
          }
          if (!exists) {
            const result = await core.alert.show({
              title: "확인",
              body: "시공 사진 없이 완료 처리 하시겠습니까?",
              showCancelButton: true,
              confirmButtonText: "완료",
              cancelButtonText: "취소",
            });
            if (result !== "confirm") {
              return;
            }
          }
        } else if (this.event && this.event.complete) {
          const result = await core.alert.show({
            title: "확인",
            body: "시공이 <span class='green--text'>완료</span>된 상태입니다.<br>시공 완료를 <span class='red--text'>취소</span>하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "예",
            cancelButtonText: "아니오",
          });
          if (result !== "confirm") {
            return;
          }
        }

        core.loader.show();
        if (this.event) {
          const event = (await CalendarEventService.updateComplete(
            this.event.id,
            complete
          )) as CalendarEventModel;
          // console.log("2222222222 event : ", event);
          this.event = event;
          // this.event.complete = event.complete;
          // core.utils.copy(schedule, this.schedule);
          // // 이전 페이지 이벤트 전송
          // this.updatePreVmEvent(UpdateEventType.RELOAD);
        }
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async loadConstructionStatus() {
      if (this.event != null) {
        this.statusList = (await ConstructionStatusService.getList(undefined, {
          calendarOrgEventId: this.event.orgEventId,
        })) as ConstructionStatusModel[];
      } else {
        console.log("event is null!!");
      }
    },
    async loadCalendarEvent() {
      if (this.id == null) return;
      if (this.requestDate != null) {
        const curDate = new Date();
        const elapsedTimeMillis = curDate.getTime() - this.requestDate.getTime();
        if (elapsedTimeMillis < 5000) {
          return;
        }
      }
      const event = (this.event = (await CalendarEventService.get(
        this.id,
        {}
      )) as CalendarEventModel);
      // console.log("event : ", event);
    },
    async changedEvent() {
      const event = this.event;
      if (event != null) {
        const startDateMoment = core.date.instance(
          core.date.instance(event.startAt).format("YYYY-MM-DD") + " 00:00:00"
        );
        const endDateMoment = core.date.instance(
          core.date.instance().format("YYYY-MM-DD") + " 00:00:00"
        );
        this.view.diffDays = startDateMoment.diff(endDateMoment, "days");

        let title = event.title;
        if (!event.owner) {
          title = "[공유] " + event.title;
        } else if (event.shareCalendarEventId != null) {
          title = "[공유중] " + event.title;
        }

        if (event.type === CalendarEventType.EXPECTED_DATE) {
          title = "[시공예정] " + event.title;
        }

        this.$store.dispatch("topToolbar/changeTitle", { title: title, ignoreCheck: true });
        this.appBarChangeMenuVisible(
          "edit",
          event.owner && event.type === CalendarEventType.DEFAULT
        );
        this.appBarChangeMenuVisible(
          "delete",
          event.owner && event.type === CalendarEventType.DEFAULT
        );
        this.appBarChangeMenuVisible(
          "unshare",
          !event.owner && event.type === CalendarEventType.DEFAULT
        );
        this.appBarChangeMenuVisible("estimate", event.owner && event.estimateId != null);
        this.appBarChangeMenuVisible(
          "share",
          event.estimateId != null &&
            event.type === CalendarEventType.DEFAULT &&
            this.isManagerRoleHigher
        );

        this.appBarChangeMenuVisible("doneCancel", event.estimateId != null && event.complete);

        try {
          if (this.isManagerRoleHigher) {
            if (event.shareReceivedCalendarEventId) {
              // 공유 받은 업체
              let shareReceivedCalendarEvent: ShareCalendarEventModel | null = null;
              shareReceivedCalendarEvent =
                event.shareReceivedCalendarEvent as ShareCalendarEventModel | null;
              if (shareReceivedCalendarEvent == null) {
                shareReceivedCalendarEvent = (await ShareCalendarEventService.get(
                  event.shareReceivedCalendarEventId
                )) as ShareCalendarEventModel;
              }
              // console.log("shareReceivedCalendarEvent : ", shareReceivedCalendarEvent);
              if (shareReceivedCalendarEvent.status === "UPDATED") {
                await core.alert.show({
                  title: "확인",
                  body: "공유 업체에서 시공범위를 수정했습니다.<br>내용을 확인하세요.",
                });
                await this.$router.push({
                  path: `/calendar/event/${event.id}/share`,
                });
                return;
              } else {
                if (shareReceivedCalendarEvent.status !== "ALLOW") {
                  console.log("unknown status : ", shareReceivedCalendarEvent.status);
                }
              }
            }

            if (event.shareCalendarEventId) {
              let shareCalendarEvent: ShareCalendarEventModel | null = null;
              shareCalendarEvent = event.shareCalendarEvent as ShareCalendarEventModel | null;
              if (shareCalendarEvent == null) {
                shareCalendarEvent = (await ShareCalendarEventService.get(
                  event.shareCalendarEventId
                )) as ShareCalendarEventModel;
              }

              this.shareCalendarEvent = shareCalendarEvent;
              if (shareCalendarEvent.changeStatus === "ESTIMATE") {
                await core.alert.show({
                  title: "확인",
                  body: "시공 범위가 변경되어 공유 일정을 수정해야합니다.<br>내용을 확인하세요.",
                });
                await this.$router.push({
                  path: `/calendar/event/${event.id}/share`,
                });
                return;
              } else if (shareCalendarEvent.status === "REQUEST") {
                core.toast.show({
                  type: TYPE.SUCCESS,
                  content: "일정 공유가 진행중 입니다.",
                });
              } else if (shareCalendarEvent.status === "DENY") {
                let text = "";
                if (shareCalendarEvent.shareCompany) {
                  text += `${shareCalendarEvent.shareCompany.name} 업체에서 `;
                }
                text += "일정 공유를 거절했습니다.";
                core.toast.show({
                  type: TYPE.ERROR,
                  content: text,
                });
                try {
                  const result = await ShareCalendarEventService.delete(shareCalendarEvent.id);
                  // console.log("result : ", result);
                } catch (e) {
                  console.log(e);
                }
              } else if (shareCalendarEvent.status === "DELETE_CONFIRM") {
                const shareCompany = shareCalendarEvent.shareCompany;
                let text = "";
                if (shareCompany != null) {
                  text = `"${shareCompany.name}" 업체로 공유된 일정이 삭제되었습니다.`;
                } else {
                  text = `공유된 일정이 삭제되었습니다.`;
                }
                core.toast.show({
                  type: TYPE.ERROR,
                  content: text,
                });
                try {
                  const result = await ShareCalendarEventService.delete(shareCalendarEvent.id);
                  // console.log("result : ", result);
                } catch (e) {
                  console.log(e);
                }
              } else {
                if (shareCalendarEvent.status !== "ALLOW") {
                  console.log("unknown status : ", shareCalendarEvent.status);
                }
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
      //console.log("event : ", event);
    },
    async showSharePage() {
      const event = this.event;
      if (event != null) {
        // 일정 공유하기 페이지 이동
        this.$router.push(`/calendar/event/${event.id}/share`);
      }
    },
    async deleteItem() {
      try {
        const event = this.event;
        if (event != null) {
          let text = "일정을 삭제하시겠습니까?";
          if (!event.owner) {
            text = "공유 받은 일정을 삭제하시겠습니까?";
          }
          const confirmResult = await core.alert.show({
            title: "확인",
            body: text,
            showCancelButton: true,
          });
          if (confirmResult === "confirm") {
            const result = await CalendarEventService.delete(event.id);
            //console.log("result : ", result);
            this.goBack(UpdateEventType.DELETE, this.event);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
});
