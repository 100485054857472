import core from "@/core";
import { TableRequest } from "@/types";

class MyCompanyService {
  protected readonly url = "/api/v1/company/me";
  protected readonly itemKey = "company";

  create(params: any) {
    return new Promise((resolve, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`${this.url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(params: any) {
    return new Promise((resolve, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`${this.url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  delete() {
    return new Promise((resolve, reject) => {
      core.http
        .delete(`${this.url}`)
        .then((data) => {
          if (typeof data === "object") {
            resolve(data);
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  register(params) {
    return new Promise((resolve, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`${this.url}/register`, strParams)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data.company);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  unregister() {
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`${this.url}/unregister`, "")
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  get() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${this.url}`)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getDetails() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${this.url}/details`, null)
        .then((data) => {
          resolve(data.details);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getUserTable(params: TableRequest) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${this.url}/user/table`, null, params)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getUser(id: number) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${this.url}/user/${id}`, null)
        .then((data) => {
          const item = data.user;
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateUserApproval(userId: number, approvalType: string) {
    const strParam = JSON.stringify({ approvalType: approvalType });
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`${this.url}/user/${userId}/approval`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          const item = data.user;
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateUserCompanyRole(userId: number, role: string) {
    const strParam = JSON.stringify({ role: role });
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`${this.url}/user/${userId}/role`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          const item = data.user;
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getLinkList() {
    const url = this.url + "/link";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            const item = data["linkCompanyList"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  createInvitation() {
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`${this.url}/invitation`, "")
        .then((data: any) => {
          //console.log('result: ', data);
          const item = data.code;
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new MyCompanyService();
