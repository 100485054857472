import { IdDeleteModel, IdModel } from "@/models/core/type.model";
import { CompanyModel } from "@/models/company/company.model";
import { ShareCalendarEventModel } from "@/models/calendar/share-calendar-event.model";

export enum CalendarEventType {
  DEFAULT = "D",
  EXPECTED_DATE = "E",
}

export enum CalendarEventDeletedType {
  USER = "U", // 사용자 직접 삭제
  SHARE_EVENT = "SE", // 공유 일정 삭제
}

export interface CalendarEventModel extends IdDeleteModel {
  name: string;
  calendarId: number;
  estimateId: number | null;
  orgEventId: number;
  allDay: boolean;
  startAt: string;
  endAt: string;
  title: string;
  memo: string;
  color: string;
  price: number;
  topFixed: boolean;
  estimateDetailList: EstimateDetail[] | null;
  estimate: CalendarEventEstimateModel | null;
  owner: boolean;
  ownerCompany: CompanyModel | null;
  shareCompany: CompanyModel | null;
  shareCalendarEventId: number | null;
  shareCalendarEvent: ShareCalendarEventModel | null;
  shareReceivedCalendarEventId: number | null;
  shareReceivedCalendarEvent: ShareCalendarEventModel | null;
  complete: boolean;
  type: CalendarEventType;
  deletedType: CalendarEventDeletedType | null;
}

export interface CalendarEventEstimateModel extends IdModel {
  address: string;
  customerPhone: string;
  dong: string;
  ho: string;
  type: string;
  issue: boolean;
  caution: boolean;
}

export interface EstimateDetail {
  id: number;
  description: string;
  specification: string;
  quantity: number;
}
