import AbstractTableService from "@/services/core/abstract-table.service";
import core from "@/core";
import { TableRequest } from "@/types";

class ShareCalendarEventService extends AbstractTableService {
  constructor() {
    super("/api/v1/calendar/event/share", "shareCalendarEvent");
  }

  updateApproval(params: any) {
    const url = this.url + "/approval";
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`${url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            resolve(data);
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateDeny(params: any) {
    const url = this.url + "/deny";
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`${url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            resolve(data);
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getRequestTable(params: TableRequest, replaceParams?: [string]) {
    const url = this.getUrl(replaceParams);
    //console.log("this.url : ", this.url);
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}/request-table`, null, params)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new ShareCalendarEventService();
