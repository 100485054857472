
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import CompanyService from "@/services/company/my-company.service";
import core from "@/core";
import { UserCompanyModel } from "@/models/company/user-company.model";
import { CompanyDetailType } from "@/types";
import { BankAccountModel } from "@/models/company/bank-account.model";
import { EstimateContentModel } from "@/models/company/estimate-content.model";
import { cloneDeep } from "lodash";

export default mixins(MixinsPageForm).extend({
  name: "CompanyAddEditComponent",
  props: {
    type: {
      type: String,
      default: "add",
    },
    company: {
      type: Object,
      default: () => {
        return null as UserCompanyModel | null;
      },
    },
  },
  data: () => ({
    processing: false,
    form: {
      name: "",
      ceoName: "",
      phone: "",
      bankAccountList: [] as BankAccountModel[],
      estimateContentList: [] as EstimateContentModel[],
    },
    estimateNodeId: null as any,
    formConvertField: {
      name: "company_name",
      ceoName: "company_ceoName",
      phone: "company_phone",
    },
    disabled: {
      name: false,
    },
  }),
  mounted() {
    this.$nextTick(() => {
      if (this.type === "edit") {
        if (this.company != null) {
          this.init(this.company);
        }
        this.disabled.name = this.isAdminRole;
      }
    });
  },
  computed: {
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
  },
  watch: {
    company(company) {
      if (this.type === "edit" && company != null) {
        this.init(company);
      }
    },
    "form.phone"() {
      this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
    },
  },
  methods: {
    init(company: UserCompanyModel) {
      //console.log("init company : ", company);
      const form = this.form;
      form.name = company.name;
      form.ceoName = company.ceoName;
      form.phone = company.phone;

      if (company.details != null) {
        const bankAccount = company.details[CompanyDetailType.BANK_ACCOUNT];
        if (bankAccount) {
          bankAccount.data.list.forEach((item: BankAccountModel) => {
            form.bankAccountList.push(item);
          });
        }

        const estimateContent = company.details[CompanyDetailType.ESTIMATE_CONTENT];
        if (estimateContent) {
          estimateContent.data.list.forEach((item: EstimateContentModel) => {
            form.estimateContentList.push(item);
          });
        }
      }
    },
    changeDefaultBankAccount(item) {
      // 기본계좌 변경
      const list = this.form.bankAccountList;
      list.some((bankAccount) => {
        if (bankAccount.default) {
          bankAccount.default = false;
          return true;
        }
      });
      item.default = true;
    },
    addBankAccount() {
      // 계좌 추가
      const list = this.form.bankAccountList;
      const isDefault = list.length === 0;
      list.push({
        default: isDefault,
        bankName: "",
        bankAccount: "",
        accountHolder: "",
      });
    },
    removeBankAccount(i: number) {
      // 계좌 삭제
      const list = this.form.bankAccountList;
      const item = list[i];
      list.splice(i, 1);
      if (item.default) {
        if (list.length > 0) {
          list[0].default = true;
        }
      }
    },
    addEstimateContent() {
      const list = this.form.estimateContentList;
      list.push({
        title: "",
        content: "",
      });
    },
    removeEstimateContent(i: number) {
      const list = this.form.estimateContentList;
      list.splice(i, 1);
    },
    async submit() {
      const type = this.type;
      if (type === "add" || type === "edit") {
        if (await this.validate()) {
          this.processing = true;

          core.loader.show();
          try {
            const params = cloneDeep(this.form) as any;

            // 상세 정보 추가
            params.details = {} as any;

            const bankAccountList = params.bankAccountList;
            delete params.bankAccountList;

            if (bankAccountList != null) {
              params.details["BANK_ACCOUNT"] = { list: bankAccountList };
            }

            const estimateContentList = params.estimateContentList;
            delete params.estimateContentList;

            if (estimateContentList != null) {
              params.details["ESTIMATE_CONTENT"] = {
                list: estimateContentList,
              };
            }

            const userCompany =
              type === "add"
                ? await CompanyService.create(params)
                : await CompanyService.update(params);

            //console.log("update user company : ", userCompany);
            // const user = await this.$store.getters["auth/user"](true);

            core.loader.hide();
            let msg = "신규 업체 정보가 생성되었습니다.";
            if (type === "edit") msg = "업체 정보가 수정되었습니다.";
            await core.alert.show({
              title: "알림",
              body: msg,
            });
            const query = this.$route.query;
            if (this.isNotBlank(query.shareCalendarEventUuid)) {
              await this.$router.push({
                path: "/sce/" + query.shareCalendarEventUuid,
                query: { s: "true" },
              });
            } else {
              await this.$router.back();
            }
          } catch (e) {
            this.processing = false;
            core.loader.hide();
            this.errorSubmit(e, this.formConvertField);
          }
        }
      } else {
        console.log("unknown type : ", type);
      }
    },
  },
});
