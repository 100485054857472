import qs from "qs";
import core from "@/core";
import { UserModel } from "@/models/user/user.model";
import store from "@/store";
import { CompanyRole } from "@/types";

class UserService {
  register(params) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/register`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(params) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/v1/user/me`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  login(params) {
    const strParam = qs.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/v1/login`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  socialRegister(params) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/social/register`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  socialLogin(registrationId: string, addProvider: boolean | null, params) {
    if (addProvider) {
      params.addProvider = addProvider;
    }
    const strParam = qs.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/v1/social/${registrationId}`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/logout`)
        .then((data) => {
          // console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          // console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getMyInfo(): Promise<UserModel> {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/user/me`, { ignoreAlertModal: true })
        .then((data: any) => {
          //console.log('result: ', data);
          if (data.user != null) {
            resolve(data.user as UserModel);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateMobileDeviceInfo(params: {
    crudType: string;
    deviceToken: string;
    timezone: string | null;
    locale: string | null;
    manufacturer: string | null;
    model: string | null;
    product: string | null;
    hardware: string | null;
    os: string | null;
    versionSdk: number | null;
    versionRelease: string | null;
    packageName: string | null;
    versionCode: number | null;
    versionName: string | null;
  }) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/me/mobile-device`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getSocialToken(token: string) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/social/token`, null, { value: token })
        .then((data) => {
          //console.log('result: ', data);
          if (data.social != null) {
            resolve(data.social);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createPhoneVerify(phone: string) {
    const strParam = JSON.stringify({
      phone: phone,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/phone-verify`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data.token);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  checkPhoneVerify(token: string, phone: string, certificationNumber: string) {
    const strParam = JSON.stringify({
      token: token,
      phone: phone,
      certificationNumber: certificationNumber,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/phone-verify/check`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updatePassword(currentPassword: string | null, newPassword: string, newConfirmPassword: string) {
    const strParam = JSON.stringify({
      currentPassword: currentPassword,
      newPassword: newPassword,
      newConfirmPassword: newConfirmPassword,
    });
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`/api/v1/user/me/password`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createProfileImage(formData: FormData) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/v1/user/me/profile-image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  withdraw() {
    return new Promise((resolve, reject) => {
      core.http
        .delete(`/api/v1/user/me/withdraw`)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  // async hasCompanyRole(...companyRoles: CompanyRole[]) {
  //   const user = (await store.getters["auth/user"]()) as UserModel | null;
  //   if (user && user.company) {
  //     //console.log("company role : ", user.company.role);
  //     return companyRoles.indexOf(user.company.role) > -1;
  //     // return user.company.role === companyRole;
  //   }
  //   return false;
  // }

  createEmailVerify(email: string) {
    const strParam = JSON.stringify({
      email: email,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/email-verify`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data.token);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  checkEmailVerify(token: string, email: string, certificationNumber: string) {
    const strParam = JSON.stringify({
      token: token,
      email: email,
      certificationNumber: certificationNumber,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/email-verify/check`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getFindUsername(token: string, phone?: string, certificationNumber?: string) {
    return new Promise((resolve, reject) => {
      const params = {
        token: token,
      } as any;
      if (phone != null && certificationNumber != null) {
        params.phone = phone;
        params.certificationNumber = certificationNumber;
      }
      core.http
        .getJson(`/api/v1/user/find-username`, null, params)
        .then((data) => {
          //console.log('result: ', data);
          if (data.user) {
            resolve(data.user);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  sendEmailFindUsername(email: string) {
    const strParam = JSON.stringify({
      email: email,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/find-username`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getFindPassword(token: string, phone?: string, certificationNumber?: string) {
    return new Promise((resolve, reject) => {
      const params = {
        token: token,
      } as any;
      if (phone != null && certificationNumber != null) {
        params.phone = phone;
        params.certificationNumber = certificationNumber;
      }
      core.http
        .getJson(`/api/v1/user/find-password`, null, params)
        .then((data) => {
          //console.log('result: ', data);
          if (data.user) {
            resolve(data.user);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  sendEmailFindPassword(username: string, email: string) {
    const strParam = JSON.stringify({
      username: username,
      email: email,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/find-password`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new UserService();
