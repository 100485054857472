
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import Vue from "vue";
import { cloneDeep } from "lodash";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import AllCalendarEventService from "@/services/calendar/all-calendar-event.service";
import { CalendarEventModel, CalendarEventType } from "@/models/calendar/calendar-event.model";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import { CalendarModel } from "@/models/calendar/calendar.model";
import ShareCalendarEventService from "@/services/calendar/share-calendar-event.service";
import UpdateEvent from "@/models";
import CalendarSelectModal from "@/modals/calendar/SelectModal.vue";
import CalendarEventListItemComponent from "@/components/calendar/EventListItemComponent.vue";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "Calendar",
  components: { CalendarEventListItemComponent, CalendarSelectModal },
  data: () => ({
    title: "",
    calendar: {
      model: core.date.instance().format("YYYY-MM-DD"),
      title: "",
      height: 0,
      items: [] as any,
      totalPrice: 0,
      dateMap: {} as any,
    },
    searchColumns: {} as any,

    visible: {
      addButton: false,
      totalPrice: false,
    },
    reloadFlag: false,
    ready: false,
    updatedType: "",
    shareCalendarEventList: [] as any,
    shareCalendarEventId: null as any,
    modal: {
      calendarSelect: {
        visible: false,
        type: "company",
        updateEvent: null as UpdateEvent | null,
      },
    },
    allCalendarEvent: {
      hasNextItem: true,
      request: {
        draw: 0,
        start: 0,
        length: 10,
        orderColumnName: "updatedAt",
        order: "desc",
        searchColumns: {},
      } as any,
      response: {
        draw: 0,
        recordsFiltered: 0,
        recordsTotal: 0,
        data: [] as any,
      },
    },
  }),
  mounted() {
    console.log("mounted");

    const params = this.$route.params as any;
    if (params.id == null) {
      const query = this.$route.query as any;
      let strParams = "";
      {
        const newQuery = cloneDeep(query);
        delete newQuery.stackKey;
        if (Object.keys(newQuery).length > 0) {
          strParams = "?" + core.http.objToUrlParams(newQuery);
        }
      }

      if (this.app.selectedCalendarId) {
        this.$router.replace("/calendar/" + this.app.selectedCalendarId + strParams);
        return;
      } else {
        this.$router.replace("/calendar/all" + strParams);
        return;
      }
    }
    const query = this.$route.query as any;
    if (query.shareCalendarEventId && !isNaN(Number(query.shareCalendarEventId))) {
      this.shareCalendarEventId = Number(query.shareCalendarEventId);
      this.replaceState();
    }

    this.$nextTick(async () => {
      this.resize();

      if (params.id === "all") {
        //console.log("all calendar ");
        this.title = "전체 캘린더";

        this.visible.totalPrice = this.$store.getters["auth/isAdminRoleHigher"];
        this.app.selectedCalendarId = params.id;
        this.visible.addButton = true;
      } else {
        const id = Number(params.id);
        //console.log("id : ", id);
        this.searchColumns.calendarId = id;
        const calendarList = (await this.$store.getters[
          "app/getCalendarList"
        ]()) as CalendarModel[];
        let calendar = null as CalendarModel | null;
        calendarList.some((_calendar) => {
          if (_calendar.id === id) {
            calendar = _calendar;
            return true;
          }
        });
        if (calendar != null) {
          this.title = calendar.name;
          this.app.selectedCalendarId = params.id;
        } else {
          await core.alert.show({
            title: "알림",
            body: "존재하지 않는 캘린더 입니다",
          });
          this.$router.replace("/calendar/all");
          return;
        }

        if (
          (calendar.type === "company" || calendar.type === "company_expected_date") &&
          this.$store.getters["auth/isAdminRoleHigher"]
        ) {
          this.visible.totalPrice = true;
        }

        this.visible.addButton = !(calendar.type === "company_expected_date");
      }

      this.ready = true;
      this.reloadFlag = true;

      await this.getAllCalendarEventList();

      await this.getShareEventList();
    });
  },
  watch: {
    "modal.calendarSelect.updateEvent"() {
      const modal = this.modal.calendarSelect;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          this.submitAllShareCalendarEventApproval(item.id);
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
    "app.size"() {
      this.resize();
    },
    reloadFlag(reloadFlag) {
      this.reloadFlag = false;
      if (reloadFlag && this.ready) {
        this.drawCalendarEvent(this.searchColumns);
      }
    },
    title(title) {
      this.$store.dispatch("topToolbar/changeTitle", {
        title: this.title,
        ignoreCheck: true,
      });
    },
  },
  updated() {
    const updatedType = this.updatedType;
    this.updatedType = "";
    if (updatedType === "schedule") {
      this.resize();
    }
  },
  async activated() {
    if (this.ready) {
      this.$store.dispatch("topToolbar/changeTitle", {
        title: this.title,
        ignoreCheck: true,
      });

      const event = this.getPageUpdateEvent();
      if (event) {
        if (
          event.result === UpdateEventType.RELOAD ||
          event.result === UpdateEventType.UPDATE ||
          event.result === UpdateEventType.DELETE
        ) {
          //console.log("event : ", event);
          this.reloadFlag = true;
        } else {
          console.log("unknown event result : ", event);
        }
      }

      this.clearAllCalendarEvent();

      await this.getAllCalendarEventList();

      await this.getShareEventList();
    }
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "search") {
        this.$router.push({ path: "/calendar/search" });
      } else if (menu.id === "filter") {
        this.$router.push({ path: "/calendar/filter" });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    showCalendarSelectModal() {
      // 캘린더 선택 모달
      const modal = this.modal.calendarSelect;
      modal.type = "company";
      modal.visible = true;
    },
    async allShareCalendarEventApproval() {
      // 전체 공유 일정 승인 처리
      const confirmResult = await core.alert.show({
        title: "확인",
        body: "공유된 전체 일정을 공유받으시겠습니까?",
        showCancelButton: true,
      });
      if (confirmResult === "confirm") {
        this.showCalendarSelectModal();
      }
    },
    async submitAllShareCalendarEventApproval(calendarId) {
      console.log("전체 공유 일정 승인 처리");
      const successList = [] as any;
      const list = this.shareCalendarEventList;
      for (let i = 0; i < list.length; i++) {
        try {
          const shareCalendarEvent = list[i];
          const params = {
            uuid: shareCalendarEvent.uuid,
            calendarId: calendarId,
          };
          const response = await ShareCalendarEventService.updateApproval(params);
          successList.push(shareCalendarEvent);
        } catch (e) {
          console.log(e);
        }
      }
      this.reloadFlag = true;
      this.getShareEventList();
    },
    showAddPage() {
      const query = {} as any;
      if (this.app.selectedCalendarId !== "all") {
        query.calendarId = this.app.selectedCalendarId;
      }

      this.$router.push({
        path: "/calendar/event/add",
        query: query,
      });
    },
    clearAllCalendarEvent() {
      const allCalendarEvent = this.allCalendarEvent;
      allCalendarEvent.hasNextItem = true;

      const request = allCalendarEvent.request;
      const response = allCalendarEvent.response;
      request.draw = 0;
      request.start = 0;
      response.recordsFiltered = 0;
      response.recordsTotal = 0;
      response.data = [] as any;
    },
    getMoreAllCalendarEventList() {
      const allCalendarEvent = this.allCalendarEvent;
      const response = allCalendarEvent.response;
      allCalendarEvent.request.start = response.data.length;
      this.getAllCalendarEventList();
    },
    async getAllCalendarEventList() {
      const searchColumns = {} as any;
      // console.log("selectedCalendarId : ", this.app.selectedCalendarId);
      if (this.app.selectedCalendarId !== "all") {
        searchColumns.calendarId = this.app.selectedCalendarId;
      }
      const allCalendarEvent = this.allCalendarEvent;
      const request = allCalendarEvent.request;
      const start = request.start;
      try {
        if (start > 0) {
          core.loader.show();
        }

        request.searchColumns = searchColumns;
        // const params = {
        //   draw: 0,
        //   start: 0,
        //   length: 10,
        //   orderColumnName: "updatedAt",
        //   order: "desc",
        //   searchColumns: searchColumns,
        // } as any;
        const response = allCalendarEvent.response;
        const tableResponse = (await AllCalendarEventService.getTable(request)) as any;
        // console.log("response : ", response);
        response.draw = tableResponse.draw;
        response.recordsFiltered = tableResponse.recordsFiltered;
        response.recordsTotal = tableResponse.recordsTotal;
        tableResponse.data.forEach((data) => {
          response.data.push(data);
        });
        allCalendarEvent.hasNextItem = request.start + request.length < response.recordsTotal;

        if (start > 0) {
          core.loader.hide();
        }
      } catch (e) {
        console.log(e);
        if (start > 0) {
          core.loader.hide();
        }
      }
    },
    async getShareEventList() {
      if (this.$store.getters["auth/isAdminRoleHigher"]) {
        const params = {
          draw: 0,
          start: 0,
          length: 200,
          orderColumnName: "createdAt",
          order: "desc",
          searchColumns: {},
        } as any;
        const response = (await ShareCalendarEventService.getRequestTable(params)) as any;
        this.shareCalendarEventList = response.data;
        if (this.shareCalendarEventList.length > 0 && this.shareCalendarEventId) {
          this.shareCalendarEventList.some((shareCalendarEvent) => {
            if (shareCalendarEvent.id === this.shareCalendarEventId) {
              // console.log("shareCalendarEvent : ", shareCalendarEvent);
              setTimeout(() => {
                const el = document.getElementById("shareCalendarEvent_" + shareCalendarEvent.id);
                if (el != null) {
                  // console.log("el : ", el);
                  el.scrollIntoView();
                  window.scrollBy(0, -70);
                }
              }, 200);
              return true;
            }
          });
        }
        // console.log("response : ", response);
      }
    },
    async drawCalendarEvent(searchColumns) {
      core.loader.show();
      try {
        const items = (this.calendar.items = [] as any);
        let totalPrice = 0;
        const params = cloneDeep(searchColumns);
        //console.log("params : ", params);
        const eventList = [] as CalendarEventModel[];
        {
          const orgEventList = (await CalendarEventService.getList(params)) as CalendarEventModel[];
          const dateMap = {} as any;
          orgEventList.forEach((event) => {
            const startDateMoment = core.date.instance(event.startAt);
            const strDate = startDateMoment.format("YYYY-MM-DD");
            if (dateMap[strDate] == null) {
              dateMap[strDate] = [];
            }
            const list = dateMap[strDate];
            list.push(event);

            totalPrice += event.price;
          });
          this.calendar.dateMap = dateMap;

          const keyList = Object.keys(dateMap);
          keyList.forEach((strDate) => {
            const list = dateMap[strDate];
            let count = 0;
            const maxCount = this.getMaxDaySize();
            list.some((event) => {
              // console.log("title : ", event.title);
              eventList.push(event);
              count++;
              if (count >= maxCount) {
                return true;
              }
            });
          });
        }

        //console.log("eventList : ", eventList);
        eventList.forEach((event) => {
          const startDateMoment = core.date.instance(event.startAt, true);

          const startDate = startDateMoment.toDate();
          let endDate = null as Date | null;
          {
            const endMoment = core.date.instance(event.endAt);
            if (event.allDay) {
              endDate = core.date.instance(endMoment, true).toDate();
            } else {
              const endDateMoment = core.date.instance(endMoment, true);
              const diffDays = endDateMoment.diff(startDate, "days");
              if (diffDays === 1 && endMoment.format("HH:mm:ss") === "00:00:00") {
                endDate = core.date.instance(endMoment, true).add(-1, "days").toDate();
              } else {
                endDate = core.date.instance(endMoment, true).toDate();
              }
            }
          }
          let title = event.title;
          if (!event.owner) {
            // 공유 받았을경우 ◈, 공유한 일정의 경우 ▣
            title = `[공유] ${event.title}`;
          } else if (event.shareCalendarEventId) {
            title = `[공유중] ${event.title}`;
          }

          if (event.type === CalendarEventType.EXPECTED_DATE) {
            title = `[시공예정] ${event.title}`;
          }
          // console.log("calendarId : ", event.calendarId);
          const item = {
            id: event.id,
            name: title,
            start: startDate,
            end: endDate,
            color: event.color,
            timed: false,
          };

          items.push(item);
          // totalPrice += event.price;

          // if (schedule.estimate != null && schedule.estimate.paymentYn === "Y") {
          //   paymentScheduleList.push(item);
          // } else if (schedule.complete) {
          //   completeScheduleList.push(item);
          // } else {
          //   items.push(item);
          // }
        });
        this.updatedType = "schedule";
        this.calendar.totalPrice = totalPrice;
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    setToday() {
      this.calendar.model = "";
    },
    getTotalPrice() {
      return this.moneyFormat(String(this.calendar.totalPrice));
    },
    getMaxDaySize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 105;
      } else {
        if (this.app.size.width < 965) {
          offset = 110;
        } else {
          offset = 115;
        }
      }
      let height = this.app.size.height - offset - 610;
      // 610 - 6개
      if (height > 0) {
        return parseInt(String(height / 105)) + 7;
      } else {
        return 7;
      }
    },
    resize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 170;
      } else {
        if (this.app.size.width < 965) {
          offset = 180;
        } else {
          offset = 190;
        }
      }
      this.calendar.height = this.app.size.height - offset;

      {
        const elList = document.querySelectorAll(".v-event-more-custom");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      {
        const elList = document.querySelectorAll(".v-event-more-custom-issue");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      {
        const elList = document.querySelectorAll(".v-event-more-custom-text");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      const elList = document.querySelectorAll(".v-calendar-weekly__week .v-calendar-weekly__day");
      if (elList != null) {
        const eventElList = document.querySelectorAll(
          ".v-calendar-weekly__week .v-calendar-weekly__day .v-event"
        );

        if (eventElList.length > 0) {
          //console.log("eventElSize : ", eventElList.length);
          eventElList.forEach((el: any) => {
            if (el.style.display == "none") {
              el.style.display = "block";
            }
          });
        }

        elList.forEach((el) => {
          if (el.clientHeight < el.scrollHeight) {
            const eventList = el.querySelectorAll("[data-date]");
            let height = 21; // label 제외
            let viewCount = 0;
            let addMoreCount = 0;
            let date = "";
            eventList.forEach((eventEl: any) => {
              if (eventEl.dataset.date != null) {
                date = eventEl.dataset.date;
              }
              height += eventEl.clientHeight;
              if (eventEl.classList.contains("v-event")) {
                height += 1;
              }
              if (height > el.clientHeight) {
                eventEl.style.display = "none";
                addMoreCount++;
              } else {
                viewCount++;
              }
            });

            if (addMoreCount > 0) {
              //console.log("date : ", date, ", addMoreCount : ", addMoreCount);
              const list = this.calendar.dateMap[date];
              //console.log("list : ", list);
              addMoreCount = list.length - viewCount;

              let moreEl = el.querySelector(".v-event-more-custom");
              if (moreEl == null) {
                moreEl = document.createElement("div");
                moreEl.classList.add("v-event-more-custom");
                el.appendChild(moreEl);
              }
              let moreTextEl = el.querySelector(".v-event-more-custom-text");
              if (moreTextEl == null) {
                moreTextEl = document.createElement("div");
                moreTextEl.classList.add("v-event-more-custom-text");
                el.appendChild(moreTextEl);
              }
              moreTextEl.innerHTML = String(addMoreCount);
            }
          }
        });
      }
    },
    showEvent({ day, nativeEvent, event }: any) {
      const startMoment = core.date.instance(event.start);
      const endMoment = core.date.instance(event.end);
      // console.log(
      //   `start ${startMoment.format("YYYY-MM-DD HH:mm:ss")}, end ${endMoment.format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   )}`
      // );
      const diff = startMoment.diff(endMoment, "days");
      if (diff === 0) {
        const searchColumns: any = {};
        for (const key of Object.keys(this.searchColumns)) {
          if (key !== "startDate" && key !== "endDate") {
            const value = this.searchColumns[key];
            if (value != null) {
              searchColumns[key] = value;
            } else if (value === null) {
              searchColumns[key] = "null";
            }
          }
        }
        searchColumns.date = startMoment.format("YYYY-MM-DD");
        // searchColumns.viewType = this.viewType;
        // searchColumns.filterViewType = this.filterViewType;
        //console.log("searchColumns : ", searchColumns);
        this.$router.push({ path: "/calendar/day", query: searchColumns });
      } else {
        const query = {
          // viewType: this.viewType,
        };
        this.$router.push({ path: "/calendar/event/" + event.id, query: query });
      }
    },
    async updateRange({ start, end }: any) {
      //console.log("updateRange : ", start.date);

      const startMoment = core.date.instance(`${start.date}T00:00:00`);
      const endMoment = core.date.instance(`${start.date}T23:59:59`);

      // 타이틀 변경
      this.calendar.title = startMoment.format("YYYY년 MM월");

      //console.log(`start ${startMoment.format("YYYY-MM-DD HH:mm:ss")}`);
      //console.log(`end ${endMoment.format("YYYY-MM-DD HH:mm:ss")}`);

      this.searchColumns.startDate = startMoment.format("YYYY-MM-DD");
      this.searchColumns.endDate = startMoment.add(1, "months").format("YYYY-MM-DD");

      this.$nextTick(() => {
        this.reloadFlag = true;
      });
    },
    viewDay({ date }: any) {
      // this.showScheduleDayModal(date);
      const searchColumns: any = {};
      for (const key of Object.keys(this.searchColumns)) {
        if (key !== "startDate" && key !== "endDate") {
          const value = this.searchColumns[key];
          if (value != null) {
            searchColumns[key] = value;
          } else if (value === null) {
            searchColumns[key] = "null";
          }
        }
      }
      searchColumns.date = date;
      // searchColumns.viewType = this.viewType;
      // searchColumns.filterViewType = this.filterViewType;
      //console.log("searchColumns : ", searchColumns);
      this.$router.push({ path: "/calendar/day", query: searchColumns });
    },
    getEventColor(event: any) {
      return event.color;
    },
    getCalendarInstance() {
      return this.$refs.calendar as Vue & {
        prev: () => void;
        next: () => void;
        checkChange: () => void;
        getFormatter: (format: any) => any;
      };
    },
    prev() {
      this.getCalendarInstance().prev();
      this.replaceState();
    },
    next() {
      this.getCalendarInstance().next();
      this.replaceState();
    },
    swipe(direction: string) {
      //console.log("swipe : ", direction);
      if (direction === "Left") {
        this.next();
      } else if (direction === "Right") {
        this.prev();
      } else {
        return;
      }
    },
  },
});
