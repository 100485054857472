
import mixins from "vue-typed-mixins";
import store from "@/store";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import UserService from "@/services/user/user.service";

export default mixins(MixinsPageForm).extend({
  name: "Login",

  data: () => ({
    app: store.state.app,
    processing: false,
    form: {
      rememberMe: true,
      username: "",
      password: "",
    },
    loading: {
      kakao: false,
      apple: false,
    },
    formConvertField: {
      username: "user_username",
      password: "user_password",
    },
    visible: {
      kakao: true,
      apple: true,
    },
  }),

  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      if (!this.app.isMobile) {
        this.form.rememberMe = false;
      }

      const query = this.$route.query;
      if (core.utils.validate.isNotBlank(query.result)) {
        this.replaceState();
        // 소셜 로그인
        if (query.result === "error") {
          const message = query.message as string;
          await core.alert.show({
            title: "알림",
            body: message,
          });
        } else if (query.result === "success") {
          const token = core.utils.getCookie("SOCIAL-TOKEN") as string;
          const result = await UserService.getSocialToken(token);
          core.utils.deleteCookie("SOCIAL-TOKEN");
          (window as any).socialLogin(JSON.stringify(result));
        }
      }

      this.visible.kakao = true;
      this.visible.apple = true;
    });
  },
  methods: {
    moveRegisterPage() {
      // /register
      this.$router.push({
        path: "/register",
        query: this.$route.query,
      });
    },
    async submit() {
      if (await this.validate()) {
        this.processing = true;

        try {
          const user = await this.$store.dispatch("auth/login", this.form);
          if (user != null) {
            const query = this.$route.query;
            // Constant.param.login
            if (this.isNotBlank(query.shareCalendarEventUuid)) {
              await this.$router.push({
                path: "/sce/" + query.shareCalendarEventUuid,
                query: { s: "true" },
              });
            } else if (this.isNotBlank(query.companyInvitationCode)) {
              await this.$router.push({
                path: "/ci/" + query.companyInvitationCode,
                query: { s: "true" },
              });
            } else {
              await this.$router.push("/");
            }
          }
        } catch (e) {
          // console.log(e);
          this.errorSubmit(e, this.formConvertField);
        }
        this.processing = false;
      }
    },
    loginKakao() {
      this.loading.kakao = true;
      if (this.app.isApp) {
        core.mobile.call({ cmd: "loginKaKao" });
        setTimeout(() => {
          this.loading.kakao = false;
        }, 5000);
      } else {
        window.location.href = "/api/v1/oauth2/authorize/kakao";
      }
    },
    loginApple() {
      this.loading.apple = true;
      if (this.app.isApp && this.app.platform === "ios") {
        core.mobile.call({ cmd: "loginApple" });
        setTimeout(() => {
          this.loading.apple = false;
        }, 5000);
      } else {
        window.location.href = "/api/v1/oauth2/authorize2/apple";
      }
    },
  },
});
