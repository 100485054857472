
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CalendarEventAddEditComponent from "@/components/calendar/EventAddEditComponent.vue";
import CalendarSelectComponent from "@/components/calendar/SelectComponent.vue";
import CalendarAddEditComponent from "@/components/calendar/AddEditComponent.vue";
import { CalendarModel } from "@/models/calendar/calendar.model";

export default mixins(MixinsPage).extend({
  name: "CalendarEdit",
  components: {
    CalendarAddEditComponent,
    CalendarSelectComponent,
    CalendarEventAddEditComponent,
  },
  data: () => ({
    query: null as any,
    params: null as any,
    type: "",
    calendar: null as CalendarModel | null,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.query = this.$route.query;
      //console.log("query : ", this.query);
      if (this.isNotBlank(this.query.id)) {
        const calendarList = (await this.$store.getters[
          "app/getCalendarList"
        ]()) as CalendarModel[];
        calendarList.some((calendar) => {
          if (calendar.id == this.query.id) {
            this.calendar = calendar;
            return true;
          }
        });
        if (this.calendar == null) {
          this.notFound();
        }
        // const calendar = await CalendarService.get(this.query.id);
        // console.log("calendar : ", calendar);
      } else {
        this.notFound();
      }
    });
  },
  watch: {
    type(type) {
      //console.log("type : ", type);
      this.params = {
        type: type,
      };
    },
  },
});
