
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { ComponentProperties } from "@/mixins/component";
import MyCompanyService from "@/services/company/my-company.service";
import Constant from "@/store/constant";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";
import core from "@/core";

export default mixins(MixinsModal).extend({
  name: "CompanyInvitationModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            item: null as any,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    companyName: "",
    content: "",
    url: "",
  }),
  mounted() {
    this.$nextTick(async () => {
      const code = await MyCompanyService.createInvitation();
      const url = (this.url = `${Constant.url}/ci/${code}`);
      const user = (await store.getters["auth/user"]()) as UserModel;
      if (user.company) {
        const companyName = (this.companyName = user.company.name);
        this.content = `[${companyName}]\n업체에서 초대코드가 도착했습니다.\n\n아래 링크를 통해 업체로 참가할 수 있습니다\n${url}`;
      }
    });
  },
  methods: {
    async copyClipboard() {
      const textArea = document.createElement("textarea");
      textArea.value = this.content;

      // textarea 추가
      (this.$refs.body as any).appendChild(textArea);

      textArea.select();
      document.execCommand("copy");
      // textarea 제거
      (this.$refs.body as any).removeChild(textArea);
      await core.alert.show({
        title: "알림",
        body: "링크가 클립보드로 복사되었습니다",
      });
    },
    sendKakao() {
      try {
        (window as any).Kakao.Share.sendDefault({
          objectType: "feed",
          content: {
            title: this.companyName,
            description: "업체에서 초대코드가 도착했습니다.",
            imageUrl: Constant.url + "/img/icons/android-icon-192x192.png",
            link: {
              mobileWebUrl: this.url,
              webUrl: this.url,
            },
          },
          buttons: [
            {
              title: "참가하기",
              link: {
                mobileWebUrl: this.url,
                webUrl: this.url,
              },
            },
          ],
        });
      } catch (e) {
        alert(e);
        core.alert.show({ title: "오류", body: JSON.stringify(e) });
      }
    },
    sendSms() {
      // console.log("content : ", content);
      location.href = core.mobile.getSmsLink("", this.content);
    },
  },
});
