
import store from "@/store";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import QuestionBoardService from "@/services/board/question-board.service";

export default mixins(MixinsPageForm).extend({
  name: "QuestionBoard",
  components: {},
  data: () => ({
    app: store.state.app,
    processing: false,
    questionBoardList: [] as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      const list = await QuestionBoardService.getList();
      this.questionBoardList = list;
      console.log("list : ", list);
    });
  },
  methods: {
    moveViewPage(item) {
      this.$router.push({
        path: "/board/question/" + item.id,
      });
    },
    moveAddPage() {
      this.$router.push({
        path: "/board/question/add",
      });
    },
  },
});
