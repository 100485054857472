
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import { ShareCalendarEventModel } from "@/models/calendar/share-calendar-event.model";
import ShareCalendarEventService from "@/services/calendar/share-calendar-event.service";
import ChangedCalendarEventShareComponent from "@/components/calendar/ChangedEventShareComponent.vue";
import CalendarEventShareComponent from "@/components/calendar/EventShareComponent.vue";

export default mixins(MixinsPage).extend({
  name: "CalendarEventNotOwnerShareComponent",
  components: {
    CalendarEventShareComponent,
    ChangedCalendarEventShareComponent,
  },
  props: {
    query: {
      type: Object as any,
      default: () => {
        return {} as any;
      },
    },
    event: {
      type: Object as () => CalendarEventModel,
      default: () => {
        return {} as CalendarEventModel;
      },
    },
  },
  data: () => ({
    tab: 0,
    shareCalendarEvent: null as ShareCalendarEventModel | null,
    processing: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      const event = this.event as CalendarEventModel;
      if (event.shareCalendarEventId != null) {
        const shareCalendarEvent = (await ShareCalendarEventService.get(
          event.shareCalendarEventId
        )) as ShareCalendarEventModel;
        this.shareCalendarEvent = shareCalendarEvent;
      }
      else if (event.shareReceivedCalendarEventId != null) {
        const shareCalendarEvent = (await ShareCalendarEventService.get(
          event.shareReceivedCalendarEventId
        )) as ShareCalendarEventModel;
        this.shareCalendarEvent = shareCalendarEvent;
      }
    });
  },
  methods: {},
});
