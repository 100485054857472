
import mixins from "vue-typed-mixins";
import MixinsTable from "@/mixins/single/table";
import { UpdateEventType } from "@/types";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import EstimateListItemComponent from "@/components/estimate/ListItemComponent.vue";
import MixinsComponent from "@/mixins/component";
import EstimateService from "@/services/estimate/estimate.service";

export default mixins(MixinsComponent, MixinsScroll, MixinsTable, MixinsAppBarMenu).extend({
  name: "EstimateComponent",
  components: { EstimateListItemComponent },
  props: {
    type: {
      type: String,
    },
  },
  data: () => ({
    table: {
      firstLoading: true,
      loading: false,
      body: document.documentElement,
      defaultColumns: [],
    },
    isContract: false,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 초기화
      this.initTable({
        service: EstimateService,
        serviceFunctionKey: "getTable",
        itemHeight: 70,
      });

      // 테이블 정보 가져오기
      // console.log("searchColumns : ", searchColumns);
      this.loadTable();
    });
  },
  watch: {
    type(type) {
      this.changedType();
    },
    isContract() {
      this.changedContract();
    },
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "properties.updateEvent"() {
      const event = this.getPropsUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (this.addTableItem("id", event.item)) {
            this.setScrollPosition(ScrollPosition.TOP);
            // 현재 작성한 견적서 페이지로 이동
            const item = event.item;
            this.$router.push({
              path: `/estimate/${item.id}`,
            });
          }
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
          // if (this.properties.params.type === "deleted") {
          //   // 견적서 복구됨
          //   this.$router.push({
          //     path: `/estimate/${event.item.id}`,
          //   });
          // }
        } else if (event.result === UpdateEventType.PAGE) {
          const item = event.item;
          const estimate = item.estimate;
          delete item.estimate;
          if (estimate != null) {
            if (this.addTableItem("id", estimate)) {
              this.setScrollPosition(ScrollPosition.TOP);
            }
          }
          // path, query
          this.$router.push(item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    changedType() {
      const type = this.type;
      console.log("changed type : ", type);
      if (this.type === "default") {
        this.table.request.searchColumns = {};
        this.isContract = false;
      } else if (this.type === "contract") {
        this.table.request.searchColumns = { contract: "COMPLETE" };
        this.isContract = true;
      }

      if (!this.table.firstLoading) {
        this.clearTable();
        this.table.firstLoading = true;
        this.loadTable();
      }
    },
    async changedContract() {
      // console.log("isContract : ", this.isContract);
      if (this.isContract) {
        await this.$store.dispatch("topToolbar/changeTitle", {
          title: "계약서 목록",
          ignoreCheck: true,
        });
      } else {
        await this.$store.dispatch("topToolbar/changeTitle", {
          title: "견적서 목록",
          ignoreCheck: true,
        });
      }
    },
  },
});
