
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import { UpdateEventType } from "@/types";
import { ShareCalendarEventModel } from "@/models/calendar/share-calendar-event.model";
import UpdateEvent from "@/models";
import { EstimateModel } from "@/models/estimate/estimate.model";
import core from "@/core";
import ShareCalendarEventService from "@/services/calendar/share-calendar-event.service";
import CalendarUtils from "@/utils/calendar-utils";
import EstimateService from "@/services/estimate/estimate.service";
import Constant from "@/store/constant";
import PhoneInputModal from "@/modals/calendar/PhoneInputModal.vue";
import CalendarEventAddEditComponent from "@/components/calendar/EventAddEditComponent.vue";

import MyCompanyService from "@/services/company/my-company.service";
import { LinkCompanyModel } from "@/models/company/link-company.model";
import { Decimal } from "decimal.js";
import TextFieldModal, { TextType } from "@/modals/core/TextFieldModal.vue";

export enum DetailItemType {
  DEFAULT = "default",
  ADD = "add",
  DELETE = "delete",
}

export interface DetailModel {
  id: number | null;
  description: string; // 품명
  specification: string; // 규격
  quantity: number; // 수량
  unitPrice: number; // 단가
  amount: number; // 합계
  orgQuantity: number; // 원본 수량
  orgUnitPrice: number; // 원본 단가
  orgAmount: number; // 원본 합계 모델
  itemType: DetailItemType;
}

export default mixins(MixinsPage).extend({
  name: "CalendarEventOwnerShareComponent",
  components: {
    TextFieldModal,
    PhoneInputModal,
    CalendarEventAddEditComponent,
  },
  props: {
    query: {
      type: Object as any,
      default: () => {
        return {} as any;
      },
    },
    event: {
      type: Object as () => CalendarEventModel,
      default: () => {
        return {} as CalendarEventModel;
      },
    },
  },
  data: () => ({
    shareCalendarEvent: null as ShareCalendarEventModel | null,
    component: {
      updateEvent: null as UpdateEvent | null,
    },
    type: "add", // add, edit
    editMode: false,
    visible: {
      detail: true,
      shareLink: false,
    },
    view: {
      memo: "",
      totalPrice: "",
    },
    modal: {
      phoneInput: {
        visible: false,
        params: {
          phone: {},
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      linkCompany: {
        visible: false,
        selected: null as any,
      },
      unitPrice: {
        visible: false,
        params: {
          textType: TextType.NUMBER,
          length: null,
          type: "edit",
          title: "단가",
          enableDeleteButton: false,
          text: "",
          item: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    estimate: null as EstimateModel | null,
    estimateDetailList: [] as DetailModel[],
    processing: false,
    shareLinkUrl: "",
    linkCompanyList: [] as any,
    ready: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      const event = this.event as CalendarEventModel;
      if (this.isBlank(event.memo)) {
        this.view.memo = "<span class='grey--text'>내용없음</span>";
      } else {
        this.view.memo = core.utils.format.textToHtml(event.memo);
      }
      //console.log("event : ", event);

      try {
        if (event.estimateId != null) {
          await this.getEstimate(event.estimateId);
          await this.loadEstimateDetail();
        }

        if (event.shareCalendarEventId != null) {
          // 공유일정 존재할 경우
          this.type = "edit";

          this.shareCalendarEvent = (await ShareCalendarEventService.get(
            event.shareCalendarEventId
          )) as ShareCalendarEventModel;

          if (this.shareCalendarEvent.changeStatus === "ESTIMATE") {
            this.editMode = true;
          }

          await this.changedShareCalendarEvent();
        }

        this.changedDetailList();
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }

      if (this.query.edit) {
        this.editMode = true;
      }

      const linkCompanyList = [] as any;
      const orgLinkCompanyList = (await MyCompanyService.getLinkList()) as LinkCompanyModel[];
      orgLinkCompanyList.forEach((linkCompany) => {
        linkCompanyList.push(linkCompany.linkCompany);
      });
      // console.log("linkCompanyList : ", linkCompanyList);
      this.linkCompanyList = linkCompanyList;

      this.ready = true;
    });
  },
  watch: {
    "modal.unitPrice.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.unitPrice);
      if (event?.result == UpdateEventType.CONFIRM) {
        const item = core.utils.format.onlyNumber(event.item);
        if (this.isNotBlank(item)) {
          this.modal.unitPrice.params.item.unitPrice = Number(item);
        }
        this.changedDetailList();
      }
    },
    editMode() {
      this.changedDetailList();
    },
    async "modal.linkCompany.selected"(linkCompany) {
      if (linkCompany) {
        this.modal.linkCompany.selected = null;
        const confirmResult = await core.alert.show({
          title: "알림",
          body: `${linkCompany.name} 업체로 일정을 공유하시겠습니까?`,
          showCancelButton: true,
        });
        if (confirmResult === "confirm") {
          // 공유정보 전송
          this.submit(linkCompany.id);
        }
      }
    },
    "modal.phoneInput.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.phoneInput);
      if (event != null) {
        if (event.result == UpdateEventType.CONFIRM) {
          // console.log("event : ", event);
          const phone = event.item.phone;
          console.log("phone : ", phone);
        } else {
          console.log("unknown result : ", event);
        }
      }
    },
  },
  activated() {
    this.component.updateEvent = this.getPageUpdateEvent();
  },
  methods: {
    showTextEditModal(estimateDetail) {
      console.log("showTextEditModal : ", estimateDetail);
      const modal = this.modal.unitPrice;
      modal.params.text = estimateDetail.unitPrice;
      modal.params.item = estimateDetail;
      modal.visible = true;
    },
    showLinkCompanyModal() {
      const modal = this.modal.linkCompany;
      modal.visible = true;
    },
    async deleteItem() {
      const shareCalendarEvent = this.shareCalendarEvent;
      if (shareCalendarEvent != null) {
        const confirmResult = await core.alert.show({
          title: "확인",
          body: "일정 공유를 삭제하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });
        if (confirmResult === "confirm") {
          try {
            await ShareCalendarEventService.delete(shareCalendarEvent.id);
          } catch (e) {
            console.log(e);
          }
          this.goBack(UpdateEventType.RELOAD);
        }
      }
    },
    cancel() {
      this.editMode = false;
      const shareCalendarEvent = this.shareCalendarEvent;
      if (shareCalendarEvent != null) {
        const estimate = shareCalendarEvent.estimate;
        if (estimate != null) {
          this.estimateDetailList.forEach((estimateDetail) => {
            estimate.detailList.some((detail) => {
              if (estimateDetail.id == detail.id) {
                const unitPriceDecimal = new Decimal(detail.unitPrice);
                estimateDetail.amount = unitPriceDecimal.mul(detail.quantity).toNumber();
                // estimateDetail.amountModel = String(detail.quantity * detail.unitPrice);
                return true;
              }
            });
          });
        }
        this.changedDetailList();
      }
    },
    async copyClipboard() {
      const textArea = document.createElement("textarea");
      textArea.value = this.shareLinkUrl;
      // textarea 추가
      document.body.appendChild(textArea);

      textArea.select();
      document.execCommand("copy");

      // textarea 제거
      document.body.removeChild(textArea);
      await core.alert.show({
        title: "알림",
        body: "링크가 클립보드로 복사되었습니다",
      });
    },
    async getShareContent() {
      if (this.shareCalendarEvent != null) {
        // const event = this.event as CalendarEventModel;
        const shareCalendarEvent = this.shareCalendarEvent as ShareCalendarEventModel;
        let companyName = "";
        if (shareCalendarEvent.company) {
          companyName = shareCalendarEvent.company.name;
        }
        //console.log("shareEstimate : ", shareEstimate);
        return `[${companyName}]\n업체에서 일정을 공유했습니다.\n\n아래 링크를 통해 일정을 공유 받을 수 있습니다.\n${this.shareLinkUrl}`;
      }
      return "";
    },
    showPhoneInputModal() {
      const modal = this.modal.phoneInput;
      modal.visible = true;
    },
    async sendKakao() {
      const content = await this.getShareContent();
      console.log("content : ", content);
      try {
        const shareCalendarEvent = this.shareCalendarEvent as ShareCalendarEventModel;
        let companyName = "";
        if (shareCalendarEvent.company) {
          companyName = shareCalendarEvent.company.name;
        }
        const url = this.shareLinkUrl;
        (window as any).Kakao.Share.sendDefault({
          objectType: "feed",
          content: {
            title: companyName,
            description: "업체에서 일정을 공유했습니다.",
            imageUrl: Constant.url + "/img/icons/android-icon-192x192.png",
            link: {
              mobileWebUrl: url,
              webUrl: url,
            },
          },
          buttons: [
            {
              title: "공유받기",
              link: {
                mobileWebUrl: url,
                webUrl: url,
              },
            },
          ],
        });
      } catch (e) {
        alert(e);
        core.alert.show({ title: "오류", body: JSON.stringify(e) });
      }
    },
    async sendSms() {
      const content = await this.getShareContent();
      // console.log("content : ", content);
      // const url = "sms:?body=" + encodeURIComponent(content);
      location.href = core.mobile.getSmsLink("", content);
    },
    async submit(shareCompanyId) {
      //console.log("링크로 공유하기");
      try {
        const params = {
          eventId: this.event.id,
          estimateDetailList: [] as any,
        } as any;
        const detailList = params.estimateDetailList;
        this.estimateDetailList.forEach((detail) => {
          if (detail.itemType !== DetailItemType.DELETE) {
            const detailParam = {
              unitPrice: detail.unitPrice,
              companyEstimateDetailId: detail.id,
            };
            detailList.push(detailParam);
          }
        });
        if (this.editMode) {
          if (this.shareCalendarEvent != null) {
            const shareCalendarEvent = (await ShareCalendarEventService.update(
              this.shareCalendarEvent.id,
              params
            )) as ShareCalendarEventModel;
            // console.log("shareCalendarEvent : ", shareCalendarEvent);
            this.shareCalendarEvent = shareCalendarEvent;

            this.estimateDetailList = [];
            this.loadEstimateDetail();

            this.changedShareCalendarEvent();
            this.changedDetailList();

            this.updatePreVmEvent(UpdateEventType.RELOAD);
          } else {
            console.log("shareCalendarEvent is null");
          }
          this.cancel();
        } else {
          params.shareCompanyId = shareCompanyId;
          const shareCalendarEvent = (await ShareCalendarEventService.create(
            params
          )) as ShareCalendarEventModel;
          this.shareCalendarEvent = shareCalendarEvent;

          if (shareCompanyId && shareCalendarEvent.shareCompany) {
            await core.alert.show({
              title: "알림",
              body: `${shareCalendarEvent.shareCompany.name} 업체로 공유되었습니다.`,
            });
          } else {
            // console.log("shareCalendarEvent : ", shareCalendarEvent);
            await core.alert.show({
              title: "알림",
              body: "일정 공유 링크가 생성되었습니다.",
            });
          }

          this.type = "edit";

          this.changedShareCalendarEvent();

          this.updatePreVmEvent(UpdateEventType.RELOAD);
        }
      } catch (e) {
        console.log(e);
      }
    },
    eventDateToString() {
      return CalendarUtils.eventDateToString(this.event);
    },
    async getEstimate(estimateId) {
      this.estimate = (await EstimateService.get(estimateId)) as EstimateModel;
      //console.log("estimate : ", this.estimate);
    },
    async loadEstimateDetail() {
      if (this.estimate != null) {
        this.estimate.detailList.forEach((item) => {
          //console.log("detail : ", detail);
          if (item.calendarEventId == this.event.id) {
            const detail: DetailModel = {
              id: item.id,
              description: item.description,
              specification: item.specification,
              quantity: Number(item.quantity),
              unitPrice: Number(item.unitPrice),
              amount: item.amount,
              orgUnitPrice: item.unitPrice,
              orgQuantity: item.quantity,
              orgAmount: item.amount,
              itemType: DetailItemType.ADD,
            };

            this.estimateDetailList.push(detail);
          }
        });
        if (this.estimateDetailList.length === 0) {
          await core.alert.show({
            title: "알림",
            body: "추가할 수 있는 시공범위가 없습니다",
          });
          this.goBack();
        }
      }
      // console.log("estimateDetailList : ", this.estimateDetailList);
    },
    async changedShareCalendarEvent() {
      // 공유 일정 변경됨
      const shareCalendarEvent = this.shareCalendarEvent;
      if (shareCalendarEvent != null) {
        const estimate = shareCalendarEvent.estimate;
        if (estimate != null) {
          this.estimateDetailList.forEach((estimateDetail) => {
            estimate.detailList.some((detail) => {
              if (estimateDetail.id == detail.id) {
                const unitPriceDecimal = new Decimal(detail.unitPrice);
                estimateDetail.unitPrice = detail.unitPrice;
                estimateDetail.quantity = detail.quantity;
                estimateDetail.amount = unitPriceDecimal.mul(detail.quantity).toNumber();
                // estimateDetail.amountModel = String(detail.quantity * detail.unitPrice);
                estimateDetail.itemType = DetailItemType.DEFAULT;
                return true;
              }
            });
          });
          estimate.detailList.some((item) => {
            // estimateDetail.amountModel = String(item.quantity * item.unitPrice);
            let exists = false;
            this.estimateDetailList.some((detail) => {
              if (detail.id === item.id) {
                exists = true;
                return true;
              }
            });
            if (!exists) {
              // const amount = item.quantity * item.unitPrice;
              const unitPriceDecimal = new Decimal(item.unitPrice);
              const amount = unitPriceDecimal.mul(item.quantity).toNumber();
              const detail: DetailModel = {
                id: item.id,
                description: item.description,
                specification: item.specification,
                quantity: Number(item.quantity),
                unitPrice: Number(item.unitPrice),
                amount: amount,
                orgUnitPrice: item.unitPrice,
                orgQuantity: item.quantity,
                orgAmount: amount,
                itemType: DetailItemType.DELETE,
              };

              this.estimateDetailList.push(detail);
            }
            // console.log("detail : ", detail);
            // if (estimateDetail.id == detail.id) {
            //   estimateDetail.amountModel = String(detail.quantity * detail.unitPrice);
            //   return true;
            // }
          });
        }
        // uuid
        const uuid = shareCalendarEvent.uuid;
        this.shareLinkUrl = Constant.url + "/sce/" + uuid;
        this.visible.shareLink = true;

        if (shareCalendarEvent.status == "DELETE_CONFIRM") {
          try {
            const result = await ShareCalendarEventService.delete(shareCalendarEvent.id);
            //console.log("result : ", result);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    changedDetailList() {
      let totalPrice = 0;
      this.estimateDetailList.forEach((detail) => {
        if ((this.editMode && detail.itemType !== DetailItemType.DELETE) || !this.editMode) {
          const unitPriceDecimal = new Decimal(detail.unitPrice);
          const amount = Number(unitPriceDecimal.mul(detail.quantity).toNumber().toFixed(0));
          // console.log("amount : ", detail.amount, amount);
          detail.amount = amount;
          // detail.amount = Number(detail.amountModel);
          // detail.unitPrice = Number((detail.amount / detail.quantity).toFixed(0));
          // detail.unitPrice = Number(detail.unitPriceModel);
          // detail.amount = detail.unitPrice * detail.quantity;
          totalPrice += detail.amount;
        }
      });
      this.view.totalPrice = String(totalPrice);
    },
  },
});
