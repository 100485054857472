import AbstractTableService from "@/services/core/abstract-table.service";
import { TableRequest } from "@/types";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import core from "@/core";

class AllCalendarEventService extends AbstractTableService {
  constructor() {
    super("/api/v1/calendar/event/all", "event");
  }

  get(id: number, params: any): Promise<unknown> {
    return super.get(id, params);
  }

  create(params): Promise<unknown> {
    return super.create(params);
  }

  update(id: number, params): Promise<unknown> {
    return super.update(id, params);
  }

  delete(id: number): Promise<unknown> {
    return super.delete(id);
  }

  getTable(params: TableRequest): Promise<unknown> {
    return super.getTable(params);
  }
}

export default new AllCalendarEventService();
