
import core from "@/core";
import {
  CalendarEventEstimateModel,
  CalendarEventModel,
  EstimateDetail,
} from "@/models/calendar/calendar-event.model";
import { ConstructionStatusModel } from "@/models/construction-status/construction-status.model";
import CalendarUtils from "@/utils/calendar-utils";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { RouteMeta } from "@/router";
import { UserModel } from "@/models/user/user.model";
import Constant from "@/store/constant";

export default mixins(MixinsPageForm).extend({
  name: "ConstructionStatusComponent",
  props: {
    event: {
      type: Object as () => CalendarEventModel,
      default: () => {
        return {
          id: 0,
          name: "",
          calendarId: 0,
          estimateId: null as number | null,
          allDay: false,
          startAt: "",
          endAt: "",
          title: "",
          memo: "",
          color: "",
          price: 0,
          topFixed: false,
          estimateDetailList: null as EstimateDetail[] | null,
          estimate: null as CalendarEventEstimateModel | null,
          complete: false,
        } as CalendarEventModel;
      },
    },
    statusList: {
      type: Array as () => ConstructionStatusModel[],
      default: () => {
        return [] as ConstructionStatusModel[];
      },
    },
  },
  data: () => ({
    step: 1,
    visible: {
      complete: false,
    },
    status: {
      before: null as ConstructionStatusModel | null,
      after: null as ConstructionStatusModel | null,
      defect: null as ConstructionStatusModel | null,
    },
    ready: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      try {
        await this.changedStatusList();
      } catch (e) {
        console.log(e);
      }

      this.ready = true;
    });
  },
  watch: {
    statusList() {
      //console.log("changed statusList");
      this.changedStatusList();
    },
  },
  computed: {
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
  },
  activated() {
    this.visible.complete = this.event && CalendarUtils.isStartAtBeforeToday(this.event);
  },
  methods: {
    async sendConstructionStatusSms() {
      const user = (await this.$store.getters["auth/user"]()) as UserModel;
      if (user.company) {
        const estimate = this.event?.estimate;
        if (estimate) {
          // 업체 이름 추가
          let content = `[${user.company.name}]\n업체에서 시공 현황이 등록되었습니다.\n`;
          // console.log("content : ", content);
          location.href = core.mobile.getSmsLink(estimate.customerPhone, content);
        }
      } else {
        console.log("not found user company : ", user);
      }
    },
    toggleComplete() {
      const appBarMenu = (this.$route.meta as RouteMeta).appBarMenu;
      if (appBarMenu != null) {
        if (appBarMenu.more && appBarMenu.more.list) {
          appBarMenu.more.list.some((menu) => {
            //console.log("menu : ", menu);
            if (menu.id === "doneCancel") {
              menu.clickEvent = true;
              return true;
            }
          });
        }
      }
    },
    async changedStatusList() {
      // 일정 상태 정보 조회

      if (this.statusList != null) {
        const status = this.status;
        this.step = 1;
        status.before = null;
        status.after = null;
        status.defect = null;

        this.statusList.forEach((status: ConstructionStatusModel) => {
          if (status.type === "B") {
            if (this.step < 2) this.step = 2;
            this.status.before = status;
          } else if (status.type === "A") {
            if (this.step < 3) this.step = 3;
            this.status.after = status;
          } else if (status.type === "F") {
            this.status.defect = status;
          }
        });
      }
    },
    showAddPage(type: string) {
      if (type === "B" || type === "A" || type === "F") {
        // 시공전, 시공후 사진 등록
        const query = {
          type: type,
          orgEventId: String(this.event.orgEventId),
        };
        this.$router.push({ path: "/construction-status/add", query: query });
      } else {
        console.log("Unknown type : ", type);
      }
    },
    showViewPage(type: string) {
      //console.log("showViewPage : ", type);
      if (type === "B" || type === "A" || type === "F") {
        let status: any = null;
        if (type === "F") status = this.status.defect;
        else if (type === "B") status = this.status.before;
        else status = this.status.after;

        this.$router.push({ path: `/construction-status/${status.id}` });

        // // 시공전, 시공후 사진 등록
        // this.modal.viewStatus.params.type = type;
        // this.modal.viewStatus.params.statusId = status.id;
        // this.modal.viewStatus.visible = true;
        // console.log(this.modal.viewStatus);
      } else {
        console.log("Unknown type : ", type);
      }
    },

    fileSizeFormat(value: string) {
      return core.utils.format.filesize(true, parseInt(value), 2);
    },
  },
});
