
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import store from "@/store";
import SelectShareSocialModal from "@/modals/user/SelectShareSocialModal.vue";
import UpdateEvent from "@/models";
import MyCompanyService from "@/services/company/my-company.service";
import Constant from "@/store/constant";
import { UserModel } from "@/models/user/user.model";
import CompanyInvitationModal from "@/modals/home/CompanyInvitationModal.vue";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import { AppBarMenuItem, YnType } from "@/types";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "Home",
  components: { CompanyInvitationModal, SelectShareSocialModal },
  data: () => ({
    value: "",
    schedulePage: "/main?page=schedule",
    visible: {
      estimate: false,
      user: false,
    },
    modal: {
      appShare: {
        visible: false,
      },
      selectShareSocial: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      companyInvitation: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
    items: [
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      },
    ],
  }),
  computed: {
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
    isAdminRoleHigher() {
      return this.$store.getters["auth/isAdminRoleHigher"];
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.visible.estimate = this.isManagerRoleHigher;
      this.visible.user = this.isAdminRoleHigher;

      const user = await store.getters["auth/user"]();
      await store.dispatch("topToolbar/changeTitle", {
        title: user.company.name,
        ignoreCheck: true,
      });

      if (user.passwordTempYn === YnType.YES) {
        console.log("임시 비밀번호");
        const confirmResult = await core.alert.show({
          title: "알림",
          body: "임시 비밀번호로 로그인 하셨습니다<br>비밀번호를 변경하러 이동하시겠습니까?",
          showCancelButton: true,
          confirmButtonText: "이동",
          cancelButtonText: "취소",
        });

        if (confirmResult === "confirm") {
          this.$router.push("/user/me/password");
        }
      }
    });
  },
  watch: {
    async "modal.selectShareSocial.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectShareSocial);
      if (event) {
        const item = event.item;
        // console.log("item : ", item);
        this.sendCompanyInvitation(item);
      }
    },
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "company") {
        // console.log("event");
        this.$router.push({ path: "/company/me" });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    showCompanyInvitation() {
      // console.log("사용자 초대");
      const modal = this.modal.companyInvitation;
      modal.visible = true;
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
    calendarPage() {
      this.$router.push("/calendar");
    },
    userPage() {
      this.$router.push("/management/user");
    },
    async sendCompanyInvitation(type) {
      const code = await MyCompanyService.createInvitation();
      const url = `${Constant.url}/ci/${code}`;
      const user = (await store.getters["auth/user"]()) as UserModel;
      const companyName = user.company?.name;
      // console.log("url : ", url);

      console.log("url : ", url);
      if (type === "sms") {
        const content = `[${companyName}]\n업체에서 초대코드가 도착했습니다.\n\n아래 링크를 통해 업체로 참가할 수 있습니다\n${url}`;
        // console.log("content : ", content);
        // const linkUrl = "sms:?body=" + encodeURIComponent(content);
        location.href = core.mobile.getSmsLink("", content);
      } else if (type === "kakao") {
        try {
          (window as any).Kakao.Share.sendDefault({
            objectType: "feed",
            content: {
              title: companyName,
              description: "업체에서 초대코드가 도착했습니다.",
              imageUrl: Constant.url + "/img/icons/android-icon-192x192.png",
              link: {
                mobileWebUrl: url,
                webUrl: url,
              },
            },
            buttons: [
              {
                title: "참가하기",
                link: {
                  mobileWebUrl: url,
                  webUrl: url,
                },
              },
            ],
          });
        } catch (e) {
          alert(e);
          core.alert.show({ title: "오류", body: JSON.stringify(e) });
        }
      } else if (type === "link") {
        const content = `[${companyName}]\n업체에서 초대코드가 도착했습니다.\n\n아래 링크를 통해 업체로 참가할 수 있습니다\n${url}`;
        core.alert.show({ title: "초대링크", body: content });
      } else {
        console.log("unknown type : ", type);
      }
    },
  },
});
