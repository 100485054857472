
import store from "@/store";
import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import QuestionBoardService from "@/services/board/question-board.service";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "QuestionBoardAdd",
  components: {},
  data: () => ({
    app: store.state.app,
    processing: false,
    form: {
      title: "",
      content: "",
    },
    contentRows: 10,
    formConvertField: {
      title: "question_title",
      content: "question_content",
    },
  }),
  mounted() {
    this.$nextTick(() => {
      // QuestionBoardService.get(1);
      this.windowResize();
    });
  },
  watch: {
    "app.size"(size) {
      this.windowResize();
    },
  },
  methods: {
    windowResize() {
      const size = this.app.size;
      // console.log("size", size);
      if (this.app.isMobileSize) {
        // console.log("size.height : ", size.height);
        const rows = (size.height / 35).toFixed(0);
        // console.log("rows : ", rows);
        this.contentRows = Number(rows);
      } else {
        this.contentRows = Number(20);
      }
    },
    moveAddPage() {
      this.$router.push({
        path: "/board/question/add",
      });
    },
    async submit() {
      core.loader.show();
      this.processing = true;
      if (await this.validate()) {
        try {
          const form = this.form;
          //console.log("submit : ", form);
          const questionBoard = await QuestionBoardService.create(form);
          //console.log("questionBoard : ", questionBoard);
          this.goBack(UpdateEventType.UPDATE, questionBoard);
        } catch (e) {
          console.log(e);
          this.errorSubmit(e, this.formConvertField);
        }
      }
      core.loader.hide();
      this.processing = false;
    },
  },
});
