var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.company == null)?_c('v-skeleton-loader',{attrs:{"type":"table-heading, list-item-three-line, table-tfoot"}}):_c('div',[_c('v-text-field',{attrs:{"label":"업체명","name":"name","type":"text","outlined":"","dense":"","readonly":""},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('v-text-field',{attrs:{"label":"대표명","name":"ceoName","type":"text","outlined":"","dense":"","readonly":""},model:{value:(_vm.company.ceoName),callback:function ($$v) {_vm.$set(_vm.company, "ceoName", $$v)},expression:"company.ceoName"}}),_c('v-text-field',{attrs:{"label":"대표번호","name":"phone","type":"text","outlined":"","dense":"","readonly":""},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}}),_c('div',{staticClass:"pl-1 pr-1"},[(
        _vm.bankAccount &&
        _vm.bankAccount.data &&
        _vm.bankAccount.data.list &&
        _vm.bankAccount.data.list.length > 0
      )?_vm._l((_vm.bankAccount.data.list),function(item,i){return _c('div',{key:i},[_c('div',{staticClass:"transition-swing font-weight-bold orange--text mt-4",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" * 계좌 "),(item.default)?[_vm._v("(기본)")]:_vm._e()],2),_c('div',{staticClass:"transition-swing mt-2 pl-3",staticStyle:{"font-size":"0.9rem","line-height":"1.6","white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.bankName)+" "+_vm._s(item.bankAccount)+" "+_vm._s(item.accountHolder)+" ")])])}):_vm._e(),(
        _vm.estimateContent &&
        _vm.estimateContent.data &&
        _vm.estimateContent.data.list &&
        _vm.estimateContent.data.list.length > 0
      )?_vm._l((_vm.estimateContent.data.list),function(item,i){return _c('div',{key:'estimateContent_' + i},[_c('div',{staticClass:"transition-swing font-weight-bold orange--text mt-4",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" * "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"transition-swing mt-2 pl-3",staticStyle:{"font-size":"0.9rem","line-height":"1.6","white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.content)+" ")])])}):_vm._e()],2),(_vm.isOwnerRole)?_c('v-btn',{staticClass:"mt-10",attrs:{"color":"red","min-width":"100%","dark":"","outlined":"","to":"/company/me/withdraw"}},[_vm._v(" 업체 삭제 ")]):_c('v-btn',{staticClass:"mt-10",attrs:{"color":"red","min-width":"100%","dark":"","outlined":""},on:{"click":_vm.unregister}},[_vm._v(" 업체 탈퇴 ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }