
import mixins from "vue-typed-mixins";
import store from "@/store";
import Constant from "@/store/constant";
import MixinsCommon from "@/mixins/single/common";
import MenuListItemComponent from "@/components/core/MenuListItemComponent.vue";
import { NavMenuType } from "@/models/core/nav-menu.model";
import CalendarMenuListItemComponent from "@/components/core/CalendarMenuListItemComponent.vue";
import CalendarUtils from "@/utils/calendar-utils";
import { CalendarModel } from "@/models/calendar/calendar.model";

export default mixins(MixinsCommon).extend({
  name: "CalendarNavigationDrawer",
  components: { CalendarMenuListItemComponent, MenuListItemComponent },
  data: () => ({
    app: store.state.app,
    auth: store.state.auth,
    menuItems: {
      selected: null as any,
      list: [] as any,
    },
    calendarList: [] as CalendarModel[],
  }),
  computed: {
    NavMenuType() {
      return NavMenuType;
    },
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
    Constant() {
      return Constant;
    },
    title() {
      return this.$store.getters["topToolbar/title"];
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.calendarList = await this.$store.getters["app/getCalendarList"]();
      //console.log("calendarList : ", this.calendarList);
    });
  },
  methods: {
    typeToString(type) {
      return CalendarUtils.typeToString(type);
    },
  },
});
