
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CalendarEventAddEditComponent from "@/components/calendar/EventAddEditComponent.vue";
import CalendarSelectComponent from "@/components/calendar/SelectComponent.vue";
import CalendarAddEditComponent from "@/components/calendar/AddEditComponent.vue";
import CalendarListEditComponent from "@/components/calendar/ListEditComponent.vue";

export default mixins(MixinsPage).extend({
  name: "CalendarListEdit",
  components: {
    CalendarListEditComponent,
    CalendarAddEditComponent,
    CalendarSelectComponent,
    CalendarEventAddEditComponent,
  },
  data: () => ({
    query: null as any,
    params: null as any,
    type: "",
  }),
  created() {
    this.query = this.$route.query;
    //console.log("query : ", this.query);
  },
  watch: {
    type(type) {
      //console.log("type : ", type);
      this.params = {
        type: type,
      };
    },
  },
});
