
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import CommentComponent, { CommentProps, Mode } from "@/components/comment/CommentComponent.vue";
import CalendarEventViewInfoComponent from "@/components/calendar/EventViewInfoComponent.vue";
import ConstructionStatusComponent from "@/components/calendar/ConstructionStatusComponent.vue";
import { EntityType } from "@/types";
import { ConstructionStatusModel } from "@/models/construction-status/construction-status.model";

export default mixins(MixinsPage).extend({
  name: "CalendarEventViewComponent",
  components: { ConstructionStatusComponent, CalendarEventViewInfoComponent, CommentComponent },
  props: {
    id: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    query: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
    event: {
      type: Object as () => CalendarEventModel,
      default: () => {
        return {} as CalendarEventModel;
      },
    },
    statusList: {
      type: Array as () => ConstructionStatusModel[],
      default: () => {
        return [] as ConstructionStatusModel[];
      },
    },
  },
  data: () => ({
    tab: 0,
    changedTab: 0,
    ready: false,
    commentChip: -1,
    bodyHeight: 100,
    component: {
      calendarEventEntity: {
        visible: true,
        type: EntityType.CALENDAR_EVENT,
        mode: Mode.DEFAULT,
        id: 0,
        idList: [],
        reloadFlag: false,
        systemMessageList: [] as string[],
      } as CommentProps,
    },
    commentHeader: {
      visible: false,
      style: "",
      buttonStyle: "",
      emptyElStyle: "",
      maxHeight: 0,
    },
    visible: {
      commentChip: false,
    },
  }),
  mounted() {
    //console.log("mounted");
    this.$nextTick(async () => {
      this.resizeWindow();

      //console.log("event : ", this.event);
      if (this.event) {
        if (this.event.owner && this.event.estimateId) {
          this.visible.commentChip = true;
          this.commentChip = 1;
        } else if (this.event.shareCalendarEvent || this.event.shareReceivedCalendarEvent) {
          this.visible.commentChip = true;
          this.commentChip = 0;
        }

        if (this.commentChip < 0) this.commentChip = 0;
      }
      this.updateCommentHeaderBar();
    });
  },
  watch: {
    commentChip(val, preVal) {
      console.log("commentChip : ", this.commentChip);
      if (this.event.owner) {
        if (this.event.estimateId) {
          // 0: 견적댓글, 1: 일정댓글
          const component = this.component.calendarEventEntity;
          if (val === 0) {
            component.type = EntityType.ESTIMATE;
            component.id = this.event.estimateId;
            component.idList = [];
            component.systemMessageList = ["연결된 견적 댓글이 공유됩니다."];
            component.reloadFlag = true;
          } else if (val === 1) {
            component.type = EntityType.CALENDAR_EVENT;
            component.id = this.event.id;
            component.idList = [];
            component.systemMessageList = [];
            component.reloadFlag = true;
          } else if (val === 2) {
            // component.type = EntityType.CALENDAR_EVENT;
            // component.id = this.event.id;
            if (this.event.shareCalendarEvent && this.event.shareCalendarEvent.shareCompany) {
              // 업체 댓글
              component.type = EntityType.SHARE_CALENDAR_EVENT;
              component.id = this.event.shareCalendarEvent.id;
              component.idList = [];
              component.systemMessageList = [
                `${this.event.shareCalendarEvent.shareCompany.name} 업체와 댓글이 공유됩니다.`,
              ];
              component.reloadFlag = true;
            } else if (
              this.event.shareReceivedCalendarEvent &&
              this.event.shareReceivedCalendarEvent.company
            ) {
              // 업체 댓글
              component.type = EntityType.SHARE_CALENDAR_EVENT;
              component.id = this.event.shareReceivedCalendarEvent.id;
              component.idList = [];
              component.systemMessageList = [
                `${this.event.shareReceivedCalendarEvent.company.name} 업체와 댓글이 공유됩니다.`,
              ];
              component.reloadFlag = true;
            } else {
              console.log("invalid comment chip : ", val);
            }
          } else {
            // if (this.event.shareCalendarEvent || this.event.shareReceivedCalendarEvent)
            this.visible.commentChip = true;
            const component = this.component.calendarEventEntity;
            component.type = EntityType.CALENDAR_EVENT;
            component.id = this.event.id;
            component.idList = [];
            component.systemMessageList = [];
            component.reloadFlag = true;
            // console.log("event: ", this.event);
            this.commentChip = 0;
          }
        } else {
          const component = this.component.calendarEventEntity;
          component.type = EntityType.CALENDAR_EVENT;
          component.id = this.event.id;
          component.idList = [];
          component.systemMessageList = [];
          component.reloadFlag = true;
        }
      } else {
        const component = this.component.calendarEventEntity;
        if (val === 0) {
          component.type = EntityType.CALENDAR_EVENT;
          component.id = this.event.id;
          component.idList = [];
          component.systemMessageList = [];
          component.reloadFlag = true;
        } else if (val === 1) {
          if (this.event.shareReceivedCalendarEvent) {
            // 업체 댓글
            component.type = EntityType.SHARE_CALENDAR_EVENT;
            component.id = this.event.shareReceivedCalendarEvent.id;
            component.idList = [];
            component.systemMessageList = [
              `${this.event.shareReceivedCalendarEvent.company.name} 업체와 댓글이 공유됩니다.`,
            ];
            component.reloadFlag = true;
          }
        } else if (val === 2) {
          if (this.event.shareCalendarEvent) {
            // 업체 댓글
            component.type = EntityType.SHARE_CALENDAR_EVENT;
            component.id = this.event.shareCalendarEvent.id;
            component.idList = [];
            component.systemMessageList = [
              `${this.event.shareCalendarEvent.company.name} 업체와 댓글이 공유됩니다.`,
            ];
            component.reloadFlag = true;
          }
        }
        //   // if (this.event.shareCalendarEvent || this.event.shareReceivedCalendarEvent)
        // this.visible.commentChip = true;
        // const component = this.component.calendarEventEntity;
        // component.type = EntityType.CALENDAR_EVENT;
        // component.id = this.event.id;
        // component.systemMessageList = [];
        // component.reloadFlag = true;
        // // console.log("event: ", this.event);
        // this.commentChip = 0;
      }

      if (!this.ready) {
        this.ready = true;
      }
    },
    "app.size"(size) {
      // app-bar, tab 높이 제외
      this.resizeWindow();
    },
    // tab(tab) {
    //   console.log("tab : ", tab);
    // },
  },
  methods: {
    toggleCommentHeaderBar() {
      this.commentHeader.visible = !this.commentHeader.visible;
      this.updateCommentHeaderBar();
    },
    updateCommentHeaderBar() {
      if (this.commentHeader.visible) {
        this.commentHeader.buttonStyle = "position: absolute; bottom: -10px; right: 10px";
        const maxHeight = this.commentHeader.maxHeight;
        this.commentHeader.style = `position: absolute; width: 100%; top: 1px; z-index: 1; min-height: 40px; background: #fff; max-height: ${maxHeight}px`;
        // this.commentHeader.style = `position: fixed; width: 100%; top: 105px; z-index: 1; min-height: 40px; background: #fff; max-height: ${maxHeight}px`;

        this.commentHeader.emptyElStyle = "padding-top: 240px !important";
      } else {
        this.commentHeader.buttonStyle = "position: absolute; bottom: -10px; right: 10px";
        const maxHeight = this.commentHeader.maxHeight;
        this.commentHeader.style = `position: absolute; width: 100%; top: 1px; z-index: 1; min-height: 40px; background: #fff; max-height: ${maxHeight}px`;
        // this.commentHeader.style = `position: fixed; width: 100%; top: 105px; z-index: 1; min-height: 40px; background: #fff; max-height: ${maxHeight}px`;

        this.commentHeader.emptyElStyle = "padding-top: 90px !important";
      }
    },
    resizeWindow() {
      if (this.event && this.event.estimateId) {
        this.bodyHeight = this.app.size.height - 56 - 48;
      } else {
        this.bodyHeight = this.app.size.height - 56;
      }
      this.commentHeader.maxHeight = this.bodyHeight / 2;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
  },
});
