
import CompanyAddEditComponent from "@/components/company/AddEditComponent.vue";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { UserCompanyModel } from "@/models/company/user-company.model";
import CompanyService from "@/services/company/my-company.service";
import { CompanyDetailsModel } from "@/models/company/company-details.model";

export default mixins(MixinsPageForm).extend({
  name: "CompanyEdit",
  components: {
    CompanyAddEditComponent,
  },
  data: () => ({
    company: null as UserCompanyModel | null,
  }),
  mounted() {
    this.$nextTick(async () => {
      try {
        const company = (await CompanyService.get()) as UserCompanyModel;
        company.details = (await CompanyService.getDetails()) as CompanyDetailsModel;
        this.company = company;
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }
    });
  },
  methods: {},
});
