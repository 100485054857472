
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { CompanyUserModel } from "@/models/company/company-user.model";
import CompanyService from "@/services/company/my-company.service";
import core from "@/core";
import UserApprovalEditModal from "@/modals/user/ApprovalEditModal.vue";
import UpdateEvent from "@/models";
import { CompanyRole, UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";
import RoleEditModal from "@/modals/user/RoleEditModal.vue";
import { UserModel } from "@/models/user/user.model";
import HelpCompanyRoleModal from "@/modals/user/HelpCompanyRoleModal.vue";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "UserView",
  components: { HelpCompanyRoleModal, RoleEditModal, UserApprovalEditModal },
  data: () => ({
    dateFormatPattern: "YYYY년 MM월 DD일",
    user: null as CompanyUserModel | null,
    visible: {
      edit: false,
    },
    view: {
      phone: "",
      createdAt: "",
    },
    form: {
      role: "",
    },
    modal: {
      helpCompanyRole: {
        visible: false,
      },
      approval: {
        visible: false,
        params: {
          approvalType: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
      companyRole: {
        visible: false,
        params: {
          role: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params;
      //console.log("query : ", query);
      if (this.isBlank(params.id)) {
        await this.notFound();
        return;
      }
      const id = Number(params.id);
      try {
        const myUser = (await this.$store.getters["auth/user"]()) as UserModel;
        this.visible.edit = myUser.id !== id;

        const user = (await CompanyService.getUser(id)) as CompanyUserModel;
        this.user = user;

        if (this.visible.edit) {
          if (
            myUser.company?.role === CompanyRole.ADMIN &&
            user.company?.role === CompanyRole.OWNER
          ) {
            this.visible.edit = false;
          }
        }

        // test
        // user.email = "khwan7@gmail.com";
        // user.company.role = "MANAGER";

        this.view.phone = core.utils.format.hyphenPhone(user.phone);
        this.view.createdAt = this.dateFormat(user.company.createdAt);
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }
    });
  },
  watch: {
    "modal.approval.updateEvent"(event) {
      //console.log("event : ", event);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const approvalType = event.item as string;
          this.changeApproval(approvalType);
        }
      }
    },
    "modal.companyRole.updateEvent"(event) {
      //console.log("event : ", event);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const role = event.item as string;
          this.changeRole(role);
        }
      }
    },
  },
  methods: {
    getMailLink(email) {
      return core.mobile.getMailLink(email);
    },
    getTelLink(phone) {
      return core.mobile.getTelLink(phone);
    },
    showHelpCompanyRole() {
      const modal = this.modal.helpCompanyRole;
      modal.visible = true;
    },
    companyRoleToStyle() {
      if (this.user != null) {
        const company = this.user.company;
        const role = company.role;
        if (role === "OWNER") {
          return "background-color: rgba(83, 103, 254, 0.2); color: #536dfe; caret-color: #536dfe;";
        } else if (role === "ADMIN") {
          return "background-color: rgba(83, 103, 254, 0.2); color: #536dfe; caret-color: #536dfe;";
        } else if (role === "MANAGER") {
          return "background-color: rgba(60, 212, 160, 0.2); color: #3cd4a0; caret-color: #3cd4a0;";
        } else if (role === "USER") {
          return "일반 사용자";
        }
      }
      return "";
    },
    companyRoleToText() {
      if (this.user != null) {
        const company = this.user.company;
        const role = company.role;
        if (role === "OWNER") {
          return "소유주";
        } else if (role === "ADMIN") {
          return "관리자";
        } else if (role === "MANAGER") {
          return "매니저";
        } else if (role === "USER") {
          return "일반사용자";
        }
      }
      return "알 수 없음";
    },
    async changeApproval(approvalType) {
      // console.log("change approval : ", approvalType);
      let msg = "";
      let preApprovalType = this.user?.company?.lastApproval.type as string;
      if (approvalType === "ALLOW") {
        if (preApprovalType === "REQUEST") {
          msg = "승인하시겠습니까?";
        } else {
          msg = "재승인하시겠습니까?";
        }
      } else if (approvalType === "DENY") {
        if (preApprovalType === "REQUEST") {
          msg = "승인을 거절 하시겠습니까?";
        } else {
          msg = "승인을 해제 하시겠습니까?";
        }
      }

      const result = await core.alert.show({
        title: "확인",
        body: msg,
        showCancelButton: true,
        confirmButtonText: "계속",
      });

      if (result === "confirm") {
        core.loader.show();
        try {
          this.user = (await CompanyService.updateUserApproval(
            this.user?.id as number,
            approvalType
          )) as CompanyUserModel;
          this.updatePreVmEvent(UpdateEventType.UPDATE, cloneDeep(this.user));
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },

    async changeRole(role) {
      // console.log("change role : ", role);
      if (role === (this.user?.company?.role as string)) {
        return;
      }

      let roleKoText = "";
      if (role === "OWNER") {
        roleKoText = "소유주";
      } else if (role === "ADMIN") {
        roleKoText = "관리자";
      } else if (role === "MANAGER") {
        roleKoText = "매니저";
      } else if (role === "USER") {
        roleKoText = "일반사용자";
      }
      const result = await core.alert.show({
        title: "확인",
        body: `사용자 권한을 "${roleKoText}"로 변경하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "계속",
      });

      if (result === "confirm") {
        core.loader.show();
        try {
          this.user = (await CompanyService.updateUserCompanyRole(
            this.user?.id as number,
            role
          )) as CompanyUserModel;
          this.updatePreVmEvent(UpdateEventType.UPDATE, cloneDeep(this.user));
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },

    approvalToText() {
      let content = "알 수 없음";
      if (this.user != null) {
        const company = this.user.company;
        const approval = company.lastApproval;

        if (approval.type === "ALLOW") {
          content = "승인";
        } else if (approval.type === "REQUEST") {
          content = "승인 요청중";
        } else if (approval.type === "DENY") {
          content = "승인 거절";
        }
      }
      //console.log("content : ", content);
      return content;
    },

    approvalToColor() {
      let content = "";
      if (this.user != null) {
        const company = this.user.company;
        const approval = company.lastApproval;
        if (approval.type === "ALLOW") {
          content = "green";
        } else if (approval.type === "REQUEST") {
          content = "orange";
        } else if (approval.type === "DENY") {
          content = "red";
        }
        //console.log("content : ", content);
      }
      return content;
    },

    showApprovalEditModal() {
      const modal = this.modal.approval;
      modal.params.approvalType = this.user?.company?.lastApproval.type as string;
      modal.visible = true;
    },
    showCompanyRoleEditModal() {
      const modal = this.modal.companyRole;
      modal.params.role = this.user?.company?.role as string;
      modal.visible = true;
    },
  },
});
