import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Home from "@/views/Home.vue";
import Logout from "@/views/Logout.vue";
import core from "@/core";
import store from "@/store";
import Login from "@/views/Login.vue";
import Constant from "@/store/constant";
import Register from "@/views/Register.vue";
import Notification from "@/views/Notification.vue";
import { AppBarMenu, ApprovalType, CompanyRole } from "@/types";
import { Role, UserModel } from "@/models/user/user.model";
import { UserCompanyModel } from "@/models/company/user-company.model";
import Settings from "@/views/Settings.vue";
import Company from "@/views/company/Company.vue";
import CompanyAdd from "@/views/company/Add.vue";
import CompanyEdit from "@/views/company/Edit.vue";
import CompanyView from "@/views/company/View.vue";
import CompanyWithdraw from "@/views/company/MyWithdraw.vue";
// import CompanyRegister from "@/views/company/Register.vue";
import CompanyWaitApproval from "@/views/company/WaitApproval.vue";
import Calendar from "@/views/Calendar.vue";
import CalendarSearch from "@/views/calendar/Search.vue";
import CalendarAdd from "@/views/calendar/Add.vue";
import CalendarEdit from "@/views/calendar/Edit.vue";
import CalendarListEdit from "@/views/calendar/ListEdit.vue";
import CalendarEventAdd from "@/views/calendar/EventAdd.vue";
import CalendarEventEdit from "@/views/calendar/EventEdit.vue";
import CalendarEventView from "@/views/calendar/EventView.vue";
import CalendarEventSelect from "@/views/calendar/EventSelect.vue";
import CalendarEventShare from "@/views/calendar/EventShare.vue";
import CalendarDay from "@/views/calendar/Day.vue";
// import UserMe from "@/views/user/Me.vue";
import UserMyInfoView from "@/views/user/MyInfoView.vue";
import UserMyInfoEdit from "@/views/user/MyInfoEdit.vue";
import UserMyPassword from "@/views/user/MyPassword.vue";
import UserMyWithdraw from "@/views/user/MyWithdraw.vue";
import SearchAddress from "@/views/SearchAddress.vue";
import Estimate from "@/views/estimate/Estimate.vue";
import EstimateAdd from "@/views/estimate/Add.vue";
import EstimateEdit from "@/views/estimate/Edit.vue";
import EstimateView from "@/views/estimate/View.vue";
import EstimatePrint from "@/views/estimate/Print.vue";
import EstimatePayment from "@/views/estimate/Payment.vue";
import User from "@/views/user/User.vue";
import UserView from "@/views/user/View.vue";
import UserSearch from "@/views/user/Search.vue";
import EstimateSearch from "@/views/estimate/Search.vue";
import ShareCalendarEventLink from "@/views/calendar/EventShareLink.vue";
import ConstructionStatusAddEdit from "@/views/construction-status/AddEdit.vue";
import ConstructionStatusView from "@/views/construction-status/View.vue";
import CompanyInvitation from "@/views/company/Invitation.vue";
import QuestionBoard from "@/views/board/QuestionBoard.vue";
import QuestionBoardAdd from "@/views/board/QuestionBoardAdd.vue";
import QuestionBoardEdit from "@/views/board/QuestionBoardEdit.vue";
import QuestionBoardView from "@/views/board/QuestionBoardView.vue";
import FindUsername from "@/views/FindUsername.vue";
import FindPassword from "@/views/FindPassword.vue";

Vue.use(VueRouter);

const appName = Constant.appName;

export enum LayoutType {
  NAV = "nav",
  APP_BAR = "app_bar",
  APP_BAR_NAV = "app_bar_nav",
  APP_BAR_BACK = "app_bar_back",
  APP_BOTTOM_BAR = "app_bottom_bar",
}

export interface RouteMeta {
  title: string;
  roles: Role[] | null;
  companyRoles: CompanyRole[] | null | undefined;
  companyApprovalList: ApprovalType[] | null | undefined;
  layoutList: LayoutType[];
  pageStack: boolean;
  appBarMenu: AppBarMenu | null | undefined;
  scrollable: boolean | undefined;
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      title: "",
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "회원가입",
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "로그인",
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/find-username",
    name: "findUsername",
    component: FindUsername,
    meta: {
      title: "사용자 아이디 찾기",
      layoutList: [LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/find-password",
    name: "findPassword",
    component: FindPassword,
    meta: {
      title: "사용자 비밀번호 찾기",
      layoutList: [LayoutType.APP_BAR_BACK] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      title: "로그아웃",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: {
      title: "지원하지 않는 브라우저 입니다",
      roles: null,
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      title: "페이지를 찾을 수 없습니다",
      roles: null,
      layoutList: [LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "홈",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR, LayoutType.APP_BOTTOM_BAR],
      pageStack: false,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-chevron-right",
              text: "업체",
              id: "company",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/notification",
    name: "notification",
    component: Notification,
    meta: {
      title: "알림",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR, LayoutType.APP_BOTTOM_BAR],
      pageStack: false,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-filter-variant",
              text: "필터",
              id: "filter",
              badge: false,
              badgeContent: "",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/sce/:uuid",
    name: "shareCalendarEventLink",
    component: ShareCalendarEventLink,
    meta: {
      title: "일정 공유",
      layoutList: [] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/sced/:uuid",
    name: "shareCalendarEventLinkDetail",
    component: ShareCalendarEventLink,
    meta: {
      title: "일정 공유",
      roles: [Role.MANAGER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/ci/:code",
    name: "companyInvitation",
    component: CompanyInvitation,
    meta: {
      title: "업체 초대",
      layoutList: [] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/management/user",
    name: "user",
    component: User,
    meta: {
      title: "사용자",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/user/search",
    name: "userSearch",
    component: UserSearch,
    meta: {
      title: "사용자 검색",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/user/:id(\\d+)",
    name: "userView",
    component: UserView,
    meta: {
      title: "사용자",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/search-address",
    name: "searchAddress",
    component: SearchAddress,
    meta: {
      title: "주소검색",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/company",
    name: "company",
    component: Company,
    meta: {
      title: "업체",
      roles: [Role.MANAGER],
      layoutList: [] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/company/add",
    name: "companyAdd",
    component: CompanyAdd,
    meta: {
      title: "신규 업체 생성",
      roles: [Role.MANAGER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/company/me/edit",
    name: "companyEdit",
    component: CompanyEdit,
    meta: {
      title: "업체 정보 수정",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/company/me",
    name: "companyView",
    component: CompanyView,
    meta: {
      title: "업체 정보",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              text: "수정",
              id: "edit",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/company/me/withdraw",
    name: "companyWithdraw",
    component: CompanyWithdraw,
    meta: {
      title: "업체삭제",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  // {
  //   path: "/company/register",
  //   name: "companyRegister",
  //   component: CompanyRegister,
  //   meta: {
  //     title: "업체 사용자로 가입",
  //     roles: ["manager"],
  //     companyOptions: null,
  //     menuType: MenuType.BACK,
  //     pageStack: false,
  //     hasCompany: false,
  //     subMenu: null,
  //     scrollable: true,
  //   } as RouteMeta,
  // },
  {
    path: "/company/wait-approval",
    name: "companyWaitApproval",
    component: CompanyWaitApproval,
    meta: {
      title: "업체 승인 대기중",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  // // {
  // //   path: "/dashboard",
  // //   name: "dashboard",
  // //   component: Dashboard,
  // //   meta: {
  // //     title: "대시보드",
  // //     roles: ["manager"],
  // //     companyOptions: null,
  // //     menuType: MenuType.NAVIGATION,
  // //     pageStack: false,
  // //     hasCompany: true,
  // //     subMenu: null,
  // //     scrollable: true,
  // //   } as RouteMeta,
  // // },
  {
    path: "/construction-status/:id(\\d+)",
    name: "constructionStatusView",
    component: ConstructionStatusView,
    meta: {
      title: "시공현황",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
      appBarMenu: {
        more: {
          visible: true,
          list: [
            {
              text: "편집",
              id: "edit",
            },
            {
              text: "삭제",
              id: "delete",
            },
            {
              text: "다운로드",
              id: "download",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/construction-status/:type(add|edit)",
    name: "constructionStatusAddEdit",
    component: ConstructionStatusAddEdit,
    meta: {
      title: "시공현황",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/estimate",
    name: "estimate",
    component: Estimate,
    meta: {
      title: "견적서",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      companyApprovalList: [ApprovalType.ALLOW],
      // layoutList: [LayoutType.NAV, LayoutType.APP_BAR, LayoutType.APP_BOTTOM_BAR],
      layoutList: [LayoutType.NAV, LayoutType.APP_BOTTOM_BAR],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/estimate/search",
    name: "estimateSearch",
    component: EstimateSearch,
    meta: {
      title: "견적서 검색",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/estimate/add",
    name: "estimateAdd",
    component: EstimateAdd,
    meta: {
      title: "견적서 작성",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/estimate/edit",
    name: "estimateEdit",
    component: EstimateEdit,
    meta: {
      title: "견적서 수정",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/estimate/:id(\\d+)",
    name: "estimateView",
    component: EstimateView,
    meta: {
      title: "계약서",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
      appBarMenu: {
        more: {
          visible: true,
          list: [
            {
              text: "내보내기",
              id: "export",
            },
            {
              text: "삭제",
              id: "delete",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/estimate/:id(\\d+)/print",
    name: "estimatePrint",
    component: EstimatePrint,
    meta: {
      title: "견적서 인쇄",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [] as LayoutType[],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/estimate/:id(\\d+)/payment",
    name: "estimatePayment",
    component: EstimatePayment,
    meta: {
      title: "결제",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
    meta: {
      title: "캘린더",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV, LayoutType.APP_BOTTOM_BAR],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/calendar/:id(all|\\d+)",
    name: "calendarType",
    component: Calendar,
    meta: {
      title: "캘린더",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV, LayoutType.APP_BOTTOM_BAR],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            // {
            //   icon: "mdi-filter-variant",
            //   text: "필터",
            //   id: "filter",
            // },
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  // {
  //   path: "/calendar/filter",
  //   name: "calendarFilter",
  //   component: CalendarFilter,
  //   meta: {
  //     title: "일정 필터",
  //     roles: [Role.MANAGER],
  //     companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
  //     companyApprovalList: [ApprovalType.ALLOW],
  //     layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
  //     pageStack: true,
  //     // roles: ["manager"],
  //     // companyOptions: null,
  //     // menuType: MenuType.NONE,
  //     // pageStack: true,
  //     // hasCompany: true,
  //     // subMenu: null,
  //     // scrollable: true,
  //   } as RouteMeta,
  // },
  {
    path: "/calendar/search",
    name: "calendarSearch",
    component: CalendarSearch,
    meta: {
      title: "일정 검색",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/calendar/add",
    name: "calendarAdd",
    component: CalendarAdd,
    meta: {
      title: "캘린더 추가",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/calendar/edit",
    name: "calendarEdit",
    component: CalendarEdit,
    meta: {
      title: "캘린더 수정",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/calendar/list/edit",
    name: "calendarListEdit",
    component: CalendarListEdit,
    meta: {
      title: "캘린더 목록 편집",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/calendar/day",
    name: "calendarDay",
    component: CalendarDay,
    meta: {
      title: "일정",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/calendar/event/select",
    name: "calendarEventSelect",
    component: CalendarEventSelect,
    meta: {
      title: "날짜 선택",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/calendar/event/add",
    name: "calendarEventAdd",
    component: CalendarEventAdd,
    meta: {
      title: "일정",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/calendar/event/edit",
    name: "calendarEventEdit",
    component: CalendarEventEdit,
    meta: {
      title: "일정",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/calendar/event/:id(\\d+)",
    name: "calendarEventView",
    component: CalendarEventView,
    meta: {
      title: "일정",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
      appBarMenu: {
        more: {
          visible: true,
          list: [
            {
              text: "편집",
              id: "edit",
            },
            {
              text: "삭제",
              id: "delete",
            },
            {
              text: "공유해제",
              id: "unshare",
            },
            {
              text: "공유",
              id: "share",
            },
            {
              text: "견적보기",
              id: "estimate",
            },
            {
              text: "시공완료 취소",
              id: "doneCancel",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/calendar/event/:id(\\d+)/share",
    name: "calendarEventShare",
    component: CalendarEventShare,
    meta: {
      title: "일정 공유",
      roles: [Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      title: "설정",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      companyApprovalList: null,
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR, LayoutType.APP_BOTTOM_BAR],
      pageStack: false,
    } as RouteMeta,
  },
  // {
  //   path: "/user/me",
  //   name: "userMe",
  //   component: UserMe,
  //   meta: {
  //     title: "내 정보",
  //     roles: [Role.ADMIN, Role.MANAGER, Role.USER],
  //     companyApprovalList: null,
  //     layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
  //     pageStack: false,
  //   } as RouteMeta,
  // },
  {
    path: "/user/me/info",
    name: "userMyInfoView",
    component: UserMyInfoView,
    meta: {
      title: "회원정보",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      companyApprovalList: null,
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              text: "수정",
              id: "edit",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/user/me/info/edit",
    name: "userMyInfoEdit",
    component: UserMyInfoEdit,
    meta: {
      title: "회원정보",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      companyApprovalList: null,
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/user/me/password",
    name: "userMyPassword",
    component: UserMyPassword,
    meta: {
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      title: "비밀번호 변경",
      companyApprovalList: null,
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/user/me/withdraw",
    name: "userMyWithdraw",
    component: UserMyWithdraw,
    meta: {
      title: "회원탈퇴",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/board/question",
    name: "questionBoard",
    component: QuestionBoard,
    meta: {
      title: "문의하기",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      companyApprovalList: null,
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/board/question/add",
    name: "questionBoardAdd",
    component: QuestionBoardAdd,
    meta: {
      title: "문의하기",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      companyApprovalList: null,
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/board/question/edit",
    name: "questionBoardEdit",
    component: QuestionBoardEdit,
    meta: {
      title: "문의하기",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      companyApprovalList: null,
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/board/question/:id(\\d+)",
    name: "questionBoardView",
    component: QuestionBoardView,
    meta: {
      title: "문의하기",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      companyApprovalList: null,
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }

  if (data.meta != null) {
    // appBarMenu 초기값 설정
    const appBarMenu = (data.meta as RouteMeta).appBarMenu;
    if (appBarMenu) {
      ["icon", "more"].forEach((key) => {
        if (appBarMenu[key]) {
          const list = appBarMenu[key].list;
          if (list != null) {
            list.forEach((menu) => {
              [
                { key: "visible", value: true },
                { key: "clickEvent", value: false },
              ].forEach((defaultColumn) => {
                if (menu[defaultColumn.key] == undefined) {
                  menu[defaultColumn.key] = defaultColumn.value;
                }
              });
            });
          }
        }
      });
    }
  }
});

async function routerResult(next, path: string): Promise<RouteMeta | null> {
  next({ path: path });
  const route = routeMap.get(path);
  // console.log("routerResult path : ", path);
  if (route) {
    return route.meta;
  }
  return routeMap.get("*").meta;
}

async function beforeEach(to, from, next) {
  const user = (await store.getters["auth/user"]()) as UserModel;
  if (user) {
    if (to.path === "/login") {
      return await routerResult(next, "/");
    }

    // 로그인 중인 사용자
    if (to.meta.roles != null && to.meta.roles.length > 0) {
      let accessible = false;
      user.roles.some((role) => {
        if (to.meta.roles.indexOf(role) > -1) {
          accessible = true;
          return true;
        }
      });

      if (!accessible) {
        return await routerResult(next, "/");
      }

      if (user.company) {
        const userCompany = user.company as UserCompanyModel;
        if (to.meta.companyRoles != null && to.meta.companyRoles.length > 0) {
          accessible = false;
          to.meta.companyRoles.some((companyRole) => {
            if (userCompany.role === companyRole) {
              accessible = true;
              return true;
            }
          });
          if (!accessible) {
            return await routerResult(next, "/");
          }
        }

        if (to.meta.companyApprovalList) {
          accessible = false;
          to.meta.companyApprovalList.some((companyApproval) => {
            if (userCompany.lastApproval.type === companyApproval) {
              accessible = true;
              return true;
            }
          });
          if (accessible) {
            next();
            return to.meta;
          }
        } else {
          accessible = true;
        }

        if (accessible) {
          next();
          return to.meta;
        } else if (userCompany.lastApproval.type !== ApprovalType.ALLOW) {
          // if (to.path === "/logout" || to.path === "/user/me/withdraw") {
          //   next();
          //   return to.meta;
          // } else
          if (to.path !== "/company/wait-approval") {
            return await routerResult(next, "/company/wait-approval");
          }
        } else if (to.path === "/company") {
          return await routerResult(next, "/");
        }
      } else {
        if (to.meta.companyRoles != null && to.meta.companyRoles.length > 0) {
          if (to.path !== "/company") {
            return await routerResult(next, "/company");
          }
        }
      }
      next();
    } else {
      // 비로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.path.toLowerCase() === "/login") {
        if (core.utils.validate.isBlank(to.query.v)) {
          // 로그인중인 사용자가 로그인 페이지 접근시 이동
          return await routerResult(next, "/home");
        } else {
          // 로그인중인 사용자가 자동인증 정보파라미터를 포함해서 들어왔을 경우 로그인 페이지로 이동
          next();
        }
      } else {
        next();
      }
      // console.log("로그인 사용자 next, 페이지 권한 필요없음");
    }
  } else {
    if (to.meta.roles != null && to.meta.roles.length > 0) {
      // 로그인 사용자 접근 가능 페이지일 경우 메인 페이지로 이동
      return await routerResult(next, "/login");
    } else {
      next();
      // console.log("비로그인 사용자 next, 페이지 권한 필요없음");
    }
  }

  return to.meta;
}

let first = true;
let overflow = true;
router.beforeEach(async (to, from, next) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);
  //console.log("from " + from.path + " -> to : " + to.path);
  if (first) {
    first = false;
    core.preloader.show();
  }

  if (store.state.app.printClass) {
    await store.dispatch("app/clearPrint");
  }

  if (store.state.topToolbar.changedStyle) {
    await store.dispatch("topToolbar/changeOrgStyle");
  }

  if (core.toast.isShow()) {
    core.toast.hide();
  }

  if (
    (from.path === "/home" && to.path === "/login") ||
    (from.path === "/login" && to.path === "/login") ||
    (from.path === "/company" && to.path === "/company")
  ) {
    window.history.back();
    return;
  }

  if (to.path.indexOf("/print") > -1) {
    if (core.utils.redirectWebviewBrowser(router)) {
      return;
    }
  }

  const meta = await beforeEach(to, from, next);
  if (meta != null) {
    if (meta.title != null) {
      if (meta.title.length > 0) {
        document.title = `${appName} - ${meta.title}`;
      } else {
        document.title = appName;
      }
    }

    // if (hasLayout(meta, LayoutType.APP_BAR, LayoutType.APP_BAR_NAV, LayoutType.APP_BAR_BACK)) {
    //   await store.dispatch("topToolbar/changeTitle", { title: meta.title, ignoreCheck: false });
    // }
    await store.dispatch("topToolbar/changeTitle", { title: meta.title, ignoreCheck: false });

    if (meta.scrollable !== undefined) {
      if (meta.scrollable) {
        overflow = true;
        document.documentElement.classList.remove("scc-scrollbar-hidden");
      } else {
        overflow = false;
        document.documentElement.classList.add("scc-scrollbar-hidden");
      }
    } else if (!overflow) {
      document.documentElement.classList.remove("scc-scrollbar-hidden");
    }
  }

  if (core.preloader.isShow()) {
    core.preloader.hide();
  }
});

export default router;
