
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import UpdateEvent from "@/models";
import { ComponentProperties } from "@/mixins/component";
import core from "@/core";
import { UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";
import UnitPriceService from "@/services/unit-price/unit-price.service";
import { Decimal } from "decimal.js";

export default mixins(MixinsModalForm).extend({
  name: "EstimateItemInputModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            item: null as any,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    isEditMode: false,
    addUnitPrice: false,
    form: {
      index: null as any,
      description: "",
      specification: "",
      quantity: "1",
      unitPrice: "",
      amount: "",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const item = this.properties.params?.item;
      if (item != null) {
        if (item.index != null) {
          this.isEditMode = true;
        }
        this.form.index = item.index;
        this.form.description = item.description;
        this.form.specification = item.specification;
        this.form.quantity = item.quantity;
        this.form.unitPrice = item.unitPrice;
        this.form.amount = item.amount;
        // console.log("item : ", item);
      }
    });
  },
  watch: {
    "form.quantity"(val) {
      if (val != null) {
        if (String(val).indexOf(".") > -1) {
          const numberAndDotVal = core.utils.format.numberAndDot(val);
          if (this.isNotBlank(numberAndDotVal)) {
            const array = numberAndDotVal.split(".");
            let length = array[1].length;
            if (length > 3) length = 3;
            // console.log("length : ", length);
            this.form.quantity = Number(numberAndDotVal).toFixed(length);
          }
        } else {
          this.form.quantity = core.utils.format.onlyNumber(val);
        }
        this.changedAmount();
      }
    },
    "form.unitPrice"(val) {
      if (val != null) {
        this.form.unitPrice = core.utils.format.onlyNumber(val);
        this.changedAmount();
      }
    },
  },
  methods: {
    deleteItem() {
      this.close(UpdateEventType.DELETE, this.form);
    },
    showGetRecentItem() {
      // console.log("최근항목가져오기 표시");
      this.close(UpdateEventType.EVENT, { type: "showGetRecentItem" });
    },
    changedAmount() {
      //console.log("quantity : ", this.form.quantity);
      //console.log("unitPrice : ", this.form.unitPrice);
      if (this.isNotBlank(this.form.unitPrice) && this.isNotBlank(this.form.quantity)) {
        const unitPriceDecimal = new Decimal(this.form.unitPrice);
        this.form.amount = core.utils.format.money(
          String(unitPriceDecimal.mul(this.form.quantity).toNumber())
        );
      } else {
        this.form.amount = "";
      }
    },
    async submit() {
      //console.log("submit");
      if (await this.validate()) {
        //console.log("form : ", this.form);
        const form = cloneDeep(this.form);
        form.amount = core.utils.format.onlyNumber(form.amount);
        //console.log("form : ", form);
        if (this.addUnitPrice) {
          try {
            // 단가 추가
            const params = {
              description: form.description,
              specification: form.specification,
              quantity: form.quantity,
              unitPrice: form.unitPrice,
            };
            const unitPrice = await UnitPriceService.create(params);
            // console.log("unitPrice : ", unitPrice);
          } catch (e) {
            console.log("failed create unit-price : ", e);
          }
        }

        this.close(UpdateEventType.CONFIRM, form);
      }
    },
  },
});
