
import core from "@/core";
import Vue from "vue";
import { EstimateModel } from "@/models/estimate/estimate.model";

export default Vue.extend({
  name: "EstimatePrintComponent",
  props: {
    estimate: {
      type: Object as () => EstimateModel,
      default: () => {
        return null;
      },
    },
  },
  data: () => ({
    title: "",
    address: "",
    view: {
      estimateNote: "",
      bankAccount: "",
    },
  }),
  mounted() {
    this.$nextTick(() => {
      // 생성 후 호출
    });
  },
  methods: {
    moneyFormat(value) {
      return core.utils.format.money(value) + "원";
    },
    dateFormat(value) {
      return core.date.instance(value).format("YYYY년 M월 D일");
    },
    getCompanyPhone() {
      const estimate = this.estimate as EstimateModel | null;
      if (estimate && estimate.ownerCompany) {
        return core.utils.format.hyphenPhone(estimate.ownerCompany.phone);
      }
      return "";
    },
    getAddressText() {
      const estimate = this.estimate as EstimateModel | null;
      if (estimate) {
        let text = estimate.address;
        if (this.isNotBlank(estimate.dong)) {
          text += ` ${estimate.dong}동`;
        }
        if (this.isNotBlank(estimate.ho)) {
          text += ` ${estimate.ho}호`;
        }
        return text;
      }
      return "";
    },
    isNotBlank(value) {
      return core.utils.validate.isNotBlank(value);
    },
  },
});
