
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import { cloneDeep } from "lodash";
import Constant from "@/store/constant";
import UserService from "@/services/user/user.service";

export default mixins(MixinsPageForm).extend({
  name: "Register",
  data: () => ({
    ready: false,
    socialLogin: false,
    provider: "",
    processing: false,
    form: {
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      emailCertificationNumber: "",
      emailVerifyToken: "",
      name: "",
      // phone: "",
    },
    emailAuth: {
      visible: {
        help: false,
      },
      verified: false,
      waitTimeMillis: 600000,
      remainingTimeMin: "10",
      date: null as Date | null,
      interval: null as number | null,
      expired: false,
      processing: false,
    },
    // auth: {
    //   visible: {
    //     help: false,
    //   },
    //   remainingTimeMin: "10",
    //   date: null as Date | null,
    //   interval: null as any,
    //   expired: false,
    //   processing: false,
    // },
    formConvertField: {
      username: "user_username",
      password: "user_password",
      confirmPassword: "user_confirmPassword",
      name: "user_name",
      email: "user_email",
      phone: "user_phone",
      emailCertificationNumber: "user_emailCertificationNumber",
    },
    enableSubmit: false,
  }),
  watch: {
    // "form.phone"() {
    //   this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
    //   // this.form.phoneVerifyToken = "";
    //   // this.auth.visible.help = false;
    //   // this.form.certificationNumber = "";
    // },
    "form.emailCertificationNumber"(val) {
      if (this.ready) {
        if (this.isNotBlank(val) && val.length === 6) {
          // 이메일 인증번호 검증
          console.log("이메일 인증번호 검증");
          this.processing = true;
          this.resetValidate();
          setTimeout(async () => {
            const emailVerify = await this.checkEmailVerify();
            // console.log("emailVerify : ", emailVerify);
            this.processing = false;
            if (!emailVerify) {
              // 인증번호 필드로 포커스 이동
              this.moveFocus("emailCertificationNumber");
            } else {
              this.emailAuth.verified = true;
              // 아이디 이메일로 자동 설정
              this.form.username = this.form.email;
              this.moveFocus("password");
            }
          }, 200);
        }
      }
    },
  },
  mounted() {
    const query = this.$route.query as any;
    if (query.token != null) {
      // 소셜로그인 회원정보
      this.socialLogin = true;
      this.form.email = query.email;
      this.form.name = query.name;
      this.provider = query.provider;
    }
    setTimeout(() => {
      this.ready = true;
    }, 500);
  },
  beforeRouteLeave(to, from, next) {
    // if (this.auth.interval != null) {
    //   clearInterval(this.auth.interval);
    //   this.auth.interval = null;
    // }
    next();
  },
  methods: {
    async sendEmailVerify() {
      //console.log("call send email verify");
      await this.validate();
      const observer = this.$refs.observer as any;
      if (
        observer.errors != null &&
        observer.errors.user_email &&
        observer.errors.user_email.length > 0
      ) {
        return;
      } else if (this.isBlank(this.form.email)) {
        return;
      }
      const auth = this.emailAuth;
      auth.processing = true;

      try {
        const token = (await UserService.createEmailVerify(this.form.email)) as string;
        this.form.emailVerifyToken = token;
        this.form.emailCertificationNumber = "";
        //console.log("token : ", token);
        auth.visible.help = true;
        auth.date = new Date();
        auth.expired = false;
        auth.remainingTimeMin = "10분";
        if (auth.interval == null) {
          auth.interval = setInterval(() => {
            const date = new Date();
            if (auth.date != null) {
              const elapsedTimeMillis = date.getTime() - auth.date.getTime();
              const remainingTimeMillis = auth.waitTimeMillis - elapsedTimeMillis;
              if (remainingTimeMillis > 0) {
                auth.remainingTimeMin =
                  String(parseInt(String(remainingTimeMillis / 60000)) + 1) + "분";
                //console.log("remainingTimeMillis : ", remainingTimeMillis);
              } else {
                auth.date = null;
                auth.remainingTimeMin = "만료";
                auth.expired = true;
                auth.verified = false;
                this.form.emailVerifyToken = "";
                this.form.emailCertificationNumber = "";
              }
            } else if (auth.interval != null) {
              clearInterval(auth.interval);
              auth.interval = null;
            }
          }, 200);

          // 인증번호 필드로 포커스 이동
          this.moveFocus("emailCertificationNumber");
          this.resetValidate();
        }
      } catch (e) {
        console.log(e);
        this.errorSubmit(e, this.formConvertField);
      } finally {
        auth.processing = false;
      }
    },
    async checkEmailVerify() {
      const token = this.form.emailVerifyToken;
      const emailCertificationNumber = this.form.emailCertificationNumber;
      const email = this.form.email;
      try {
        const result = (await UserService.checkEmailVerify(
          token,
          email,
          emailCertificationNumber
        )) as string;
        //console.log("result : ", result);
        return true;
      } catch (e) {
        //console.log("error : ", e);
        this.errorSubmit(e, this.formConvertField);
        return false;
      }
    },
    async submit() {
      if (!this.socialLogin && this.isBlank(this.form.emailVerifyToken)) {
        await this.sendEmailVerify();
        return;
      }

      if (await this.validate()) {
        this.processing = true;
        // const phoneVerify = await this.checkPhoneVerify();
        // //console.log("phoneVerify : ", phoneVerify);
        // if (!phoneVerify) {
        //   this.processing = false;
        //   return;
        // }

        try {
          if (this.socialLogin) {
            const query = this.$route.query;

            const params = {
              name: this.form.name,
              // phone: this.form.phone,
              token: query.token,
            };
            await this.$store.dispatch("auth/socialRegister", params);
          } else {
            const params = cloneDeep(this.form) as any;
            if (this.isBlank(params.email)) {
              delete params.email;
            }

            await this.$store.dispatch("auth/register", params);
          }
          const alertResult = await core.alert.show({
            title: "알림",
            body: "회원가입이 완료되었습니다.",
            confirmButtonText: "확인",
          });
          const query = {} as any;
          // console.log("Constant.param.login : ", Constant.param.login);
          Constant.param.login.forEach((paramName) => {
            if (this.$route.query[paramName]) {
              query[paramName] = this.$route.query[paramName];
            }
          });
          await this.$router.push({
            path: "/login",
            query: query,
          });
        } catch (e) {
          console.log("error : ", e);
          this.errorSubmit(e, this.formConvertField);
        }
        this.processing = false;
      }
    },
  },
});
