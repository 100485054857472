
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MyCompanyService from "@/services/company/my-company.service";
import core from "@/core";
import store from "@/store";

export default mixins(MixinsPageForm).extend({
  name: "WaitApproval",
  data: () => ({
    companyName: "",
    approvalType: "",
    auth: store.state.auth,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      await this.changeUserCompany();
    });
  },
  watch: {
    "auth.changedCompanyDate"() {
      this.changeUserCompany();
    },
  },
  methods: {
    async changeUserCompany() {
      const user = await this.$store.getters["auth/user"]();
      //console.log("change user company : ", user.company);
      if (user.company) {
        this.companyName = user.company.name;
        this.approvalType = user.company.lastApproval.type;
        if (this.approvalType === "ALLOW") {
          await this.$router.push("/");
          return;
        }
      }
    },
    async unregister() {
      const store = this.$store;
      const router = this.$router;
      const result = await core.alert.show({
        title: "확인",
        body: "업체 멤버 등록을 해제하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        core.loader.show();
        try {
          const result = await MyCompanyService.unregister();
          const user = await this.$store.getters["auth/user"](true);
          await router.push("/company");
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});
