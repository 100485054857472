import AbstractTableService from "@/services/core/abstract-table.service";
import { TableRequest } from "@/types";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import core from "@/core";

class CalendarEventService extends AbstractTableService {
  constructor() {
    super("/api/v1/calendar/event", "event");
  }

  get(id: number, params: any): Promise<unknown> {
    return super.get(id, params);
  }

  createEventUser(id: number): Promise<unknown> {
    const url = this.url;
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`${url}/${id}/user`, "")
        .then((data) => {
          if (typeof data === "object") {
            resolve(data);
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  removeEventUser(id: number): Promise<unknown> {
    const url = this.url;
    return new Promise((resolve, reject) => {
      core.http
        .delete(`${url}/${id}/user`)
        .then((data) => {
          if (typeof data === "object") {
            resolve(data);
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  existsEventUser(id: number): Promise<unknown> {
    const url = this.url;
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}/${id}/user/exists`, null)
        .then((data) => {
          if (typeof data === "object") {
            const item = data["exists"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getList(params: any): Promise<unknown> {
    const url = this.url;
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, params)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey + "List"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  create(params): Promise<unknown> {
    return super.create(params);
  }

  update(id: number, params): Promise<unknown> {
    return super.update(id, params);
  }

  delete(id: number): Promise<unknown> {
    return super.delete(id);
  }

  getTable(params: TableRequest): Promise<unknown> {
    return super.getTable(params);
  }

  updateComplete(id: number, complete: boolean): Promise<unknown> {
    const params = {
      complete: complete,
    };
    const url = this.getUrl();
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`${url}/${id}/complete`, strJson)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  dateToText(item: CalendarEventModel): string {
    let yearFormat = "YYYY년 ";
    const moment = core.date.instance();
    const startMoment = core.date.instance(item.startAt);
    const endMoment = core.date.instance(item.endAt);

    if (
      moment.format("YYYY") === startMoment.format("YYYY") &&
      moment.format("YYYY") === endMoment.format("YYYY")
    ) {
      yearFormat = "";
    }

    let strDate = "";
    // console.log(startMoment.format("YYYY-MM-DD"));
    // console.log(endMoment.format("YYYY-MM-DD"));
    if (startMoment.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      if (item.allDay) {
        strDate = startMoment.format(yearFormat + "MM월 DD일(ddd)");
      } else {
        strDate =
          startMoment.format(yearFormat + "MM월 DD일(ddd) a hh:mm") +
          " ~ " +
          endMoment.format("a hh:mm");
      }
    } else {
      if (item.allDay) {
        strDate =
          startMoment.format(yearFormat + "MM월 DD일(ddd)") +
          " ~ " +
          endMoment.format(yearFormat + "MM월 DD일(ddd)");
      } else {
        strDate =
          startMoment.format(yearFormat + "MM월 DD일(ddd) a hh:mm") +
          " ~ " +
          endMoment.format(yearFormat + "MM월 DD일(ddd) a hh:mm");
      }
    }
    return strDate;
  }

  detailToText(item: CalendarEventModel): string {
    let text = "";
    if (item.estimateDetailList != null) {
      item.estimateDetailList.forEach((detail) => {
        //console.log("detail : ", detail);
        text += detail.description + ",";
      });
      if (text.length > 0) {
        text = text.substr(0, text.length - 1);
      }
    }
    return text;
  }

  sortEventList(eventList: CalendarEventModel[], searchColumns: any) {
    if (searchColumns.startDate != null && searchColumns.endDate != null) {
      const startMoment = core.date.instance(searchColumns.startDate);
      const endMoment = core.date.instance(searchColumns.endDate);
      const diffDays = endMoment.diff(startMoment, "days");
      //console.log("diff days : ", diffDays);
      if (diffDays <= 1) {
        this.sortEventListByTime(eventList, startMoment, endMoment);

        this.updateEventTitle(eventList);
      }
    }
  }

  sortEventListByTime(eventList: any, startMoment, endMoment) {
    const sortItems = {
      default: {
        day: [] as any,
        dawn: [] as any,
        morning: [] as any,
        afternoon: [] as any,
        evening: [] as any,
      },
    };

    for (let i = 0; i < eventList.length; i++) {
      const item = eventList[i];
      item.showMainTitle = false;
      delete item.mainTitle;
      item.showSubTitle = false;
      delete item.subTitle;

      const items = sortItems.default;

      if (item.allDay) {
        item.showType = "day";
        if (item.topFixed) {
          item.showType = item.showType + "_top";
        }
        items.day.push(item);
      } else if (!item.allDay) {
        let startAt = null as any;

        const endAtMoment = core.date.instance(item.endAt);
        // if (endAtMoment.format("HH:mm:ss") === "00:00:00") {
        //   // 만약 종료날짜가 검색 요청 일자와 동일하고, 0시 0분 0초 일경우 삭제 처리한다.
        //   if (startMoment.format("YYYY-MM-DD") === endAtMoment.format("YYYY-MM-DD")) {
        //     console.log(
        //       `delete schedule title '${item.title}', startAt '${item.startAt}', endAt '${item.endAt}'`
        //     );
        //     scheduleList.splice(i, 1);
        //     i--;
        //     continue;
        //   }
        // }

        const startAtDateMoment = core.date.instance(
          core.date.instance(item.startAt).format("YYYY-MM-DDT00:00:00")
        );
        if (startAtDateMoment.isBefore(startMoment)) {
          startAt = startMoment.format("YYYY-MM-DD") + "T00:00:00";
        } else {
          startAt = item.startAt;
        }
        item.viewStartAt = startAt;

        let endAt = null as any;

        const endAtDateMoment = core.date.instance(endAtMoment.format("YYYY-MM-DDT00:00:00"));
        if (endAtDateMoment.isAfter(startMoment)) {
          endAt = startMoment.format("YYYY-MM-DD") + "T00:00:00";
        } else {
          endAt = item.endAt;
        }
        item.viewEndAt = endAt;
        if (core.date.instance(startAt).hours() < 6) {
          item.showType = "dawn";
          items.dawn.push(item);
        } else if (core.date.instance(startAt).hours() < 12) {
          item.showType = "morning";
          items.morning.push(item);
        } else if (core.date.instance(startAt).hours() < 18) {
          item.showType = "afternoon";
          items.afternoon.push(item);
        } else {
          item.showType = "evening";
          items.evening.push(item);
        }
        if (item.topFixed) {
          item.showType = item.showType + "_top";
        }
      }
    }

    const keys = ["default"];
    const subKeys = ["day", "dawn", "morning", "afternoon", "evening"];
    eventList.length = 0;
    keys.forEach((key) => {
      subKeys.forEach((subKey) => {
        const list = sortItems[key][subKey].sort((a: any, b: any) => {
          return this.sort(a, b, "title", "asc");
        });
        list.forEach((item) => {
          eventList.push(item);
        });
      });
    });
  }

  sort(a: any, b: any, orderColumnName: string, order: string) {
    if (!a.allDay && !b.allDay) {
      // 시간별
      const aMoment = core.date.instance(a.startAt);
      const bMoment = core.date.instance(b.startAt);
      if (aMoment.isBefore(bMoment)) return -1;
      else if (aMoment.isAfter(bMoment)) return 1;
    }

    const aValue = core.utils.getValueFromObjectId(a, String(orderColumnName));
    const bValue = core.utils.getValueFromObjectId(b, String(orderColumnName));
    if (aValue != null && bValue != null) {
      if (order === "asc") {
        // 오름차순
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        // 내림차순
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    } else {
      return -1;
    }
  }

  private updateEventTitle(eventList: any) {
    let type = "";
    eventList.forEach((event: any) => {
      //this.convertSchedule(schedule);
      if (type != event.showType) {
        type = event.showType;
        event.showSubTitle = true;
        event.subTitle = "";

        if (type === "day") {
          event.subTitle += "종일";
        } else if (type === "dawn") {
          event.subTitle += "새벽";
        } else if (type === "morning") {
          event.subTitle += "오전";
        } else if (type === "afternoon") {
          event.subTitle += "오후";
        } else if (type === "evening") {
          event.subTitle += "저녁";
        } else if (type === "day_top") {
          event.subTitle += "상단고정(종일)";
        } else if (type === "dawn_top") {
          event.subTitle += "상단고정(새벽)";
        } else if (type === "morning_top") {
          event.subTitle += "상단고정(오전)";
        } else if (type === "afternoon_top") {
          event.subTitle += "상단고정(오후)";
        } else if (type === "evening_top") {
          event.subTitle += "상단고정(저녁)";
        } else {
          event.subTitle += "알수없음";
        }
      } else {
        event.showSubTitle = false;
      }
    });
  }
}

export default new CalendarEventService();
