
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MyCompanyService from "@/services/company/my-company.service";
import { UserCompanyModel } from "@/models/company/user-company.model";
import { CompanyDetailsModel } from "@/models/company/company-details.model";
import { CompanyDetailType } from "@/types";
import { CompanyDetailModel } from "@/models/company/company-detail.model";
import core from "@/core";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";

export default mixins(MixinsPageForm).extend({
  name: "CompanyViewComponent",
  data: () => ({
    company: null as UserCompanyModel | null,
    companyDetails: null as CompanyDetailsModel | null,
    bankAccount: null as CompanyDetailModel | null,
    estimateContent: null as CompanyDetailModel | null,
    isOwnerRole: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      await this.init();
      this.isOwnerRole = store.getters["auth/isOwnerRole"];
    });
  },
  methods: {
    async init() {
      //console.log("companyId : ", companyId);

      try {
        const company = (await MyCompanyService.get()) as UserCompanyModel;
        company.phone = this.phoneFormat(company.phone);
        //console.log("company : ", company);

        this.company = company;
        const companyDetails = (await MyCompanyService.getDetails()) as CompanyDetailsModel;
        //console.log("companyDetails : ", companyDetails);
        this.companyDetails = companyDetails;
        this.bankAccount = companyDetails[CompanyDetailType.BANK_ACCOUNT];
        this.estimateContent = companyDetails[CompanyDetailType.ESTIMATE_CONTENT];
        // const companyDetail = companyDetails[CompanyDetailType.BANK_ACCOUNT];
        // console.log(companyDetail);
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }
    },
    async unregister() {
      {
        const alertResult = await core.alert.show({
          title: "확인",
          body: "업체를 탈퇴하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });
        //console.log(alertResult);
        if (alertResult !== "confirm") {
          return;
        }
      }
      core.loader.show();
      try {
        const result = await MyCompanyService.unregister();
        //console.log("result : ", result);

        const user = (await store.getters["auth/user"](true)) as UserModel;
        core.loader.hide();
        const alertResult = await core.alert.show({
          title: "알림",
          body: "업체에서 탈퇴되었습니다",
        });
        // console.log("path : ", this.$route.path);
        if (this.$route.path === "/company/me") {
          await this.$router.back();
        }
      } catch (e) {
        core.loader.hide();
        console.log(e);
      }
    },
  },
});
