
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import core from "@/core";
import ColorItems from "@/data/color-items";
import store from "@/store";
import UpdateEvent from "@/models";
import { cloneDeep } from "lodash";
import { UpdateEventType } from "@/types";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";

export default mixins(MixinsModal).extend({
  name: "CalendarEventDaySelectModal",
  components: {},
  data: () => ({
    title: "",
    eventList: [] as CalendarEventModel[],
    searchColumns: {} as any,
    colorItems: ColorItems,
    colorHexItems: {} as any,
    date: "",
    totalPrice: 0,
    changedSort: false,
    sort: {
      orderColumnName: "id",
      order: "asc",
    } as any,
    listViewType: "default",
    modal: {
      sort: {
        visible: false,
        params: {
          sort: {
            orderColumnName: "id",
            order: "asc",
          },
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      listViewType: {
        visible: false,
        params: {
          listViewType: "default",
        },
        updateEvent: null as any as UpdateEvent | null,
      },
    },
    bodyMinHeight: "100px",
    app: store.state.app,
  }),
  created() {
    this.colorItems.forEach((item: any) => {
      this.colorHexItems[item.color] = item.hex;
    });
  },
  mounted() {
    this.$nextTick(async () => {
      core.loader.show();

      this.resize();

      this.searchColumns = cloneDeep(this.properties.params.searchColumns);

      this.date = this.searchColumns.date;
      delete this.searchColumns.date;

      const startMoment = core.date.instance(this.date);
      const endMoment = core.date.instance(this.date).add(1, "days");

      this.searchColumns.startDate = startMoment.format("YYYY-MM-DD");
      this.searchColumns.endDate = endMoment.format("YYYY-MM-DD");

      //console.log(this.searchColumns);

      this.title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");

      await this.getCalendarEventList();
      core.loader.hide();
    });
  },
  watch: {
    "app.size"() {
      this.resize();
    },
  },
  destroyed() {
    if (this.changedSort) {
      this.setPropsUpdateEvent(UpdateEventType.RELOAD);
    }
  },
  methods: {
    resize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 200;
      } else {
        if (this.app.size.width < 965) {
          offset = 215;
        } else {
          offset = 220;
        }
      }
      this.bodyMinHeight = this.app.size.height - offset + "px";
    },
    getTitle(item: any) {
      return item.title;
      // return ScheduleUtils.getScheduleTitle(this.myUser, this.viewType, item);
      // return "";
    },
    getTime(item: any) {
      if (item.allDay) return "종일";

      const startMoment = core.date.instance(item.startAt);
      // const startMoment = core.date.instance(item.startAt);

      const endMoment = core.date.instance(item.endAt);
      // const endMoment = core.date.instance(item.endAt);

      return `${startMoment.format("a hh:mm")}<br>${endMoment.format("a hh:mm")}`;
    },
    getColorStyle(color: string) {
      const hex = this.colorHexItems[color];
      let style = "";
      if (hex != null) {
        style = `color: ${hex}`;
      }
      //console.log("style : ", style);
      return style;
    },
    swipe(direction: string) {
      if (direction === "Left") {
        this.next();
      } else if (direction === "Right") {
        this.prev();
      } else {
        return;
      }
    },
    next() {
      core.loader.show();
      const moment = core.date.instance(this.date).add(1, "days");
      this.date = moment.format("YYYY-MM-DD");
      this.title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");

      this.searchColumns.startDate = moment.format("YYYY-MM-DD");
      this.searchColumns.endDate = moment.add(1, "days").format("YYYY-MM-DD");
      this.getCalendarEventList();
      (this.$refs.body as HTMLElement).scrollTop = 0;
      core.loader.hide();
    },
    prev() {
      core.loader.show();
      const moment = core.date.instance(this.date).add(-1, "days");
      this.date = moment.format("YYYY-MM-DD");
      this.title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");

      this.searchColumns.startDate = moment.format("YYYY-MM-DD");
      this.searchColumns.endDate = moment.add(1, "days").format("YYYY-MM-DD");
      this.getCalendarEventList();
      (this.$refs.body as HTMLElement).scrollTop = 0;
      core.loader.hide();
    },
    async getCalendarEventList() {
      const params = cloneDeep(this.searchColumns);
      //console.log("params : ", params);
      const eventList = (await CalendarEventService.getList(params)) as CalendarEventModel[];
      this.eventList = eventList;
    },
    drawScheduleList() {
      // let orderColumnName = undefined as string | undefined;
      // let order = undefined as string | undefined;
      // const sort = this.sort;
      // if (sort != null) {
      //   orderColumnName = sort.orderColumnName;
      //   order = sort.order;
      // }
      // const listViewType = this.listViewType;
      // ScheduleService.sortScheduleList(
      //   this.searchColumns,
      //   this.scheduleList,
      //   orderColumnName,
      //   order,
      //   listViewType
      // );
    },
    showSortModal() {
      const modal = this.modal.sort;
      modal.params.sort = this.sort;
      modal.visible = true;
    },
    showListViewTypeModal() {
      const modal = this.modal.listViewType;
      modal.params.listViewType = this.listViewType;
      modal.visible = true;
    },
    async confirm() {
      // const result = await core.alert.show({
      //   title: "확인",
      //   body: `일정을 "${this.title}"로 선택하시겠습니까?`,
      //   showCancelButton: true,
      //   cancelButtonText: "아니오",
      //   confirmButtonText: "예",
      // });
      // if (result === "confirm") {
      //   this.close(UpdateEventType.CONFIRM, this.date);
      // }
      this.close(UpdateEventType.CONFIRM, this.date);
    },
  },
});
