
import Vue from "vue";
import core from "@/core";
import CalendarUtils from "@/utils/calendar-utils";
import {
  CalendarEventEstimateModel,
  CalendarEventModel,
  EstimateDetail,
} from "@/models/calendar/calendar-event.model";

export default Vue.extend({
  name: "CalendarEventViewInfoComponent",
  props: {
    event: {
      type: Object as () => CalendarEventModel,
      default: () => {
        return {
          id: 0,
          name: "",
          calendarId: 0,
          estimateId: null as number | null,
          allDay: false,
          startAt: "",
          endAt: "",
          title: "",
          memo: "",
          color: "",
          price: 0,
          topFixed: false,
          estimateDetailList: null as EstimateDetail[] | null,
          estimate: null as CalendarEventEstimateModel | null,
        } as CalendarEventModel;
      },
    },
    schedule: {
      type: Object,
      default: () => {
        return {
          title: "",
          estimateId: null,
          category: {},
          caption: "",
          memoView: "",
        };
      },
    },
  },
  data: () => ({
    mapLink: "",
    visible: {
      mapMaker: false,
    },
    ready: false,
  }),
  mounted() {
    this.visible.mapMaker = false;

    if (this.event != null && this.event.id != null) {
      this.changedCalendarEvent();
    }
  },
  watch: {
    event(event) {
      if (event != null) {
        this.changedCalendarEvent();
      }
    },
  },
  methods: {
    changedCalendarEvent() {
      const event = this.event;
      if (event.estimate != null) {
        this.mapLink = core.mobile.getGeoLink(String(event.estimate.address));
        this.visible.mapMaker = this.isNotBlank(this.mapLink);
        // const platform = core.utils.platform();
        // if (platform === "android") {
        //   this.visible.mapMaker = true;
        //   this.mapLink = "geo:?q=" + event.estimate.address;
        // }
      }
      this.ready = true;
    },
    getAddressText() {
      if (this.ready) {
        const event = this.event;
        if (event.estimate != null) {
          let text = event.estimate.address;
          if (this.isNotBlank(event.estimate.dong) && this.isNotBlank(event.estimate.ho)) {
            text += ` ${event.estimate.dong}동 ${event.estimate.ho}호`;
          }
          return text;
        }
      }
      return "";
    },
    getCustomerPhone() {
      const event = this.event;
      if (event.estimate != null) {
        return core.utils.format.textToHtml(
          core.utils.format.hyphenPhone(event.estimate.customerPhone)
        );
      }
      return "";
    },
    isNotBlank(val) {
      return core.utils.validate.isNotBlank(val);
    },
    eventDateToString() {
      return CalendarUtils.eventDateToString(this.event);
    },
    estimateDetailToString() {
      const estimateDetailList = this.event.estimateDetailList;
      if (estimateDetailList != null) {
        let text = "";
        estimateDetailList.forEach((detail, index) => {
          text += `${index + 1}. ${detail.description}\n`;
        });
        return text;
      }
      return "";
    },
    memoToHtml() {
      return core.utils.format.textToHtml(this.event.memo);
    },
  },
});
