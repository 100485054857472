
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import core from "@/core";
import ConstructionStatusService from "@/services/construction-status/construction-status.service";
import { UpdateEventType } from "@/types";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";

export interface StatusDetailModel {
  index: number;
  id: number;
  remark: string;
  imageThumbUri: string;
  imageUri: string;
  dataThumbUri: string;
  dataUri: string;
  type: string;
  fileDetailId: number;
}

export default mixins(MixinsPageForm, MixinsScroll).extend({
  name: "ConstructionStatusAddEdit",

  data: () => ({
    title: "",
    type: "",
    imageIndex: 0,
    imageList: [] as any,
    changedImage: false,
    processing: false,
    request: {
      statusId: null as number | null,
      orgEventId: null as number | null,
      type: null as string | null,
    },
    status: null as any,
    scrollUpdatedPosition: null as ScrollPosition | null,
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
  }),
  created() {
    const params = this.$route.params as any;
    this.type = params.type;
    if (this.type === "add") {
      this.title = "시공현황 추가";
    } else if (this.type === "edit") {
      this.title = "시공현황 수정";
    }

    const query = this.$route.query as any;
    if (this.type === "add") {
      if (query.orgEventId == null || query.type == null) {
        this.notFound();
        return;
      }
      this.request.orgEventId = query.orgEventId;
      this.request.type = query.type;
      if (query.type === "F") {
        this.title = "하자현황 추가";
      }
    } else if (this.type === "edit") {
      if (query.statusId == null) {
        this.notFound();
        return;
      }
      this.request.statusId = query.statusId;
    }
  },
  mounted() {
    // viewer 뒤로가기시 닫힘 처리
    const routerBeforeTask = this.$store.state.app.routerBeforeTask;
    const routerBeforeTaskItem = new RouterBeforeTaskItem(this.$vnode.tag as string, () => {
      const viewerContainer = document.querySelector(".viewer-container.viewer-in");
      if (viewerContainer != null) {
        const button = viewerContainer.querySelector(".viewer-button") as any;
        if (button != null) {
          button.click();
        }
        return false;
      }
      return true;
    });
    routerBeforeTask.pushPage(routerBeforeTaskItem);

    this.$nextTick(async () => {
      try {
        if (this.type === "edit") {
          const statusId = this.request.statusId as number;
          const status = (await ConstructionStatusService.get(statusId)) as any;
          this.status = status;
          if (this.status.type === "F") {
            this.title = "하자현황 수정";
          }

          const imageList = [] as StatusDetailModel[];
          status.detailList.forEach((detail) => {
            //console.log(detail);
            const image = {
              index: this.imageIndex++,
              id: detail.id,
              remark: detail.remark,
              imageThumbUri: detail.imageThumbUri,
              imageUri: detail.imageUri,
              dataThumbUri: detail.imageThumbUri,
              dataUri: detail.imageUri,
              type: "link",
              fileDetailId: detail.fileDetailId,
            } as StatusDetailModel;
            imageList.push(image);
          });
          this.imageList = imageList;
          // console.log(imageList);
        }
      } catch (e) {
        console.log(e);
      }
    });
  },
  watch: {
    async title(title) {
      await this.$store.dispatch("topToolbar/changeTitle", { title: title, ignoreCheck: true });
    },
  },
  updated() {
    console.log("updated");
    // if (this.scrollUpdatedPosition != null) {
    //   this.scrollUpdatedPosition = null;
    //   setTimeout(() => {
    //     this.setScrollPosition(ScrollPosition.BOTTOM);
    //   }, 500);
    // }
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    async deleteItem() {
      if (this.type === "edit") {
        try {
          const content =
            this.status?.type === "F"
              ? "하자현황을 삭제하시겠습니까?"
              : "시공현황을 삭제하시겠습니까?";
          const confirmResult = await core.alert.show({
            title: "확인",
            body: content,
            showCancelButton: true,
          });
          if (confirmResult === "confirm") {
            const result = await ConstructionStatusService.delete(this.status.id);
            //console.log("result : ", result);
            this.goBack(UpdateEventType.RELOAD);
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    onView(detail) {
      // console.log("detail : ", detail);
      const imageUri = detail.imageUri ? detail.imageUri : detail.dataUri;
      let selected = null as any;
      this.$viewer.images.some((el) => {
        if (el.src.indexOf(imageUri) > -1) {
          selected = el;
        }
      });
      if (selected != null) {
        selected.click();
      } else {
        this.$viewer.show();
      }

      // if (detail.type === "link") {
      //   window.open(detail.dataUri, "_blank");
      // } else {
      //   const image = new Image();
      //   image.src = detail.dataUri;
      //   const win = window.open("about:blank", "_blank") as any;
      //   win.document.write(image.outerHTML);
      // }
    },
    showImageSelectView() {
      (this.$refs.imageFile as any).click();
    },
    async onChangeImageFile() {
      //console.log("onChangeImageFile");
      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        const imageFileList = [] as any;
        for (let i = 0; i < length; i++) {
          try {
            const file = files[i];
            const imageFile = (await core.utils.image.getImageBlob(file, 1280)) as any;
            imageFile.index = this.imageIndex++;
            imageFile.type = "upload";

            //console.log("new image : ", imageFile);
            imageFileList.push(imageFile);
          } catch (e: any) {
            core.http.sendErrorLog(e);
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
          }
        }

        imageFileList.forEach((imageFile) => {
          this.imageList.push(imageFile);
        });

        this.changedImage = true;
        // this.scrollUpdatedPosition = ScrollPosition.BOTTOM;
        // console.log("addImage : ", this.$refs.addImage);
        if (this.$refs.addImage != null) {
          let count = 0;
          const moveScroll = () => {
            setTimeout(() => {
              //console.log("scroll");
              (this.$refs.addImage as any).$el.scrollIntoView(true);
              // this.setScrollPosition(ScrollPosition.BOTTOM);
              count++;
              if (count === 1) {
                moveScroll();
              }
            }, 200);
          };
          moveScroll();
        }

        (this.$refs.imageFile as any).value = "";
      }
    },
    removeImage(item) {
      const imageList = this.imageList;
      for (let i = 0; i < imageList.length; i++) {
        const image = imageList[i] as any;
        if (image.index == item.index) {
          if (image.type === "link") {
            // 이미 업로드된 이미지일 경우에 이미지 변경됨 처리
            this.changedImage = true;
          }
          imageList.splice(i, 1);
          break;
        }
      }

      if (imageList.length === 0) {
        if (this.type === "add") {
          this.changedImage = false;
        }
      }
    },
    async submit() {
      console.log("submit");
      if (this.processing) {
        return;
      }
      if (this.imageList.length == 0) {
        await core.alert.show({
          title: "알림",
          body: "이미지를 한개 이상 선택해야됩니다.",
        });
        return;
      }

      this.processing = true;
      core.loader.show();

      const params = {
        calendarOrgEventId: this.request.orgEventId,
        type: this.request.type, // 시공전, 시공후
        detailList: [] as any,
      };

      const detailList = params.detailList;
      let uploadImageSize = 0;
      for (let i = 0; i < this.imageList.length; i++) {
        const imageFile = this.imageList[i];
        if (imageFile.type === "upload") {
          uploadImageSize++;
        }
      }

      let uploadImageCount = 0;
      for (let i = 0; i < this.imageList.length; i++) {
        const imageFile = this.imageList[i];
        if (imageFile.type === "upload") {
          const formData = new FormData();
          formData.append("imageFile", imageFile.blob, imageFile.filename);
          try {
            uploadImageCount++;
            core.loader.show(`이미지 업로드중... (${uploadImageCount}/${uploadImageSize})`);
            const fileDetailId = await ConstructionStatusService.createImage(formData);

            const detail = {
              fileDetailId: fileDetailId,
              remark: imageFile.remark != null ? imageFile.remark : null,
            } as any;
            detailList.push(detail);
          } catch (e) {
            console.log(e);
          }
        } else {
          const detail = {
            id: imageFile.id,
            fileDetailId: imageFile.fileDetailId,
            remark: imageFile.remark != null ? imageFile.remark : null,
          } as any;
          detailList.push(detail);
        }
      }

      try {
        core.loader.show("정보 등록중...");

        // 견적 상태 정보 등록
        let status = null as any;
        if (this.type === "add") {
          status = await ConstructionStatusService.create(params);
          // console.log("create status : ", status);
        } else if (this.type === "edit") {
          status = await ConstructionStatusService.update(this.request.statusId as number, params);
          // console.log("update status : ", status);
        }
        this.processing = false;
        core.loader.hide();
        this.goBack(UpdateEventType.RELOAD);

        // const result = await core.alert.show({
        //   title: "확인",
        //   body: "고객에게 문자를 전송하시겠습니까?",
        //   showCancelButton: true,
        //   cancelButtonText: "아니오",
        //   confirmButtonText: "예",
        // });
        //
        // if (result === "confirm") {
        //   core.loader.show("문자 전송중...");
        //
        //   try {
        //     const smsPushId = await StatusService.sendSms(status.id);
        //     const goBack = this.goBack;
        //     let intervalCount = 0;
        //     const interval = setInterval(async () => {
        //       try {
        //         const apiResult = (await SmsService.getSmsResult(smsPushId as string)) as any;
        //         if (apiResult.result) {
        //           clearInterval(interval);
        //           core.loader.hide();
        //           await core.alert.show({
        //             title: "알림",
        //             body: "문자 전송 완료",
        //           });
        //           goBack(UpdateEventType.RELOAD);
        //         } else if (apiResult.code < 0) {
        //           clearInterval(interval);
        //           core.loader.hide();
        //           await core.alert.show({
        //             title: "알림",
        //             body: apiResult.message,
        //           });
        //           goBack(UpdateEventType.RELOAD);
        //         } else if (intervalCount > 10) {
        //           clearInterval(interval);
        //           core.loader.hide();
        //           await core.alert.show({
        //             title: "알림",
        //             body: "문자 전송 실패",
        //           });
        //           goBack(UpdateEventType.RELOAD);
        //         }
        //       } catch (e) {
        //         console.log(e);
        //       }
        //       intervalCount++;
        //     }, 2000);
        //   } catch (e) {
        //     console.log(e);
        //   }
        // } else {
        //   this.goBack(UpdateEventType.RELOAD);
        // }
      } catch (e) {
        console.log(e);
        core.loader.hide();
      }
      this.processing = false;
    },
  },
});
