
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import CalendarUtils from "@/utils/calendar-utils";

export default mixins(MixinsCommon).extend({
  name: "CalendarEventListItemComponent",

  props: {
    item: {
      type: Object as () => CalendarEventModel,
      default: () => {
        return null as CalendarEventModel | null;
      },
    },
  },
  mounted() {},
  methods: {
    movePage(item) {
      if (item.deletedAt == null) {
        const path = "/calendar/event/" + item.id;
        const params = this.$route.params;
        const query = {} as any;
        if (params.id) {
          query.calendarId = params.id;
        }
        this.$router.push({
          path: path,
          query: query,
        });
      }
    },
    eventDateToString(item) {
      return CalendarUtils.eventDateToString(item);
    },
    getColorStyle(item: any) {
      const hex = CalendarUtils.colorToHex(item.color);
      let style = "";
      if (hex != null) {
        style = `color: ${hex}`;
      }
      //console.log("style : ", style);
      return style;
    },
  },
});
