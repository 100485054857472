
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimateAddEditComponent from "@/components/estimate/AddEditComponent.vue";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPage).extend({
  name: "EstimateAdd",
  components: {
    EstimateAddEditComponent,
  },
  data: () => ({
    query: null as any,
    place: null as any,
  }),
  created() {
    this.query = this.$route.query;
  },
  activated() {
    const event = this.getPageUpdateEvent();
    if (event) {
      if (event.result === UpdateEventType.UPDATE) {
        if (event.path.indexOf("/search-address") > -1) {
          const item = event.item;
          this.place = item;
          //console.log("selected place item : ", item);
        } else {
          console.log("unknown path : ", event);
        }
      } else {
        console.log("unknown result : ", event);
      }
    }
  },
});
