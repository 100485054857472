
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { EstimateDetailModel, EstimateModel } from "@/models/estimate/estimate.model";
import { CalendarModel } from "@/models/calendar/calendar.model";
import core from "@/core";
import ColorItems from "@/data/color-items";
import EstimateService from "@/services/estimate/estimate.service";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import { cloneDeep } from "lodash";
import { UserModel } from "@/models/user/user.model";
import { CompanyRole } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "CalendarSelectComponent",
  components: {},
  props: {
    type: {
      type: String,
      default: "add",
    },
    query: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
    // estimate: {
    //   type: Object as () => EstimateModel,
    //   default: () => {
    //     return null as EstimateModel | null;
    //   },
    // },
  },
  data: () => ({
    ready: false,
    colorItems: ColorItems,
    form: {
      estimateId: null as number | null,
      calendarId: null as number | null,
      allDay: true,
      startAt: "",
      endAt: "",
      memo: "",
    },
    visible: {
      detail: false,
    },
    view: {
      totalPrice: "",
    },
    estimate: null as EstimateModel | null,
    calendarList: [] as CalendarModel[],
    estimateDetailList: [] as EstimateDetailModel[],
    selectedEstimateDetailList: [] as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      if (this.isNotBlank(this.query.calendarId)) {
        this.form.calendarId = Number(this.query.calendarId);
      }

      if (this.isNotBlank(this.query.estimateId)) {
        this.estimate = (await EstimateService.get(this.query.estimateId)) as EstimateModel;
        //console.log("estimate : ", this.estimate);
        this.form.estimateId = this.estimate.id;
        this.estimate.detailList.forEach((detail) => {
          //console.log("detail : ", detail);
          this.estimateDetailList.push(detail);
        });

        const user = (await this.$store.getters["auth/user"]()) as UserModel;
        if (
          user.company?.role === CompanyRole.OWNER ||
          user.company?.role === CompanyRole.ADMIN ||
          user.company?.role === CompanyRole.MANAGER
        ) {
          this.visible.detail = true;
        }
      }

      // 캘린더 목록 조회
      const calendarList = await this.$store.getters["app/getCalendarList"]();
      if (this.estimate != null) {
        calendarList.forEach((calendar) => {
          if (calendar.type === "company") {
            this.calendarList.push(calendar);
          }
        });
      } else {
        this.calendarList = calendarList;
      }

      if (this.type === "add") {
        this.form.startAt = core.date.instance().format("YYYY-MM-DDT09:00:00");
        this.form.endAt = core.date.instance().format("YYYY-MM-DDT10:00:00");
      }
      //console.log("form : ", this.form);

      this.ready = true;
    });
  },
  computed: {},
  watch: {
    selectedEstimateDetailList(val) {
      if (val != null) {
        let totalPrice = 0;
        val.forEach((item) => {
          totalPrice += item.amount;
        });
        if (totalPrice > 0) {
          const strTotalPrice = String(totalPrice);
          this.view.totalPrice = this.moneyFormat(strTotalPrice) as any;
        } else {
          this.view.totalPrice = "";
        }
      }
    },
  },
  methods: {
    select(type) {
      //console.log("type : ", type);
      this.$emit("update:type", type);
    },
    getTime(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("a hh:mm");
    },
    getDate(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("YYYY년MM월DD일(ddd)");
    },
    toggleAllDay() {
      const form = this.form;
      form.allDay = !form.allDay;
    },
    changedAllDay() {
      const allDay = this.form.allDay;
      if (!allDay) {
        const sec = core.date
          .instance(this.form.endAt)
          .diff(core.date.instance(this.form.startAt), "seconds");
        if (sec == 0) {
          const startHour = core.date.instance().add(1, "hours").format("HH");
          const endHour = core.date.instance().add(2, "hours").format("HH");
          this.form.startAt = core.date
            .instance(this.form.startAt)
            .format("YYYY-MM-DDT" + startHour + ":00:00");
          this.form.endAt = core.date
            .instance(this.form.endAt)
            .format("YYYY-MM-DDT" + endHour + ":00:00");
        }
        console.log(`change allday, startAt ${this.form.startAt}, endAt ${this.form.endAt}`);
      }
    },
    showScheduleSelectPage() {
      console.log("showScheduleSelectPage");
    },
    showScheduleTimeModal() {
      console.log("showScheduleTimeModal");
    },
    async submit() {
      if (await this.validate()) {
        //console.log("submit");
        const params = cloneDeep(this.form) as any;
        //console.log("params : ", params);
        if (this.selectedEstimateDetailList.length > 0) {
          params.estimateDetailIdList = [] as any;
          this.selectedEstimateDetailList.forEach((estimateDetail) => {
            // console.log("estimateDetail : ", estimateDetail);
            params.estimateDetailIdList.push(estimateDetail.id);
          });
        }
        try {
          const event = await CalendarEventService.create(params);
          // console.log("event : ", event);
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
});
