
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import ShareCalendarEventService from "@/services/calendar/share-calendar-event.service";
import PublicShareCalendarEventService from "@/services/calendar/public-share-calendar-event.service";
import core from "@/core";
import { UserModel } from "@/models/user/user.model";
import { ShareCalendarEventModel } from "@/models/calendar/share-calendar-event.model";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import UpdateEvent from "@/models";
import CalendarSelectModal from "@/modals/calendar/SelectModal.vue";
import { ApprovalType, UpdateEventType } from "@/types";
import Constant from "@/store/constant";

export default mixins(MixinsPageForm).extend({
  name: "EventShareLink",
  components: { CalendarSelectModal },
  data: () => ({
    ready: false,
    uuid: "",
    shareCalendarEvent: null as ShareCalendarEventModel | null,
    totalPrice: 0,
    detailList: [] as any,
    processing: false,
    modal: {
      calendarSelect: {
        visible: false,
        type: "company",
        updateEvent: null as UpdateEvent | null,
      },
    },
    calendarId: null as any,
    visible: {
      logo: true,
      ios: false,
    },
  }),

  mounted() {
    this.$nextTick(async () => {
      try {
        // console.log("path : ", this.$route.path);

        if (!core.mobile.isApp()) {
          const platform = core.utils.platform();
          if (platform === "ios") {
            this.visible.ios = true;
          }
        }

        if (this.$route.path.indexOf("/sced") > -1) {
          this.visible.logo = false;
        }
        const params = this.$route.params;
        this.uuid = params.uuid;
        if (this.isBlank(this.uuid)) {
          await this.notFound();
          return;
        }
        //console.log("uuid : ", this.uuid);
        await this.getShareCalendarEvent();

        this.ready = true;

        const query = this.$route.query;
        if (query.s) {
          // 자동 선택
          await this.submit();
        }
      } catch (e) {
        console.log(e);
        const query = this.$route.query;
        if (query.s) {
          // 자동 선택
          this.goBack();
        } else {
          await this.notFound();
        }
      }
    });
  },
  activated() {},
  watch: {
    "modal.calendarSelect.updateEvent"() {
      const modal = this.modal.calendarSelect;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          // select calendar
          //console.log("select calendar : ", item);
          this.calendarId = item.id;
          this.submit();
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
  },
  methods: {
    moveIosStore() {
      location.href = Constant.iosStoreUrl;
    },
    async updateDeny() {
      console.log("submit");
      try {
        const shareCalendarEvent = this.shareCalendarEvent;
        const params = {
          uuid: shareCalendarEvent?.uuid,
        };
        const response = await ShareCalendarEventService.updateDeny(params);
        // console.log("response : ", response);
        this.goBack(UpdateEventType.RELOAD);
      } catch (e) {
        console.log(e);
      }
    },
    showCalendarSelectModal() {
      // 캘린더 선택 모달
      const modal = this.modal.calendarSelect;
      modal.type = "company";
      modal.visible = true;
    },
    async getShareCalendarEvent() {
      const uuid = this.uuid;
      const shareCalendarEvent = (await PublicShareCalendarEventService.get(
        uuid
      )) as ShareCalendarEventModel;
      this.shareCalendarEvent = shareCalendarEvent;
      //console.log("shareCalendarEvent : ", shareCalendarEvent);
      if (shareCalendarEvent.estimate) {
        shareCalendarEvent.estimate.detailList.forEach((detail) => {
          const item = {
            description: detail.description,
            specification: detail.specification,
            quantity: detail.quantity,
            unitPrice: detail.unitPrice,
            amount: detail.quantity * detail.unitPrice,
          };
          this.detailList.push(item);
          this.totalPrice += item.amount;
        });
      }
    },
    dateToText() {
      if (this.shareCalendarEvent != null && this.shareCalendarEvent.event != null) {
        return CalendarEventService.dateToText(this.shareCalendarEvent.event as any);
      }
      return "";
    },
    async submit() {
      console.log("submit");

      // 앱에서 열도록 처리
      if (!core.mobile.isApp()) {
        const platform = core.utils.platform();
        if (platform === "android") {
          const uuid = this.uuid;
          setTimeout(function () {
            const path = "/sce/" + uuid + "?s=true";
            location.href =
              "intent://action?path=" +
              path +
              "#Intent;scheme=sigong;package=kr.co.scc20.sigong;end";
          }, 200);
          return;
        } else if (platform === "ios") {
          const uuid = this.uuid;
          setTimeout(function () {
            const path = "/sce/" + uuid + "?s=true";
            try {
              location.href = "sigong://action?path=" + path;
            } catch (e) {
              console.log("error : ", e);
            }
          }, 200);
          return;
        }
      }

      // 견적서 받기 처리
      this.processing = true;
      try {
        const user = (await this.$store.getters["auth/user"]()) as UserModel;
        if (user == null) {
          // if (!this.app.isApp) {
          //   const platform = core.utils.platform();
          //   if (platform === "android") {
          //     // 안드로이드 앱 열기
          //   } else if (platform === "ios") {
          //     // ios 앱 열기
          //   }
          //   link = "/login?shareEstimateUuid=" + this.uuid;
          // } else {
          //   link = "/login?shareEstimateUuid=" + this.uuid;
          // }
          await core.alert.show({
            title: "알림",
            body: "로그인후 다시 시도해주세요",
          });

          const query = {
            shareCalendarEventUuid: this.uuid,
          };
          this.$router.push({
            path: "/login",
            query: query,
          });
        } else {
          if (user.company) {
            if (user.company.lastApproval.type !== ApprovalType.ALLOW) {
              await core.alert.show({
                title: "알림",
                body: "업체 승인 후 일정을 공유받을 수 있습니다",
              });
              if (core.mobile.isApp()) {
                this.$router.back();
              }
              return;
            }

            if (this.calendarId == null) {
              this.showCalendarSelectModal();
              return;
            }

            // 업체 정보 존재할 경우 견적서 받기 처리
            console.log("일정 받기 처리");
            const shareCalendarEvent = this.shareCalendarEvent;

            if (user.company.id === shareCalendarEvent?.companyId) {
              try {
                await core.alert.show({
                  title: "알림",
                  body: "내 업체 일정은 공유받을 수 없습니다",
                });
                if (core.mobile.isApp()) {
                  this.$router.back();
                }
                // console.log("<<<<<<<<<<<< back");
                return;
              } catch (e) {
                console.log(e);
              }
            } else {
              try {
                const params = {
                  uuid: this.uuid,
                  calendarId: this.calendarId,
                };
                const response = await ShareCalendarEventService.updateApproval(params);
                //console.log("response : ", response);
                await core.alert.show({
                  title: "알림",
                  body: "일정을 공유받았습니다",
                });
                if (this.visible.logo) {
                  const query = {} as any;
                  if (this.shareCalendarEvent) {
                    query.shareCalendarEventId = this.shareCalendarEvent.id;
                  }
                  let url = "/calendar/";
                  if (this.app.selectedCalendarId) {
                    url += this.app.selectedCalendarId;
                  } else {
                    url += "all";
                  }

                  this.$router.push({
                    path: url,
                    query: query,
                  });
                } else {
                  this.goBack(UpdateEventType.RELOAD);
                }
              } catch (e) {
                console.log(e);
              }
            }
          } else {
            // 업체 등록 페이지 이동
            console.log("업체 등록 페이지 이동 처리");
            await core.alert.show({
              title: "알림",
              body: "업체 정보 등록 후 공유받을 수 있습니다.",
            });
            const query = {
              shareCalendarEventUuid: this.uuid,
            };
            this.$router.push({
              path: "/company",
              query: query,
            });
          }
        }
      } finally {
        this.processing = false;
      }
    },
  },
});
