
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { EstimateDetailModel, EstimateModel } from "@/models/estimate/estimate.model";
import { CalendarModel, CalendarType } from "@/models/calendar/calendar.model";
import core from "@/core";
import ColorItems from "@/data/color-items";
import EstimateService from "@/services/estimate/estimate.service";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import { cloneDeep } from "lodash";
import { UserModel } from "@/models/user/user.model";
import { CompanyRole, UpdateEventType } from "@/types";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import TimePickerModal from "@/modals/core/TimePickerModal.vue";
import UpdateEvent from "@/models";

export default mixins(MixinsPageForm).extend({
  name: "CalendarEventAddEditComponent",
  components: { TimePickerModal },
  props: {
    type: {
      type: String,
      default: "add",
    },
    query: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
    properties: {
      type: Object,
      default: () => {
        return {
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
    // estimate: {
    //   type: Object as () => EstimateModel,
    //   default: () => {
    //     return null as EstimateModel | null;
    //   },
    // },
  },
  data: () => ({
    ready: false,
    colorItems: ColorItems,
    selectAll: false,
    form: {
      estimateId: null as number | null,
      calendarId: null as number | null,
      allDay: true,
      title: "",
      startAt: "",
      endAt: "",
      memo: "",
      color: "",
    },
    visible: {
      detail: false,
    },
    view: {
      totalPrice: "",
    },
    modal: {
      timepicker: {
        start: "",
        visible: false,
        params: {
          time: "",
          type: "",
        },
        // 업데이트 이벤트 처리용 변수
        updateEvent: null as UpdateEvent | null,
      },
    },
    event: null as CalendarEventModel | null,
    estimate: null as EstimateModel | null,
    calendarList: [] as CalendarModel[],
    estimateDetailList: [] as EstimateDetailModel[],
    selectedEstimateDetailList: [] as any,
    shareEstimateId: null as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      if (this.isNotBlank(this.query.calendarId)) {
        this.form.calendarId = Number(this.query.calendarId);
      }

      if (this.isNotBlank(this.query.estimateId)) {
        await this.getEstimate(this.query.estimateId);
      }

      // 캘린더 목록 조회
      const calendarList = await this.$store.getters["app/getCalendarList"]();
      if (this.estimate != null) {
        calendarList.forEach((calendar) => {
          if (calendar.type === "company") {
            this.calendarList.push(calendar);
          }
        });
      } else {
        calendarList.forEach((calendar) => {
          if (calendar.type !== CalendarType.COMPANY_EXPECTED_DATE) {
            this.calendarList.push(calendar);
          }
        });
      }

      if (this.type === "add") {
        if (this.query.startDate != null) {
          this.form.startAt = core.date
            .instance(this.query.startDate as string)
            .format("YYYY-MM-DDT09:00:00");
        } else {
          this.form.startAt = core.date.instance().format("YYYY-MM-DDT09:00:00");
        }

        if (this.query.endDate != null) {
          this.form.endAt = core.date
            .instance(this.query.endDate as string)
            .format("YYYY-MM-DDT10:00:00");
        } else {
          this.form.endAt = core.date.instance().format("YYYY-MM-DDT10:00:00");
        }
        this.form.color = "teal";

        await this.loadEstimateDetail();
      } else if (this.type === "edit") {
        if (this.isBlank(this.query.id) || this.isBlank(this.query.calendarId)) {
          await this.notFound();
          return;
        }
        try {
          const event = (await CalendarEventService.get(this.query.id, {
            calendarId: this.query.calendarId,
          })) as CalendarEventModel;
          this.event = event;
          //console.log("event : ", event);
          this.form.title = event.title;
          this.form.allDay = event.allDay;
          this.form.color = event.color;
          this.form.memo = event.memo;
          this.form.startAt = event.startAt;
          this.form.endAt = event.endAt;

          if (event.estimate != null) {
            if (this.estimate == null) {
              await this.getEstimate(event.estimate.id);
            }
            await this.loadEstimateDetail();

            if (event.estimateDetailList != null) {
              event.estimateDetailList.forEach((selectedDetail) => {
                this.estimate?.detailList.some((detail) => {
                  if (detail.id === selectedDetail.id) {
                    this.selectedEstimateDetailList.push(detail);
                    return true;
                  }
                });
              });
            }
          }
        } catch (e) {
          console.log(e);
          this.notFound();
        }
      }

      //console.log("form : ", this.form);

      this.ready = true;
    });
  },
  computed: {},
  watch: {
    selectAll(selectAll) {
      if (selectAll && this.selectedEstimateDetailList.length != this.estimateDetailList.length) {
        const selectedEstimateDetailList = [] as any;
        this.estimateDetailList.forEach((estimateDetail) => {
          selectedEstimateDetailList.push(estimateDetail);
        });
        this.selectedEstimateDetailList = selectedEstimateDetailList;
      } else if (!selectAll && this.selectedEstimateDetailList.length > 0) {
        this.selectedEstimateDetailList = [];
      }
    },
    "properties.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.properties);
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          //console.log("event : ", event);
          const item = event.item;
          if (item.returnType === "startAt") {
            const prev = this.form.startAt;
            const startAt = item.date + core.date.instance(prev).format("THH:mm:ss");
            const calendar = core.date.calendar(startAt, this.form.endAt, prev);
            this.form.startAt = calendar.startAt;
            this.form.endAt = calendar.endAt;
          } else if (item.returnType === "endAt") {
            const prev = this.form.endAt;
            const endAt = item.date + core.date.instance(prev).format("THH:mm:ss");
            const calendar = core.date.calendar(this.form.startAt, endAt, null, prev);
            this.form.startAt = calendar.startAt;
            this.form.endAt = calendar.endAt;
          } else {
            console.log("unknown returnType : ", item.returnType);
          }
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
    "modal.timepicker.updateEvent"(val) {
      const event = this.getComponentUpdateEvent(this.modal.timepicker);
      if (event != null && event.result == UpdateEventType.CONFIRM) {
        const item = event.item;
        if (item.type === "startAt") {
          const prev = this.form.startAt;
          const startAt =
            core.date.instance(this.form.startAt).format("YYYY-MM-DD") + "T" + item.time + ":00";
          const calendar = core.date.calendar(startAt, this.form.endAt, prev);
          this.form.startAt = calendar.startAt;
          this.form.endAt = calendar.endAt;
        } else if (item.type === "endAt") {
          const prev = this.form.endAt;
          const endAt =
            core.date.instance(this.form.endAt).format("YYYY-MM-DD") + "T" + item.time + ":00";
          const calendar = core.date.calendar(this.form.startAt, endAt, null, prev);
          this.form.startAt = calendar.startAt;
          this.form.endAt = calendar.endAt;
        } else {
          console.log("Unknown item, ", item);
        }
      }
    },
    selectedEstimateDetailList(val) {
      if (val != null) {
        //console.log("val : ", val);
        let totalPrice = 0;
        let shareEstimateId = null as any;
        val.forEach((item) => {
          totalPrice += item.amount;
          if (item.shareEstimateId != null && shareEstimateId == null) {
            shareEstimateId = item.shareEstimateId;
          }
        });

        if (this.selectAll && val.length === 0) {
          this.selectAll = false;
        } else if (!this.selectAll && val.length === this.estimateDetailList.length) {
          this.selectAll = true;
        }
        this.shareEstimateId = shareEstimateId;

        if (totalPrice > 0) {
          const strTotalPrice = String(totalPrice);
          this.view.totalPrice = this.moneyFormat(strTotalPrice) as any;
        } else {
          this.view.totalPrice = "";
        }
      }
    },
  },
  methods: {
    isEstimateDetailDisabled(estimateDetail) {
      if (this.shareEstimateId != null && this.shareEstimateId != estimateDetail.shareEstimateId) {
        return true;
      } else if (
        this.shareEstimateId == null &&
        this.selectedEstimateDetailList.length > 0 &&
        estimateDetail.shareEstimateId != null
      ) {
        return true;
      }
      return false;
    },
    async deleteItem() {
      try {
        const confirmResult = await core.alert.show({
          title: "확인",
          body: "일정을 삭제하시겠습니까?",
          showCancelButton: true,
        });
        if (confirmResult === "confirm") {
          const event = this.event as CalendarEventModel;
          const result = await CalendarEventService.delete(event.id);
          //console.log("result : ", result);
          this.goBack(UpdateEventType.DELETE, this.event);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getEstimate(estimateId) {
      this.estimate = (await EstimateService.get(estimateId)) as EstimateModel;
      //console.log("estimate : ", this.estimate);

      if (this.type === "add" && this.isBlank(this.form.title)) {
        if (this.isNotBlank(this.estimate.address)) {
          this.form.title = this.estimate.address as string;
        } else {
          this.form.title = this.estimate.account;
        }
        if (this.isNotBlank(this.estimate.dong)) {
          this.form.title = this.form.title + " " + this.estimate.dong + "동";
        }
        if (this.isNotBlank(this.estimate.ho)) {
          this.form.title = this.form.title + " " + this.estimate.ho + "호";
        }
      }

      this.form.estimateId = estimateId;
      const user = (await this.$store.getters["auth/user"]()) as UserModel;
      //console.log("user : ", user);
      if (
        user.company?.role === CompanyRole.OWNER ||
        user.company?.role === CompanyRole.ADMIN ||
        user.company?.role === CompanyRole.MANAGER
      ) {
        this.visible.detail = true;
      }
    },
    async loadEstimateDetail() {
      if (this.estimate != null) {
        this.estimate.detailList.forEach((detail) => {
          //console.log("detail : ", detail);
          if (detail.calendarEventId == null) {
            this.estimateDetailList.push(detail);
          } else if (this.event != null && this.event.id === detail.calendarEventId) {
            this.estimateDetailList.push(detail);
          }
        });
        if (this.estimateDetailList.length === 0) {
          await core.alert.show({
            title: "알림",
            body: "추가할 수 있는 시공범위가 없습니다",
          });
          this.goBack();
        }
      }
    },
    showScheduleTimeModal(type: string, at: string) {
      const modal = this.modal.timepicker;
      const moment = core.date.instance(at);
      modal.params.time = moment.format("HH:mm");
      modal.params.type = type;
      modal.start = "";
      if (type === "startAt" && this.estimate != null) {
        modal.start = "09:00";
      } else if (type === "endAt") {
        const startAtMoment = core.date.instance(this.form.startAt);
        const endAtMoment = core.date.instance(this.form.endAt);
        const strStartAt = startAtMoment.format("YYYYMMDD");
        const strEndAt = endAtMoment.format("YYYYMMDD");
        //console.log(`strStartAt : ${strStartAt}, strEndAt : ${strEndAt}`);
        if (strStartAt === strEndAt) {
          modal.start = core.date.instance(this.form.startAt).format("HH:mm");
        }
      }
      modal.visible = true;
    },
    showScheduleSelectPage(returnType: string, date: string) {
      const moment = core.date.instance(date);
      const query = {
        month: moment.format("YYYY-MM"),
        returnType: returnType,
      } as any;

      if (this.isNotBlank(this.form.calendarId)) {
        query.calendarId = this.form.calendarId;
      }

      this.$router.push({
        path: `/calendar/event/select`,
        query: query,
      });
    },
    getTime(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("a hh:mm");
    },
    getDate(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("YYYY년MM월DD일(ddd)");
    },
    toggleAllDay() {
      const form = this.form;
      form.allDay = !form.allDay;
    },
    changedAllDay() {
      const allDay = this.form.allDay;
      if (!allDay) {
        const sec = core.date
          .instance(this.form.endAt)
          .diff(core.date.instance(this.form.startAt), "seconds");
        if (sec == 0) {
          const startHour = core.date.instance().add(1, "hours").format("HH");
          const endHour = core.date.instance().add(2, "hours").format("HH");
          this.form.startAt = core.date
            .instance(this.form.startAt)
            .format("YYYY-MM-DDT" + startHour + ":00:00");
          this.form.endAt = core.date
            .instance(this.form.endAt)
            .format("YYYY-MM-DDT" + endHour + ":00:00");
        }
        console.log(`change allday, startAt ${this.form.startAt}, endAt ${this.form.endAt}`);
      }
    },
    async submit() {
      if (await this.validate()) {
        //console.log("submit");
        const params = cloneDeep(this.form) as any;
        //console.log("params : ", params);
        if (this.selectedEstimateDetailList.length > 0) {
          params.estimateDetailIdList = [] as any;
          this.selectedEstimateDetailList.forEach((estimateDetail) => {
            // console.log("estimateDetail : ", estimateDetail);
            params.estimateDetailIdList.push(estimateDetail.id);
          });
        }
        try {
          let event = null as CalendarEventModel | null;
          if (this.type === "add") {
            event = (await CalendarEventService.create(params)) as CalendarEventModel;
            // console.log("created event : ", event);
          } else if (this.type === "edit") {
            event = (await CalendarEventService.update(
              this.query.id,
              params
            )) as CalendarEventModel;
            // console.log("updated event : ", event);
          }
          this.goBack(UpdateEventType.UPDATE, event);
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
});
