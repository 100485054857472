import AbstractCoreService from "@/services/core/abstract-core.service";
import core from "@/core";

class CompanyService extends AbstractCoreService {
  constructor() {
    super("/api/v1/company", "company");
  }

  getInvitation(code: string) {
    const url = this.url + "/invitation/" + code;
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            const item = data["company"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new CompanyService();
