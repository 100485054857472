
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { ComponentProperties } from "@/mixins/component";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import { UpdateEventType } from "@/types";
import EstimateService from "@/services/estimate/estimate.service";
import { EstimateModel } from "@/models/estimate/estimate.model";
import core from "@/core";

export default mixins(MixinsModal, MixinsTable, MixinsScroll).extend({
  name: "SelectEstimateModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            item: null as any,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    table: {
      body: null as any,
      defaultColumns: [],
    },
    search: {
      keyword: "",
      submitKeyword: "",
      loading: false,
      readonly: false,
    },
    scroll: {
      enable: true,
      first: true,
      eventFunc: null as any,
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
      interval: null as any,
      intervalCount: 0,
      control: {
        scrollTop: 0,
        date: (null as any) as Date | null,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const scroll = this.scroll;
      const $el = this.$refs.body as any;
      this.table.body = $el;

      // 테이블 초기화
      this.initTable({
        service: EstimateService,
        serviceFunctionKey: "getTable",
        itemHeight: 110,
      });

      scroll.eventFunc = function () {
        scroll.scrollTop = $el.scrollTop;
        scroll.scrollHeight = $el.scrollHeight;
        scroll.clientHeight = $el.clientHeight;
        //console.log("scrollTop : ", $el.scrollTop);
      };

      $el.addEventListener("scroll", scroll.eventFunc);

      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
      //console.log("change scrollTop : ", scrollTop);
    },
  },
  destroyed() {
    //console.log("destroyed");
    const $el = this.$refs.body as any;
    if ($el != null) {
      $el.removeEventListener("scroll", this.scroll.eventFunc);
    }
  },
  methods: {
    titleToText(item: EstimateModel) {
      return EstimateService.titleToText(item);
    },
    contractToHtml(item: EstimateModel) {
      return EstimateService.contractToHtml(item);
    },
    priceToHtml(item: EstimateModel) {
      return EstimateService.priceToHtml(item);
    },
    scheduleToHtml(item: EstimateModel) {
      return EstimateService.scheduleToHtml(item);
    },
    selectItem(item) {
      this.close(UpdateEventType.CONFIRM, item);
    },

    keywordToSearchColumns(searchKeyword: string) {
      const searchColumns = {} as any;

      // 1. 동호수 형식 분류, 101동 101호, 101-101, 101.101
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const dongHoResult = core.utils.format.textToDongHo(searchKeyword) as any;
        // console.log("dongHoResult : ", dongHoResult);
        if (dongHoResult != null) {
          console.log(`replace content ${searchKeyword} -> ${dongHoResult.replaceContent}`);
          searchKeyword = dongHoResult.replaceContent;
          if (dongHoResult.list.length > 1) {
            const dongList = [] as any;
            const hoList = [] as any;
            dongHoResult.list.forEach((dongHo) => {
              dongList.push(dongHo.dong);
              hoList.push(dongHo.ho);
            });
            searchColumns.dongList = dongList.list.join(",");
            searchColumns.hoList = hoList.list.join(",");
          } else {
            searchColumns.dong = dongHoResult.list[0].dong;
            searchColumns.hoStartLike = dongHoResult.list[0].ho;
          }
        }
      }

      // 2. 주소 또는 거래처 검색
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        searchColumns.orAddressLike = searchKeyword;
        searchColumns.orAccountLike = searchKeyword;
      }
      return searchColumns;
    },
    async submit() {
      //console.log("submit");
      const keyword = this.search.keyword.trim();
      this.search.submitKeyword = keyword;
      const searchColumns = this.keywordToSearchColumns(keyword);
      this.loadTable(searchColumns);
    },
  },
});
