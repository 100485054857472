
import store from "@/store";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import QuestionBoardService from "@/services/board/question-board.service";
import { QuestionModel } from "@/models/board/question.model";
import core from "@/core";
import { UserModel } from "@/models/user/user.model";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "QuestionBoardView",
  components: {},
  data: () => ({
    app: store.state.app,
    processing: false,
    questionBoard: null as QuestionModel | null,
    form: {
      content: "",
    },
    user: {} as UserModel,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.user = (await store.getters["auth/user"]()) as UserModel;

      const id = Number(this.$route.params.id);
      if (isNaN(id)) {
        await this.notFound();
        return;
      }
      try {
        const questionBoard = (this.questionBoard = (await QuestionBoardService.get(
          id
        )) as QuestionModel);
        // console.log("questionBoard : ", questionBoard);
      } catch (e) {
        this.goBack(UpdateEventType.RELOAD);
        return;
      }
    });
  },
  methods: {
    textToHtml(content) {
      return core.utils.format.textToHtml(content);
    },
    async deleteAnswer(item) {
      const confirmResult = await core.alert.show({
        title: "확인",
        body: "댓글을 정말 삭제하시겠습니까?",
        showCancelButton: true,
      });
      if (confirmResult === "confirm") {
        const result = (await QuestionBoardService.deleteAnswer(item.id)) as QuestionModel;
        this.questionBoard = result;
      }
    },
    async editItem() {
      if (this.questionBoard) {
        this.$router.push({
          path: "/board/question/edit",
          query: { id: String(this.questionBoard.id) },
        });
      }
    },
    async deleteItem() {
      if (this.questionBoard) {
        const confirmResult = await core.alert.show({
          title: "확인",
          body: "게시물을 정말 삭제하시겠습니까?",
          showCancelButton: true,
        });
        if (confirmResult === "confirm") {
          await QuestionBoardService.delete(this.questionBoard.id);
          this.goBack(UpdateEventType.RELOAD);
        }
      }
    },
    async submit() {
      core.loader.show();
      this.processing = true;
      if (await this.validate()) {
        try {
          const form = this.form;
          //console.log("submit : ", form);
          if (this.questionBoard) {
            const result = (await QuestionBoardService.createAnswer(
              this.questionBoard.id,
              form
            )) as QuestionModel;
            form.content = "";
            this.questionBoard = result;
            //console.log("result : ", result);
            this.resetValidate();
          }
        } catch (e) {
          console.log(e);
          this.errorSubmit(e);
        }
      }
      core.loader.hide();
      this.processing = false;
    },
  },
});
