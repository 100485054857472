
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { ComponentProperties } from "@/mixins/component";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";

export default mixins(MixinsModalForm).extend({
  name: "EstimateMemoEditModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            memo: "",
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    form: {
      memo: "",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      this.form.memo = this.properties.params.memo;
    });
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        this.close(UpdateEventType.CONFIRM, cloneDeep(this.form));
      }
    },
  },
});
