
import core from "@/core";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";

export default mixins(MixinsPage).extend({
  name: "Company",
  data: () => ({
    app: store.state.app,
    loading: true,
  }),
  mounted() {
    this.$nextTick(async () => {
      try {
        const user = (await store.getters["auth/user"](true)) as UserModel | null;
        if (user == null) {
          await this.notFound();
          return;
        }
        // console.log("user : ", user);
        if (user.company != null) {
          await this.$router.push("/");
          return;
        }
        const query = this.$route.query;
        if (this.isNotBlank(query.companyInvitationCode)) {
          this.replaceState();
          await this.$router.push({
            path: "/company/register",
            query: query,
          });
        }
      } finally {
        this.loading = false;
      }
    });
  },
  methods: {
    moveAddPage() {
      this.$router.push({
        path: "/company/add",
        query: this.$route.query,
      });
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});
