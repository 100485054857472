
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import { ShareCalendarEventModel } from "@/models/calendar/share-calendar-event.model";
import { EstimateModel } from "@/models/estimate/estimate.model";
import core from "@/core";
import ShareCalendarEventService from "@/services/calendar/share-calendar-event.service";
import CalendarUtils from "@/utils/calendar-utils";
import PhoneInputModal from "@/modals/calendar/PhoneInputModal.vue";
import CalendarEventAddEditComponent from "@/components/calendar/EventAddEditComponent.vue";
import { UpdateEventType } from "@/types";
import { Decimal } from "decimal.js";

export interface DetailModel {
  id: number | null;
  description: string; // 품명
  specification: string; // 규격
  quantity: number; // 수량
  //quantityModel: string; // 수량 모델
  unitPrice: number; // 단가
  // unitPriceModel: string; // 단가 모델
  //orgAmountModel: string; // 원금 합계 모델
  amount: number; // 합계
  //amountModel: string; // 합계 모델
  calendarEvent: CalendarEventModel | null;
}

export default mixins(MixinsPage).extend({
  name: "ChangedCalendarEventShareComponent",
  components: {
    PhoneInputModal,
    CalendarEventAddEditComponent,
  },
  props: {
    query: {
      type: Object as any,
      default: () => {
        return {} as any;
      },
    },
    event: {
      type: Object as () => CalendarEventModel,
      default: () => {
        return {} as CalendarEventModel;
      },
    },
    shareCalendarEvent: {
      type: Object as () => ShareCalendarEventModel,
      default: () => {
        return {} as ShareCalendarEventModel;
      },
    },
  },
  data: () => ({
    visible: {
      detail: true,
      shareLink: false,
    },
    view: {
      memo: "",
      preTotalPrice: "",
      totalPrice: "",
    },
    estimate: null as EstimateModel | null,
    preEstimateDetailList: [] as DetailModel[],
    estimateDetailList: [] as DetailModel[],
    processing: false,
    shareLinkUrl: "",
  }),
  mounted() {
    this.$nextTick(async () => {
      const event = this.event as CalendarEventModel;
      if (this.isBlank(event.memo)) {
        this.view.memo = "<span class='grey--text'>내용없음</span>";
      } else {
        this.view.memo = core.utils.format.textToHtml(event.memo);
      }
      //console.log("event : ", event);

      if (this.shareCalendarEvent) {
        const shareCalendarEvent = this.shareCalendarEvent;
        shareCalendarEvent?.estimate?.detailList.forEach((detail) => {
          //console.log("detail : ", detail);
          const amount = detail.quantity * detail.unitPrice;
          const item: DetailModel = {
            id: detail.id,
            description: detail.description,
            specification: detail.specification,
            quantity: Number(detail.quantity),
            unitPrice: Number(detail.unitPrice),
            amount: amount,
            calendarEvent: null,
          };
          this.estimateDetailList.push(item);
        });
      }
      this.changeDetailList();
    });
  },
  methods: {
    eventDateToString() {
      return CalendarUtils.eventDateToString(this.event);
    },
    async submit() {
      console.log("submit");
      try {
        const shareCalendarEvent = this.shareCalendarEvent;
        const params = {
          uuid: shareCalendarEvent?.uuid,
          calendarId: this.event?.calendarId,
          eventId: this.event?.id,
        };
        const response = await ShareCalendarEventService.updateApproval(params);
        // console.log("response : ", response);
        this.goBack(UpdateEventType.RELOAD);
      } catch (e) {
        console.log(e);
      }
    },
    changeDetailList() {
      let totalPrice = 0;
      this.estimateDetailList.forEach((detail) => {
        const unitPriceDecimal = new Decimal(detail.unitPrice);
        const amount = Number(unitPriceDecimal.mul(detail.quantity).toNumber().toFixed(0));
        // console.log("amount : ", detail.amount, amount);
        detail.amount = amount;
        // detail.unitPrice = Number(detail.unitPriceModel);
        // detail.amount = detail.unitPrice * detail.quantity;
        totalPrice += detail.amount;
      });
      this.view.totalPrice = String(totalPrice);
    },
  },
});
