
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { CalendarModel } from "@/models/calendar/calendar.model";
import { cloneDeep } from "lodash";
import draggable from "vuedraggable";
import core from "@/core";
import CalendarService from "@/services/calendar/calendar.service";
import UserService from "@/services/user/user.service";
import { CompanyRole } from "@/types";
import CalendarUtils from "@/utils/calendar-utils";

export default mixins(MixinsPage).extend({
  name: "CalendarListEditComponent",
  components: {
    draggable,
  },
  props: {},
  data: () => ({
    ready: false,
    calendarList: [] as CalendarModel[],
    disabled: {
      delete: true,
      edit: true,
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      if (this.$store.getters["auth/isAdminRoleHigher"]) {
        this.disabled.delete = false;
        this.disabled.edit = false;
      }
      await this.reloadCalendar();

      this.ready = true;
    });
  },
  computed: {},
  watch: {
    // calendarList(calendarList) {
    //   console.log("calendarList : ", calendarList);
    // },
  },
  methods: {
    async reloadCalendar() {
      // 캘린더 목록 조회
      const calendarList = (await this.$store.getters["app/getCalendarList"]()) as CalendarModel[];
      this.calendarList = cloneDeep(calendarList);
      let companyCalendarCount = 0;
      this.calendarList.forEach((calendar) => {
        if (calendar.type === "company") {
          companyCalendarCount++;
        }
      });
      if (companyCalendarCount === 1) {
        this.disabled.delete = true;
      }
      //console.log("calendarList : ", calendarList);
    },
    async editItem(item) {
      //console.log("edit : ", item);
      this.$router.push({
        path: "/calendar/edit",
        query: {
          id: String(item.id),
        },
      });
    },
    async deleteItem(item) {
      //console.log("item : ", item.name);
      const msg = `"${item.name}" 캘린더에 등록 된 모든 일정이 삭제되며, 다시 복구할 수 없습니다`;
      const result = await core.alert.show({
        title: "확인",
        body: msg,
        showCancelButton: true,
        cancelButtonText: "취소",
        confirmButtonText: "삭제",
      });

      if (result === "confirm") {
        try {
          core.loader.show();

          const result = await CalendarService.delete(item.id);
          // console.log("result : ", result);
          setTimeout(() => {
            core.loader.hide();
            this.reloadCalendar();
          }, 500);
        } catch (e) {
          console.log(e);
        }
      }
    },
    typeToString(type) {
      return CalendarUtils.typeToString(type);
    },
    async submit() {
      //console.log("순서 저장");
      try {
        const params = {
          idList: [] as any,
        };
        // console.log("calendarList : ", this.calendarList);
        this.calendarList.forEach((calendar) => {
          params.idList.push(calendar.id);
        });
        const result = await CalendarService.updateOrder(params);
        //console.log("result : ", result);
        this.goBack();
      } catch (e) {
        console.log(e);
      }
    },
  },
});
