
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import { cloneDeep } from "lodash";
import { UpdateEventType } from "@/types";
import UpdateEvent from "@/models";
import { EstimateModel } from "@/models/estimate/estimate.model";

export default mixins(MixinsModalForm).extend({
  name: "EstimatePaymentAddEditModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            type: "add",
            estimate: {} as EstimateModel,
            payment: {} as any,
          } as any,
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    typeItems: [
      { text: "현금", value: "CACHE" },
      { text: "현금(영수증)", value: "CACHE_RECEIPT" },
      { text: "카드", value: "CARD" },
      { text: "쿠폰", value: "COUPON" },
      { text: "할인", value: "DISCOUNT" },
    ],
    dateVisible: false,
    type: "",
    form: {
      price: "" as string | number,
      date: "",
      type: "CACHE",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      // 생성 후 호출
      const properties = this.properties;

      const params = properties.params;
      this.type = properties.params.type;

      if (params.estimate == null) {
        console.log("estimate is null!!");
        await this.notFound();
        return;
      }

      if (params.type === "add") {
        this.form.date = core.date.instance().format("YYYY-MM-DD");
      } else if (params.type === "edit") {
        if (params.payment == null) {
          console.log("estimate is null!!");
          await this.notFound();
          return;
        }
        const payment = params.payment;
        this.form.date = payment.date;
        this.form.price = payment.price;
        this.form.type = payment.type;
      }
    });
  },
  watch: {
    "form.price"(price) {
      this.form.price = core.utils.format.onlyNumber(price);
    },
  },
  methods: {
    getBalance() {
      const type = this.type;
      const estimate = this.properties.params.estimate;

      return estimate.balance;
    },
    async submit() {
      if (await this.validate()) {
        // core.loader.show("저장중...");
        try {
          const params = cloneDeep(this.form);
          this.close(UpdateEventType.CONFIRM, params);
        } catch (e) {
          // core.loader.hide();
          this.errorSubmit(e);
        }
      }
    },
    async deleteItem() {
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      const item = this.properties.params.payment;
      if (result === "confirm") {
        this.close(UpdateEventType.DELETE);
      }
    },
  },
});
