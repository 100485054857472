import AbstractTableService from "@/services/core/abstract-table.service";
import { TableRequest } from "@/types";

class UnitPriceTableService extends AbstractTableService {
  constructor() {
    super("/api/v1/unit-price-table", "unitPriceTable");
  }

  get(id: number): Promise<unknown> {
    return super.get(id, null);
  }

  getList(): Promise<unknown> {
    return super.getList();
  }

  create(params): Promise<unknown> {
    return super.create(params);
  }

  update(id: number, params): Promise<unknown> {
    return super.update(id, params);
  }

  delete(id: number): Promise<unknown> {
    return super.delete(id, null);
  }

  async getTable(params: TableRequest): Promise<unknown> {
    const tableResponse = (await super.getTable(params)) as any;
    tableResponse.data.forEach((data) => {
      data.selected = false;
    });
    return tableResponse;
  }
}

export default new UnitPriceTableService();
