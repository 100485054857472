
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { ComponentProperties } from "@/mixins/component";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";
import core from "@/core";

export default mixins(MixinsModalForm).extend({
  name: "PhoneInputModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            phone: "",
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    form: {
      phone: "",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      this.form.phone = this.properties.params.phone;
    });
  },
  watch: {
    "form.phone"(val) {
      this.form.phone = core.utils.format.hyphenPhone(val);
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        this.close(UpdateEventType.CONFIRM, cloneDeep(this.form));
      }
    },
  },
});
