import Stomp, { Client, Subscription } from "webstomp-client";
import SockJS from "sockjs-client";

export enum Status {
  CONNECTED,
  CONNECTING,
  DISCONNECTED,
}

export enum SubscribeType {
  COMMENT = "comment",
  COMPANY = "company",
  CALENDAR = "calendar",
  NOTIFICATION = "notification",
  SESSION = "session",
  // SUPPORT = "support",
  // CALL = "call",
}

const SubscribeTopic = {
  comment: {
    topic: "/topic/comment/{param1}/{param2}",
    paramNameList: ["entityType", "entityId"],
  },
  session: {
    topic: "/topic/user/{param1}/session",
    paramNameList: ["userId"],
  },
  company: {
    topic: "/topic/user/{param1}/company",
    paramNameList: ["userId"],
  },
  calendar: {
    topic: "/topic/user/{param1}/calendar",
    paramNameList: ["userId"],
  },
  notification: {
    topic: "/topic/user/{param1}/notification",
    paramNameList: ["userId"],
  },
};

export interface SubscribeRequest {
  callback?: any;
}

export interface CompanyRequest extends SubscribeRequest {
  userId: number;
}

export interface NotificationRequest extends SubscribeRequest {
  userId: number;
}

export interface CommentRequest extends SubscribeRequest {
  entityType: string;
  entityId: number;
}

export interface SupportRequest extends SubscribeRequest {
  companyId: number;
}

export class Subscribe {
  type: SubscribeType | null = null;
  topic: string | null = null;
  item: any = null;
  subscription: Subscription | undefined;
  callback: Callback | undefined;
}

export interface Callback {
  connected: any;
  disconnected: any;
}

export default class CoreWebSocket {
  status = Status.DISCONNECTED;
  client: Client | undefined;
  subscribeList = [] as Subscribe[];
  store: any;
  disconnectedCount = 0;

  constructor(store: any) {
    this.store = store;
  }

  init() {
    this.connect(this);
  }

  async connect(vm) {
    if (vm.status === Status.DISCONNECTED) {
      try {
        vm.status = Status.CONNECTING;
        // const user = await vm.store.dispatch("auth/user", false);
        const user = await vm.store.getters["auth/user"]();
        if (user != null) {
          //console.log("try web socket connect");
          const socket = new SockJS("/ws");
          vm.client = Stomp.over(socket, { debug: false });
          vm.client.connect(
            {},
            (frame) => {
              vm.connected(vm, frame);
            },
            (event) => {
              vm.disconnected(vm, event);
            }
          );
        } else {
          vm.disconnected(vm, null);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  disconnect() {
    const vm = this;
    if (vm.status === Status.CONNECTED) {
      vm.client?.disconnect(() => {
        vm.disconnected(vm, null);
      });
    }
    // vm.subscribeList = [];
  }

  private connected(vm, frame) {
    vm.status = Status.CONNECTED;
    vm.disconnectedCount = 0;
    //console.log("websocket connected");
    const subscribeList = vm.subscribeList;
    vm.subscribeList = [];
    setTimeout(async () => {
      //console.log("subscribeList : ", subscribeList);
      await vm.store.dispatch("app/webSocketConnected", {
        connected: true,
        subscribeList: subscribeList,
      });
      subscribeList.forEach((subscribe) => {
        if (subscribe.callback) {
          if (subscribe.callback.connected) {
            subscribe.callback.connected();
          }
        }
        // vm.subscribe(subscribe.type, subscribe.item);
      });
    }, 200);
  }

  private async disconnected(vm, event) {
    vm.status = Status.DISCONNECTED;

    const user = (vm.store.state as any).auth.user;
    // console.log("websocket disconnected, user : ", user);
    if (user != null) {
      vm.store.dispatch("app/webSocketConnected", { connected: false, subscribeList: null });
      const subscribeList = vm.subscribeList;
      subscribeList.forEach((subscribe) => {
        if (subscribe.callback) {
          if (subscribe.callback.disconnected) {
            subscribe.callback.disconnected();
          }
        }
        // vm.subscribe(subscribe.type, subscribe.item);
      });
      (vm.store.state as any).auth.sessionCheckTime = new Date(0);
      if (vm.disconnectedCount === 0) {
        setTimeout(() => {
          vm.connect(vm);
        }, 100);
      } else {
        setTimeout(() => {
          vm.connect(vm);
        }, 5000);
      }
      vm.disconnectedCount++;
    }
  }

  subscribe(
    type: SubscribeType,
    item: SubscribeRequest | NotificationRequest | CompanyRequest | CommentRequest | SupportRequest,
    callback?: Callback
  ) {
    //console.log(`subscribe type '${type}', item '`, item, `'`);

    const topic = this.getTopic(type, item);
    const subscribeList = this.subscribeList;
    for (let i = 0; i < subscribeList.length; i++) {
      const subscribe = subscribeList[i];
      if (subscribe.topic === topic) {
        // console.log("already subscribe topic : ", topic);
        return;
      }
    }
    const subscribe = new Subscribe();
    subscribe.topic = topic;
    subscribe.type = type;
    subscribe.item = item;
    subscribe.callback = callback;

    if (this.status === Status.CONNECTED) {
      // console.log("subscribe topic : ", topic);
      subscribe.subscription = this.client?.subscribe(topic, (message) => {
        if (item.callback != null) {
          try {
            item.callback(JSON.parse(message.body));
          } catch (e) {
            console.log(e);
          }
        }
      });
    } else {
      //console.log("websocket is not connected, ", subscribe.topic);
    }
    this.subscribeList.push(subscribe);
  }

  unsubscribe(
    type: SubscribeType,
    item: NotificationRequest | CompanyRequest | CommentRequest | SupportRequest
  ) {
    const topic = this.getTopic(type, item);
    this.subscribeList.some((subscribe, index) => {
      if (subscribe.topic === topic) {
        if (subscribe.subscription != null) {
          // console.log("unsubscribe topic : ", topic);
          subscribe.subscription.unsubscribe();
        }
        this.subscribeList.splice(index, 1);
        return true;
      }
    });
  }

  private getTopic(
    type: SubscribeType,
    item: SubscribeRequest | NotificationRequest | CompanyRequest | CommentRequest | SupportRequest
  ) {
    const subscribeTopicItem = SubscribeTopic[type];
    if (subscribeTopicItem == null) {
      console.log(`Unknown type ${type}`);
      throw new Error("Unknown subscribe type '" + type + "'");
    }
    //console.log("subscribeTopicItem : ", subscribeTopicItem);
    let topic = subscribeTopicItem.topic;
    if (subscribeTopicItem.paramNameList != null && subscribeTopicItem.paramNameList.length > 0) {
      subscribeTopicItem.paramNameList.forEach((id, index) => {
        const value = item[id];
        if (value == null) {
          console.log(`subscribe item not found value(key '${id}')`);
          return "";
        }
        topic = topic.replace(`{param${index + 1}}`, item[id]);
      });
    }
    return topic;
  }
}
