import AbstractTableService from "@/services/core/abstract-table.service";
import core from "@/core";

class QuestionBoardService extends AbstractTableService {
  constructor() {
    super("/api/v1/board/question", "questionBoard");
  }

  createAnswer(id: any, params: { content: string }) {
    const url = this.getUrl() + `/${id}/answer`;
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`${url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  deleteAnswer(id) {
    const url = this.getUrl() + `/answer/${id}`;
    return new Promise((resolve, reject) => {
      core.http
        .delete(`${url}`)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new QuestionBoardService();
