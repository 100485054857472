
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimateService from "@/services/estimate/estimate.service";
import { EstimateModel } from "@/models/estimate/estimate.model";
import core from "@/core";
import { AppBarMenuItem, CompanyRole, UpdateEventType } from "@/types";
import EstimatePrintComponent from "@/components/estimate/PrintComponent.vue";
import { UserModel } from "@/models/user/user.model";
import { UserCompanyModel } from "@/models/company/user-company.model";
import EstimateMemoEditModal from "@/modals/estimate/MemoEditModal.vue";
import UpdateEvent from "@/models";
import CalendarSelectModal from "@/modals/calendar/SelectModal.vue";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import CalendarEventService from "@/services/calendar/calendar-event.service";
import { CalendarModel } from "@/models/calendar/calendar.model";
import Constant from "@/store/constant";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import HintView from "@/components/core/HintView.vue";
import SelectModal from "@/modals/core/SelectModal.vue";
import EstimateDownloadModal from "@/modals/estimate/DownloadModal.vue";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "EstimateView",
  components: {
    EstimateDownloadModal,
    SelectModal,
    HintView,
    CalendarSelectModal,
    EstimateMemoEditModal,
    EstimatePrintComponent,
  },
  data: () => ({
    ready: false,
    geoLink: null as string | null,
    estimate: {} as EstimateModel,
    estimateDetailListSize: 0,
    estimateUpdatedAt: null as Date | null,
    download: {
      index: 0,
      uriList: [] as string[],
    },
    visible: {
      edit: false,
    },
    view: {
      memo: "",
      remark: "",
    },
    modal: {
      memoEdit: {
        visible: false,
        params: {
          memo: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
      calendarSelect: {
        visible: false,
        type: "company",
        updateEvent: null as UpdateEvent | null,
      },
      // exportSelect: {
      //   visible: false,
      //   params: {
      //     list: [
      //       { id: "image", text: "이미지 다운로드" },
      //       { id: "pdf", text: "PDF 다운로드" },
      //     ],
      //   },
      //   updateEvent: null as UpdateEvent | null,
      // },
      download: {
        visible: false,
        params: {
          uriList: [] as string[],
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    calendarList: [] as CalendarModel[],
    calendarEventList: [] as CalendarEventModel[],
  }),

  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params;
      //console.log("query : ", query);
      if (this.isBlank(params.id)) {
        await this.notFound();
        return;
      }

      this.appBarChangeMenuVisible("delete", this.isManagerRoleHigher);
      this.appBarChangeMenuVisible("export", this.isManagerRoleHigher);

      const user = (await this.$store.getters["auth/user"]()) as UserModel;
      //console.log("user : ", user);
      const company = user.company as UserCompanyModel;
      if (
        company.role == CompanyRole.OWNER ||
        company.role == CompanyRole.ADMIN ||
        company.role == CompanyRole.MANAGER
      ) {
        this.visible.edit = true;
      }

      const estimateId = Number(params.id);
      try {
        this.estimate = await this.getEstimate(estimateId);
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }
      this.loadEstimate(this.estimate);

      try {
        this.calendarList = await this.$store.getters["app/getCalendarList"]();
        await this.getCalendarEventList();
      } catch (e) {
        console.log(e);
        return;
      }

      this.ready = true;
    });
  },
  computed: {
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
  },
  async activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.path.indexOf("/estimate") > -1) {
        if (event.result === UpdateEventType.UPDATE) {
          this.estimate = event.item;

          if (this.isReloadEstimate()) {
            this.estimate = await this.getEstimate(this.estimate.id);
          }
          this.loadEstimate(this.estimate);
          this.updatePreVmEvent(event.result, this.estimate);
          await this.getCalendarEventList();
        } else if (event.result === UpdateEventType.RELOAD) {
          this.estimate = await this.getEstimate(this.estimate.id);
          this.loadEstimate(this.estimate);
          this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
          await this.getCalendarEventList();
        } else {
          console.log("unknown event : ", event);
        }
      } else if (event.path.indexOf("/calendar/event") > -1) {
        this.estimate = await this.getEstimate(this.estimate.id);
        this.loadEstimate(this.estimate);
        this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
        await this.getCalendarEventList();
      } else {
        console.log("unknown event path : ", event);
      }
    }
  },
  watch: {
    "modal.memoEdit.updateEvent"() {
      const modal = this.modal.memoEdit;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          const memo = item.memo;
          if (this.estimate != null) {
            this.updateMemo(this.estimate, memo);
          }
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
    "modal.calendarSelect.updateEvent"() {
      const modal = this.modal.calendarSelect;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          // console.log("select calendar : ", item);
          this.$router.push({
            path: "/calendar/event/add",
            query: {
              estimateId: String(this.estimate.id),
              calendarId: String(item.id),
            },
          });
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
    // async "modal.exportSelect.updateEvent"() {
    //   const modal = this.modal.exportSelect;
    //   const event = this.getComponentUpdateEvent(modal);
    //   if (event != null) {
    //     if (event.result === UpdateEventType.CONFIRM) {
    //       const item = event.item;
    //       console.log("select export type : ", item);
    //       if (item === "image" || item === "pdf") {
    //         // 이미지 or PDF 파일 다운로드
    //         try {
    //           const result = (await EstimateService.downloadImage(this.estimate.id)) as any;
    //           if (result.downloadUriList) {
    //             this.download.index = 0;
    //             this.download.uriList = result.downloadUriList;
    //           }
    //         } catch (e) {
    //           console.log(e);
    //         }
    //
    //         if (this.download.uriList.length === 0) {
    //           core.alert.show({
    //             title: "알림",
    //             body: "견적서 이미지 파일 생성 실패",
    //           });
    //         } else {
    //           const modal = this.modal.download;
    //           modal.params.uriList = this.download.uriList;
    //           modal.visible = true;
    //         }
    //       }
    //     } else {
    //       console.log("unknown event : ", event);
    //     }
    //   }
    // },
  },
  methods: {
    // showExportModal() {
    //   const modal = this.modal.exportSelect;
    //   modal.visible = true;
    // },
    async downloadImageFile() {
      // 이미지 or PDF 파일 다운로드
      try {
        core.loader.show();
        const result = (await EstimateService.export(this.estimate.id)) as any;
        if (result.downloadUriList) {
          this.download.index = 0;
          this.download.uriList = result.downloadUriList;
        }
      } catch (e) {
        console.log(e);
      }

      if (this.download.uriList.length === 0) {
        core.alert.show({
          title: "알림",
          body: "견적서 내보내기 파일 생성 실패",
        });
      } else {
        const modal = this.modal.download;
        modal.params.uriList = this.download.uriList;
        modal.visible = true;
      }
      core.loader.hide();
    },
    appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      // if (menu.id === "print") {
      //   // console.log("event");
      //   if (this.estimate != null) {
      //     this.$router.push(`/estimate/${this.estimate.id}/print`);
      //   }
      // } else
      if (menu.id === "export") {
        //console.log("견적서 다운로드");
        this.downloadImageFile();
      } else if (menu.id === "delete") {
        // console.log("event");
        this.deleteItem();
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    async sendEstimateSms() {
      const user = (await this.$store.getters["auth/user"]()) as UserModel;
      if (user.company) {
        // 업체 이름 추가
        let content = `[${user.company.name}]\n업체에서 견적 정보가 등록되었습니다.\n`;
        content += "아래 링크를 통해 견적 정보를 확인하세요!\n";
        content += "\n";

        let val = window.btoa(this.estimate.customerPhone + "," + this.estimate.authCode);
        content += Constant.customerWebUrl + "/login?v=" + val + "\n";
        content += "인증코드 " + this.estimate.authCode;
        // console.log("content : ", content);
        // const url = "sms:" + this.estimate.customerPhone + "?body=" + encodeURIComponent(content);
        location.href = core.mobile.getSmsLink(this.estimate.customerPhone, content);
      } else {
        console.log("not found user company : ", user);
      }
    },
    async sendScheduleSms() {
      const user = (await this.$store.getters["auth/user"]()) as UserModel;
      if (user.company) {
        // 업체 이름 추가
        let content = `[${user.company.name}]\n\n`;
        const calendarEventList = this.calendarEventList;
        calendarEventList.forEach((calendarEvent) => {
          const strDate = CalendarEventService.dateToText(calendarEvent);
          content += `${strDate}\n`;
        });
        content += "\n시공 일정이 지정되었습니다.\n";
        content += "\n";

        let val = window.btoa(this.estimate.customerPhone + "," + this.estimate.authCode);
        content += Constant.customerWebUrl + "/login?v=" + val + "\n";
        content += "인증코드 " + this.estimate.authCode;
        // console.log("content : ", content);
        // const url = "sms:" + this.estimate.customerPhone + "?body=" + encodeURIComponent(content);
        location.href = core.mobile.getSmsLink(this.estimate.customerPhone, content);
      } else {
        console.log("not found user company : ", user);
      }
    },
    moveCalendarEventPage(calendarEvent) {
      this.$router.push({
        path: `/calendar/event/${calendarEvent.id}`,
        query: {
          calendarId: calendarEvent.calendarId,
        },
      });
    },
    async getCalendarEventList() {
      try {
        const estimateId = this.estimate.id;
        const eventList = (await EstimateService.getCalendarEvent(
          estimateId
        )) as CalendarEventModel[];
        this.calendarEventList = eventList;

        for (let i = 0; i < eventList.length; i++) {
          const event = eventList[i];
          const shareCalendarEvent = event.shareCalendarEvent;

          // TODO : 2022/11/03 수정 검토 필요
          if (shareCalendarEvent != null && shareCalendarEvent.changeStatus === "ESTIMATE") {
            const confirmResult = await core.alert.show({
              title: "확인",
              body: "견적서 항목이 변경되었습니다. 공유 일정 시공범위 금액을 확인해주세요.",
            });
            this.$router.push({
              path: `/calendar/event/${event.id}/share`,
              query: {
                edit: "true",
              },
            });
          }
          break;
        }
        // const shareCalendarEventList = (await EstimateService.getShareCalendarEvent(
        //   estimateId
        // )) as ShareCalendarEventModel[];
        // for (let i = 0; i < eventList.length; i++) {
        //   const event = eventList[i];
        //   for (let j = 0; j < shareCalendarEventList.length; j++) {
        //     const shareCalendarEvent = shareCalendarEventList[j];
        //     if (event.shareCalendarEventId == shareCalendarEvent.id) {
        //       event.shareCalendarEvent = shareCalendarEvent;
        //       if (shareCalendarEvent.status === "UPDATED_ESTIMATE") {
        //         const confirmResult = await core.alert.show({
        //           title: "확인",
        //           body: "견적서 항목이 변경되었습니다. 공유 일정 시공범위 금액을 확인해주세요.",
        //         });
        //         this.$router.push({
        //           path: `/calendar/event/${event.id}/share`,
        //           query: {
        //             edit: "true",
        //           },
        //         });
        //       }
        //       break;
        //     }
        //   }
        // }
        // eventList.forEach((event) => {
        //   shareCalendarEventList.some((shareCalendarEvent) => {
        //     if (event.shareCalendarEventId == shareCalendarEvent.id) {
        //       event.shareCalendarEvent = shareCalendarEvent;
        //       // console.log("event : ", event);
        //       return true;
        //     }
        //   });
        // });
        // console.log("shareCalendarEventList : ", shareCalendarEventList);
      } catch (e) {
        console.log(e);
        return;
      }
    },
    calendarIdToText(calendarId: number) {
      let text = "";
      this.calendarList.some((calendar) => {
        if (calendar.id === calendarId) {
          text = calendar.name;
          return true;
        }
      });
      return text;
    },
    calendarEventDateToText(item: CalendarEventModel) {
      return CalendarEventService.dateToText(item);
    },
    calendarEventEstimateDetailToText(item: CalendarEventModel) {
      return CalendarEventService.detailToText(item);
    },
    showSelectCalendarModal(type) {
      // console.log("show select calendar modal : ", type);

      // 캘린더 선택 모달
      const modal = this.modal.calendarSelect;
      modal.type = type;
      modal.visible = true;
    },
    movePaymentPage() {
      const estimateId = String(this.estimate.id);
      this.$router.push({
        path: `/estimate/${estimateId}/payment`,
      });
    },
    moveEditPage(type) {
      const estimateId = String(this.estimate.id);
      if (type === "estimate") {
        this.$router.push({
          path: "/estimate/edit",
          query: {
            id: estimateId,
            view: "estimate",
          },
        });
      } else {
        this.$router.push({
          path: "/estimate/edit",
          query: {
            id: estimateId,
            view: "info",
          },
        });
      }
    },
    showMemoEditModal() {
      console.log("메모 수정");
      if (this.estimate != null) {
        const modal = this.modal.memoEdit;
        modal.params.memo = this.estimate.memo;
        modal.visible = true;
      }
    },
    nextContract() {
      console.log("계약진행 페이지 이동");
      const estimateId = String(this.estimate.id);
      this.$router.push({
        path: "/estimate/edit",
        query: {
          id: estimateId,
          view: "contract",
        },
      });
      // if (this.estimate != null) {
      //   this.$router.push(`/estimate/${this.estimate.id}/contract/add`);
      // }
    },
    loadEstimate(estimate: EstimateModel) {
      if (this.isBlank(estimate.memo)) {
        this.view.memo = "<span class='grey--text'>내용없음</span>";
      } else {
        this.view.memo = core.utils.format.textToHtml(estimate.memo);
      }

      if (this.isBlank(estimate.remark)) {
        this.view.remark = "";
      } else {
        this.view.remark = core.utils.format.textToHtml(estimate.remark);
      }

      // this.geoLink = "geo:?q=" + estimate.address;
      this.geoLink = core.mobile.getGeoLink(String(estimate.address));

      this.estimateDetailListSize = 0;

      if (estimate.detailList) {
        estimate.detailList.forEach((detail) => {
          //console.log("detail : ", detail);
          if (detail.calendarEventId == null) {
            this.estimateDetailListSize++;
          }
        });
      }
    },
    addressToText(estimate: EstimateModel) {
      let text = estimate.address;

      if (this.isNotBlank(estimate.dong)) {
        text += ` ${estimate.dong}동`;
      }

      if (this.isNotBlank(estimate.ho)) {
        text += ` ${estimate.ho}호`;
      }

      return text;
    },
    contractToHtml(estimate: EstimateModel) {
      return EstimateService.contractToHtml(estimate);
    },
    async updateContract(estimate: EstimateModel, contract) {
      const result = await core.alert.show({
        title: "확인",
        body: "계약상태를 변경 하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        try {
          await EstimateService.updateContract(estimate.id, contract);
          estimate.contract = contract;

          if (this.isReloadEstimate()) {
            this.estimate = await this.getEstimate(estimate.id);
            this.loadEstimate(this.estimate);
          }

          this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async updateMemo(estimate: EstimateModel, memo) {
      try {
        core.loader.show("저장중...");
        await EstimateService.updateMemo(estimate.id, memo);
        estimate.memo = memo;

        if (this.isReloadEstimate()) {
          this.estimate = await this.getEstimate(estimate.id);
          this.loadEstimate(this.estimate);
        } else {
          this.loadEstimate(estimate);
        }

        this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
    isReloadEstimate() {
      const date = new Date();
      if (this.estimateUpdatedAt == null) return true;
      // 견적 조회 이후, 1분 이상 지났을때 다시 조회하도록 처리
      return date.getTime() - this.estimateUpdatedAt.getTime() > 60000;
    },
    async getEstimate(estimateId): Promise<EstimateModel> {
      const estimate = (await EstimateService.get(estimateId)) as EstimateModel;
      this.estimateUpdatedAt = new Date();
      return estimate;
    },
    async deleteItem() {
      const alertResult = await core.alert.show({
        title: "확인",
        body: "견적서를 삭제 하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (alertResult === "confirm") {
        try {
          const result = await EstimateService.delete(this.estimate.id);
          // console.log("result : ", result);
          this.goBack(UpdateEventType.DELETE, this.estimate);
        } catch (e) {
          console.log(e);
        }
      }
    },
    getTelLink(phone) {
      const link = core.mobile.getTelLink(phone);
      // core.alert.show({
      //   title: "알림",
      //   body: link,
      // });
      return link;
    },
  },
});
